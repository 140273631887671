import React from "react";
import { Input, Form } from "antd";

const { TextArea } = Input;
export default function coverLetter() {
  return (
    <div>
      <h3>Cover Letter</h3>
      <Form.Item name="coverLetter">
        <TextArea rows={4} />
      </Form.Item>
    </div>
  );
}
