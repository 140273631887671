import React from "react";
import { Routes, Route } from "react-router-dom";
import PasswordResetRequest from "../pages/auth/passwordResetRequest";
import ResetRequest from "../pages/auth/resetPassword";

export default function AuthRoutes() {
  return (
    <Routes>
      <Route
        exact
        path="/reset-password"
        element={<PasswordResetRequest />}
      ></Route>
      <Route
        exact
        path="/set-password/:token"
        element={<ResetRequest />}
      ></Route>
    </Routes>
  );
}
