import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { apiRoutes, baseUrl } from "./apiRoute";
import { Form, Input, Button, Checkbox, Spin, Alert } from "antd";
import Axios from "axios";

Axios.defaults.withCredentials = true;

function ResetRequest(props) {
  const [email, setEmail] = useState("");
  const [user, setUser] = useState({});
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [isAuthed, setAuth] = useState(false);
  const [err, setErr] = useState(false);
  const [errMsg, setMsg] = useState("");
  const navigate = useNavigate();
  let { token } = useParams();
  useEffect(() => {}, []);
  const reset = async (password, password2) => {
    return Axios.post(apiRoutes.passwordReset, {
      password: password,
      password2: password2,
      token: token,
    })
      .then((resp) => {
        console.log(resp.data);

        return resp.data;
      })
      .catch((err) => {
        console.log(err.response.data);
        throw err;
      });
  };
  const onFinish = async (values) => {
    setLoading(true);
    const { password, password2 } = values;

    var signedInUser;
    try {
      signedInUser = await reset(password, password2);
      setErr(false);
      setAuth(true);
      setTimeout(() => {
        navigate("/login");
      }, 1500);
    } catch (error) {
      setErr(true);
      if (error.response && error.response.data.message) {
        setMsg(error.response.data.message);
      }
    }
    form.resetFields();
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) {
      // history.replace("/jobs")
    }
  }, [user, loading]);
  return (
    <div>
      <div
        // className="card"
        style={{
          width: "450px",
          maxWidth: "500px",
          margin: "auto",

          padding: "2rem",
        }}
      >
        {isAuthed ? (
          <div style={{ marginBottom: "2rem" }}>
            <Alert
              message="Success!"
              description={`Password Reset Successfully`}
              type="success"
              closable
            />
          </div>
        ) : (
          <></>
        )}
        {err ? (
          <>
            <div style={{ marginBottom: "2rem" }}>
              <Alert
                message="Error Login:"
                description={`${errMsg}, please try again.`}
                type="error"
                closable
              />
            </div>
          </>
        ) : (
          <></>
        )}
        <Spin spinning={loading}>
          <Form
            form={form}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="New Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your new password!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="password2"
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Reset Now
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </div>
  );
}
export default ResetRequest;
