import React, { useEffect, useState, useContext } from "react";
import { Form, Button, Typography, Spin } from "antd";
import hotelList from "../../files/hotelList";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import HotelInfo from "./components/hotelInfo";
import JobDescription from "./components/jobDescription";
import JobRequirement from "./components/jobRequirements";
import ShowResult from "./components/showResult";
import { db } from "../auth/firebase";
import EmployerLayout from "./layout/layout";
import { firebase } from "../auth/firebase";
import { UserContext } from "../auth/context";
import Axios from "axios";
import { apiRoutes, baseUrl } from "../auth/apiRoute";
export default function JobPosting(appProps) {
  const [selectedHotel, selectHotel] = useState({});
  const [loading, setLoading] = useState(false);
  const [postedJob, setPostedJob] = useState({});
  const [status, setStatus] = useState("");
  const { Title } = Typography;
  let formRef = React.createRef();

  const onFinish = async (values) => {
    setLoading(true);

    var data = values;
    data.userId = appProps.user.uid;
    data.userEmail = appProps.user.email;

    const hotelData = await getCurrentUserHotel(appProps.user.email);
    if (hotelData) {
      if (
        data.hotel.name == hotelData.hotel.name &&
        hotelData.hotel.avatarUrl
      ) {
        data.hotel.avatarUrl = hotelData.hotel.avatarUrl;
      }
    }

    var obj = JSON.parse(
      JSON.stringify(data, function (k, v) {
        if (v === undefined) {
          return "";
        }
        return v;
      })
    );

    try {
      obj.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      obj.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
      var postedDoc = await db.collection("jobs").add(obj);
      setPostedJob(postedDoc);
      sendNotification(data);
      setStatus("success");
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const sendNotification = async (data) => {
    try {
      await Axios.post(apiRoutes.emailNewJob, { data: data });
    } catch (error) {
      console.log(error);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const getCurrentUserHotel = async (userEmail) => {
    var docRef = db.collection("employers").doc(userEmail);
    var userData = await docRef.get();
    return userData.data();
  };
  useEffect(async () => {
    const userEmail = appProps.user.uid;
    const hotelData = await getCurrentUserHotel(userEmail);
    if (hotelData && hotelData.hotel) {
      selectHotel(hotelData.hotel);
    }

    // if (status != "success") {
    //   formRef.current.setFieldsValue({ hotel: selectedHotel });
    // }
  }, [appProps.user.uid]);

  const onChangeSelect = (value) => {
    selectHotel(value);
  };

  return (
    <EmployerLayout>
      {status == "success" ? (
        <ShowResult errorMsg={""} status={status} id={postedJob.id} />
      ) : (
        <Spin spinning={loading}>
          <Form
            key={selectedHotel.name}
            ref={formRef}
            style={{ paddingTop: "2px" }}
            initialValues={{
              hotel: {
                name: selectedHotel.name,
                street: selectedHotel.street,
                city: selectedHotel.city,
                zip: selectedHotel.zip,
                state: selectedHotel.state,
              },
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout={"vertical"}
          >
            <div className="card" style={{ marginTop: "22px" }}>
              <HotelInfo
                onSelectHotel={(values) => {
                  onChangeSelect(values);
                }}
              />
            </div>
            <div className="card" style={{ marginTop: "22px" }}>
              <JobDescription />
            </div>
            <div className="card" style={{ marginTop: "22px" }}>
              <JobRequirement />
            </div>
            <Form.Item style={{ textAlign: "center" }}>
              <Button htmlType="submit">Cancel</Button>
              <Button type="primary" htmlType="submit">
                Confirm
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      )}
    </EmployerLayout>
  );
}
