import React, { Children } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Button, Spin, Typography } from "antd";
const { Title } = Typography;
const { Header, Content, Sider } = Layout;
export default function EmployerLayout(props) {
  return (
    <Layout>
      <Sider
        width={200}
        className="site-layout-background"
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          // console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          // console.log(collapsed, type);
        }}
      >
        <Menu mode="vertical">
          <Menu.Item key="3">
            <Link to="/dashboard/employer/hotel/update">
              Update Hotel Profile{" "}
            </Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link to="/dashboard/employer/job/add">Post a Job</Link>
          </Menu.Item>
          <Menu.Item key="1">
            <Link to="/dashboard/employer/posted-jobs">Posted Jobs</Link>
          </Menu.Item>
          {/* <Menu.Item key="4">
            <Link to="/dashboard/employer/candidates">Candidates</Link>
          </Menu.Item> */}
        </Menu>
      </Sider>
      <Content className="site-layout-background" style={{ padding: "0.5rem" }}>
        <Title level={3}>Dashboard</Title>
        {props.children}
      </Content>
    </Layout>
  );
}
