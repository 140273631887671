import React from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Col,
  Row,
  Select,
  InputNumber,
  DatePicker,
  AutoComplete,
  Cascader,
  Typography,
  Tag,
} from "antd";
import JobSkills from "./jobSkills";
import TextEditor from "./richText";
const { Option } = Select;
const { Text } = Typography;
const { TextArea } = Input;
export default function jobRequirement(props) {
  return (
    <div>
      <h3>Job Requirements</h3>
      <Form.Item name="requirement" label="Requirements">
        <TextEditor key={props.requirement} description={props.requirement} />
      </Form.Item>

      <JobSkills />
    </div>
  );
}
