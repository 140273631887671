import React from "react";
import { Layout } from "antd";

const { Header, Footer, Sider, Content } = Layout;

export default function footer() {
  return (
    <Footer style={{ textAlign: "center" }}>
      ©2022 Created by Alan Guan for Hotel Association of New York City
    </Footer>
  );
}
