import React, { useState, useEffect } from "react";
import { Avatar, Typography, Space, Empty, Alert, Button } from "antd";
import { Link } from "react-router-dom";
import { UserOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { db, firebase, getAuth, storage } from "../auth/firebase";
import LoadingFrame from "../../layout/loadingFrame";
import moment from "moment";
const { Text, Paragraph, Title } = Typography;
export default function ProfileView(appProps) {
  const [currentProfile, setCurrentProfile] = useState({});
  const [resumeUrl, setResumeUrl] = useState("");
  const [resumeFileName, setResumeFileName] = useState("");
  const [coverletterUrl, setCoverletterUrl] = useState("");
  const [coverletterFileName, setCoverletterFileName] = useState("");
  const [loading, setLoading] = useState(true);
  const [photoUrl, setPhotoUrl] = useState();
  const [profileComplete, setProfileComplete] = useState(false);
  useEffect(async () => {
    setLoading(true);
    const userId = appProps.user.uid;
    const candidateProfileRef = await db
      .collection("candidates")
      .doc(userId)
      .get();
    var candidateProfile = candidateProfileRef.data();
    if (candidateProfile) {
      if (candidateProfile.resume && candidateProfile.resume.experiences) {
        candidateProfile.resume.experiences =
          candidateProfile.resume.experiences.map((item) => {
            return {
              ...item,
              startDate: moment(item.startDate),
              endDate: moment(item.endDate),
            };
          });
      }
      setCurrentProfile(candidateProfile.resume);
      setProfileComplete(true);
      setResumeFileName(candidateProfile.resumeFileName);
      setResumeUrl(candidateProfile.resumeUrl);
      setCoverletterFileName(candidateProfile.coverletterFileName);
      setCoverletterUrl(candidateProfile.coverletterUrl);
    }
    setPhotoUrl(appProps.user.photoURL);
    setLoading(false);
  }, []);
  const contactInfo = () => {
    if (currentProfile) {
      return (
        <div
          className="card"
          style={{ padding: "10px", maxWidth: "800px", margin: "auto" }}
        >
          {photoUrl ? (
            <img src={photoUrl} className="avatar" />
          ) : (
            <Avatar
              size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
              icon={<UserOutlined />}
            />
          )}
          <Title level={4}>
            {currentProfile.firstName + " " + currentProfile.lastName}
          </Title>
          <Space direction="vertical">
            <Text>
              <PhoneOutlined /> {currentProfile.phone}
            </Text>
            <Text>
              <MailOutlined /> {currentProfile.email}
            </Text>
            <Text type="secondary">
              {currentProfile.city}, {currentProfile.state},{" "}
              {currentProfile.zip}{" "}
            </Text>
            {resumeUrl ? (
              <a href={resumeUrl} download={resumeFileName} target="_blank">
                Download Resume
              </a>
            ) : (
              ""
            )}
          </Space>
        </div>
      );
    }
  };

  const coverLetter = () => {
    return (
      <div
        className="card"
        style={{
          padding: "10px",
          maxWidth: "800px",
          margin: "auto",
          marginTop: "10px",
        }}
      >
        <Title level={4}>Cover Letter</Title>
        {coverletterUrl ? (
          <a
            href={coverletterUrl}
            download={coverletterFileName}
            target="_blank"
          >
            Download
          </a>
        ) : (
          ""
        )}

        <Paragraph>
          <Paragraph>
            {currentProfile.coverLetter ? (
              currentProfile.coverLetter
            ) : (
              <Empty />
            )}
          </Paragraph>
        </Paragraph>
      </div>
    );
  };

  const experiences = () => {
    return (
      <div
        className="card"
        style={{
          padding: "10px",
          maxWidth: "800px",
          margin: "auto",
          marginTop: "10px",
        }}
      >
        <Title level={4}>Experiences</Title>

        {currentProfile.experiences &&
          currentProfile.experiences.map((item) => {
            var startDateTime = moment(item.startDate).format("MMMM Do YYYY");
            var endDateTime = moment(item.endDate).format("MMMM Do YYYY");
            if (item.currentJob) {
              endDateTime = "Current Job";
            }
            var duration = moment(item.startDate).diff(
              moment(item.endDate),
              "years"
            );
            return (
              <div
                style={{ marginTop: "10px" }}
                key={item.employerName + item.jobTitle}
              >
                <Space direction="vertical" size={"2"}>
                  <Text strong>{item.employerName}</Text>
                  <Text>{item.jobTitle}</Text>
                  <Text type="secondary">
                    {startDateTime} – {endDateTime}, Duration {duration} years
                  </Text>
                  <Text type="secondary">
                    {item.city}, {item.state}, {item.country}{" "}
                  </Text>
                </Space>
              </div>
            );
          })}
      </div>
    );
  };

  const educations = () => {
    return (
      <div
        className="card"
        style={{
          padding: "10px",
          maxWidth: "800px",
          margin: "auto",
          marginTop: "10px",
        }}
      >
        <Title level={4}>Education</Title>
        {currentProfile.educations &&
          currentProfile.educations.map((item) => {
            var startDateTime = moment(item.startDate).format("MMMM Do YYYY");
            var endDateTime = moment(item.endDate).format("MMMM Do YYYY");
            var duration = moment(item.startDate).diff(
              moment(item.endDate),
              "years"
            );
            return (
              <div
                style={{ marginTop: "10px" }}
                key={item.employerName + item.jobTitle}
              >
                <Space direction="vertical" size={"2"}>
                  <Text strong>{item.schoolName}</Text>
                  <Text>
                    {item.degree + " of "} {item.major + " degree"}
                  </Text>
                  {/* <Text type="secondary">
                    {startDateTime} – {endDateTime}, Duration {duration} years
                  </Text> */}
                  <Text type="secondary">
                    {item.city}, {item.state}, {item.country}{" "}
                  </Text>
                </Space>
              </div>
            );
          })}
      </div>
    );
  };
  const noProfile = () => {
    return (
      <Alert
        message="No Profile"
        type="warning"
        action={
          <Space>
            <Button size="small" type="ghost">
              <Link to="/dashboard/candidate/profile/edit">
                Complete Profile{" "}
              </Link>
            </Button>
          </Space>
        }
      />
    );
  };
  return (
    <LoadingFrame loading={loading} style={{ margin: "10px" }}>
      {!profileComplete ? (
        noProfile()
      ) : currentProfile ? (
        <>
          {contactInfo()}
          {coverLetter()}
          {experiences()}
          {educations()}
        </>
      ) : (
        ""
      )}
    </LoadingFrame>
  );
}
