import React from "react";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import AuthRoutes from "./authRoutes";
import CandidateRoutes from "./candidateRoutes";
import EmployerRoutes from "./employerRoutes";
import Login from "../pages/auth/login";
import Register from "../pages/auth/register";
import Candidates from "../pages/employer/candidates";
import CandidateView from "../pages/candidate/CandidateView";
import JobRoutes from "./jobsRoutes";
import Home from "../pages/home";
import PageNotFound from "../pages/notFound";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Skeleton } from "antd";
import ApplicantsList from "../pages/employer/applicantsList";
const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

// const RedirectIfLogin = ({
//   isLoading,
//   isAuthenticated,
//   redirectPath = "/",
// }) => {
//   if (isLoading) {
//     return <Skeleton active />;
//   }
//   if (!isAuthenticated) {
//     return <Outlet />;
//   }

//   return <Navigate to={redirectPath} replace />;
// };
export default function AppRoutes(appProps) {
  // if (appProps.user) {
  //   return (
  //     <div style={{ margin: "auto", maxWidth: "300px" }}>
  //       <Spin indicator={antIcon} />
  //     </div>
  //   );
  // }

  return (
    <Routes {...appProps}>
      {/* <Route path="*" element={<Navigate to="/404" replace />}></Route> */}
      <Route path="/404" exact element={<PageNotFound />}></Route>

      <Route path="/" exact element={<Home />}></Route>
      <Route
        exact
        path="/login"
        key={window.location.pathname}
        element={<Login {...appProps} />}
      />
      <Route
        exact
        path="/register"
        key={window.location.pathname}
        element={<Register {...appProps} />}
      />
      <Route
        path="/dashboard/candidate/*"
        element={
          <CandidateRoutes {...appProps} key={window.location.pathname} />
        }
      ></Route>
      <Route
        path="/dashboard/employer/*"
        element={<EmployerRoutes {...appProps} />}
      ></Route>

      <Route path="/jobs/*" element={<JobRoutes {...appProps} />}></Route>
      <Route path="/candidates" element={<Candidates {...appProps} />}></Route>
      <Route
        path="/candidates/:candidateId"
        element={<CandidateView {...appProps} />}
      ></Route>
      <Route path="/auth/*" element={<AuthRoutes {...appProps} />}></Route>
    </Routes>
  );
}
