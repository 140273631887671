import React, { useEffect, useState, useContext } from "react";
import { Input, Button, Popconfirm } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { MailOutlined, CheckSquareOutlined } from "@ant-design/icons";
import { db, getAuth, firebase } from "../../auth/firebase";
import { UserContext } from "../../auth/context";
import Axios from "axios";
import { apiRoutes, baseUrl } from "../../auth/apiRoute";
export default function InterestedButton(props) {
  const userContext = useContext(UserContext);
  const [isInterested, setInterested] = useState(false);
  const currentUser = userContext.user;
  const params = useParams();
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const handleVisibleChange = async (newVisible) => {
    console.log(newVisible);
    if (!newVisible) {
      setVisible(newVisible);
      return;
    } // Determining condition before show the popconfirm.

    if (!userContext.employerProfileNeeded) {
      onSave(); // next step
    } else {
      setVisible(newVisible);
    }
  };
  useEffect(async () => {
    if (currentUser) {
      const userId = currentUser.uid;

      const jobRef = await db
        .collection("users")
        .doc(userId)
        .collection("interestedCandidates")
        .doc(params.candidateId)
        .get();

      setInterested(jobRef.exists);
    }
  }, [currentUser]);

  const onSave = (e) => {
    updateUserInterestedCandidates();

    setInterested(true);
  };

  const sendNotification = async () => {
    try {
      await Axios.post(apiRoutes.emailInterestedApplicant, {
        candidateId: params.candidateId,
        employerId: currentUser.uid,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const updateUserInterestedCandidates = async () => {
    if (currentUser) {
      const userId = currentUser.uid;

      const newUser = {
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        uid: currentUser.uid,
      };

      await db
        .collection("users")
        .doc(userId)
        .collection("interestedCandidates")
        .doc(params.candidateId)
        .set(newUser);
      sendNotification();
    }
  };

  return (
    <div
      style={{
        margin: "0 10px",
        display: userContext.view == "employer" ? "block" : "none",
      }}
    >
      {!isInterested ? (
        <Popconfirm
          title="Your profile is not complete."
          visible={visible}
          onConfirm={() => {
            setVisible(false);
            navigate("/dashboard/employer/profile/edit");
          }}
          onCancel={() => {
            setVisible(false);
          }}
          onVisibleChange={handleVisibleChange}
          okText="Update Profile"
          cancelText="Never mind"
        >
          <Button onClick={onSave} key={props.uid}>
            <MailOutlined /> Send Internal Email
          </Button>
        </Popconfirm>
      ) : (
        <Button disabled>
          {" "}
          <CheckSquareOutlined style={{ color: "#002766" }} /> Email sent
        </Button>
      )}
    </div>
  );
}
