import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table, Tag, Space, Typography } from "antd";
import EmployerLayout from "./layout/layout";
import { db } from "../auth/firebase";
import SliderDrawer from "./components/SliderDrawer";
import JobDetail from "./jobDetailByPoster";
import CandidateDetail from "../candidate/CandidateDetail";
import { MenuUnfoldOutlined } from "@ant-design/icons";
export default function ApplicantsList(appProps) {
  const { Title } = Typography;
  const [postedJobs, setPostedjobs] = useState([]);
  const [selectedJobId, setselectedJobId] = useState("");
  const [sliderOpen, setSliderOpen] = useState(false);
  const [showApplicants, setShowApplicants] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState("");
  const columns = [
    {
      title: "Applicant",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => {
        return (
          <a
            onClick={() => {
              setSliderOpen(!sliderOpen);
              setSelectedApplicant(record);
            }}
          >
            {record.firstName + " " + record.lastName}
          </a>
        );
      },
    },
    {
      title: "Applied Time",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      render: (text, record) => {
        var date;
        if (record.createdAt) {
          date = record.createdAt.toDate().toDateString();
        }

        return <>{date}</>;
      },
    },
  ];
  useEffect(async () => {
    console.log(appProps.applicants);
    setPostedjobs(appProps.applicants);
  }, [appProps.applicants]);
  var classname = "applicantlist";
  if (appProps.open) {
    classname = "applicantlist showList";
  }
  return (
    <div className={classname} key={appProps.selectedJobName}>
      {/* <MenuUnfoldOutlined style={{ fontSize: "1rem" }} />
      <Title level={4}>{"Applicants"}</Title> */}
      <Title level={5}>{appProps.selectedJobName}</Title>
      <Table
        columns={columns}
        dataSource={postedJobs}
        scroll={{
          x: "400px",
        }}
      />
      <SliderDrawer open={sliderOpen}>
        <CandidateDetail
          {...selectedApplicant}
          close={() => {
            setSliderOpen(false);
          }}
        />
      </SliderDrawer>
    </div>
  );
}
