import React, { useState, useEffect } from "react";
import {
  Link,
  useNavigate,
  useSearchParams,
  useParams,
} from "react-router-dom";
import { Table, Tag, Button, Typography } from "antd";
import EmployerLayout from "./layout/layout";
import { db } from "../auth/firebase";
import { ArrowLeftOutlined } from "@ant-design/icons";
export default function JobsCandidates(appProps) {
  const { Title } = Typography;
  const [candidates, setCandidates] = useState([]);
  const [job, setJob] = useState();
  const [selectedApplicant, setSelectedApplicant] = useState([]);
  const [search, setSearch] = useSearchParams();
  const navigate = useNavigate();
  let { jobId } = useParams();

  useEffect(() => {
    const params = new URLSearchParams(search);
    var obj = {};
    for (const [key, val] of params.entries()) {
      obj[key] = val;
    }
    console.log(obj);
  }, []);
  useEffect(async () => {
    const userId = appProps.user.uid;

    getCurrentJob();
    getCurrentJobCandidates();
  }, []);

  const getCurrentJob = async () => {
    const jobQuery = await db.collection("jobs").doc(jobId).get();
    let job = jobQuery.data();

    setJob(job);
  };

  const getCurrentJobCandidates = async () => {
    const candidatesQuery = await db
      .collection("jobs")
      .doc(jobId)
      .collection("appliedUsers")
      .get();

    var userJobs = candidatesQuery.docs.map((doc) => {
      return {
        ...doc.data(),
        ...{ uid: doc.id },
        ...{ key: doc.id },
      };
    });

    setCandidates(userJobs);
  };
  const columns = [
    {
      title: "Applicant",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => {
        return (
          <a
            onClick={() => {
              navigate(
                `/dashboard/employer/posted-jobs/${jobId}/candidates/${record.uid}`
              );
            }}
          >
            {record.firstName + " " + record.lastName}
          </a>
        );
      },
    },
    {
      title: "Applied Time",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      render: (text, record) => {
        var date;
        if (record.createdAt) {
          date = record.createdAt.toDate().toDateString();
        }

        return <>{date}</>;
      },
    },
  ];
  return (
    <EmployerLayout>
      <Button onClick={() => navigate(-1)}>
        <ArrowLeftOutlined />
        go back
      </Button>

      <Title level={5}>{job && job.name ? job.name : ""}</Title>
      <Table
        columns={columns}
        dataSource={candidates}
        scroll={{
          x: "400px",
        }}
      />
    </EmployerLayout>
  );
}
