import React, { createContext, useState, useEffect } from "react";
import Axios from "axios";
import { apiRoutes, baseUrl } from "./apiRoute";
import { auth, createEmployerProfile, db } from "./firebase";

import hotelList from "../../files/hotelList";

const UserContext = createContext();

const UserContextProvider = (appProps) => {
  // the value that will be given to the context
  const [profileNeeded, setProfileComplete] = useState(true);
  const [employerProfileNeeded, setEmployerProfileComplete] = useState(true);
  const [pageId, setPageId] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [jobs, setJobs] = useState({});
  const [settingData, setSettingData] = useState();
  const [isDemo, setDemo] = useState(true);
  const [roles, setRoles] = useState();
  const [view, setView] = useState("candidate");
  const [isEmployer, setEmployer] = useState(false);
  const [user, setUser] = useState();
  const [hotels, setHotels] = useState(hotelList);
  const [searchValue, setSearchValue] = useState();
  const [candidates, setCandidates] = useState();

  let mounted = false;
  useEffect(() => {
    if (!mounted) {
      getHotels();
      checkRole();
      getJobs();
      getCandidates();
      getUser();
    }

    return () => {
      mounted = true;
    };
  }, []);
  useEffect(() => {
    addPageId();
  }, [window.location.pathname]);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  const addPageId = () => {
    setPageId(pageId + 1);
  };
  const getUser = async () => {
    if (auth.currentUser) {
      setUser(auth.currentUser);
    }
  };
  const getHotels = async () => {
    const hotelsRef = db.collection("hotels");

    var records = [];
    var querySnapshot = await hotelsRef.get();
    querySnapshot.forEach((doc) => {
      var document = doc.data();
      records.push(document);
    });

    if (records.length > 0) {
      records.push({
        name: "Not Member Hotel",
        city: "New York",
        phone: "(212) 320-2591",
        profileId: "10000",
        state: "NY",
        street: "16 East 32nd Street",
        suspended: false,
        type: "Hotel - A Div",
        zip: "10016",
      });
      setHotels(records);
    }
  };
  const checkRole = async () => {
    const signedinUser = auth.currentUser;
    if (signedinUser) {
      try {
        const response = await Axios.post(baseUrl + "checkrole", {
          uid: signedinUser.uid,
        });
        const roles = response.data.roles;

        if (roles && roles.includes("employer")) {
          setView("employer");
          setEmployer(true);
        } else {
          setView("candidate");
          setEmployer(false);
          getCandidateProfile();
        }
        // const idTokenResult = await auth.currentUser.getIdTokenResult();
        // console.log(idTokenResult.claims.roles);
        // signedinUser.roles = idTokenResult.claims.roles;
        // if (
        //   idTokenResult.claims.roles &&
        //   idTokenResult.claims.roles.includes("employer")
        // ) {
        //   setView("employer");
        // }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const getJobs = async () => {
    var citiesRef = db.collection("jobs");

    // Create a query against the collection.
    var query = citiesRef.where("userId", "==", "alanseau@test.com");
    var records = [];
    var querySnapshot = await citiesRef.orderBy("createdAt", "desc").get();
    querySnapshot.forEach((doc) => {
      var document = doc.data();
      document.id = doc.id;
      records.push(document);
    });

    setJobs(records);
  };
  const getCandidates = async () => {
    try {
      const result = await Axios.post(apiRoutes["candidates"]);
      if (result.data.length > 0) {
        // console.log(result.data);
        setCandidates(result.data);
      }
    } catch (error) {
      console.log(error.data);
    }

    // var citiesRef = db.collection("candidates");

    // var query = citiesRef.where("userId", "==", "alanseau@test.com");
    // var records = [];
    // var querySnapshot = await citiesRef.orderBy("updatedAt", "desc").get();
    // querySnapshot.forEach((doc) => {
    //   var document = doc.data();
    //   document.id = doc.id;
    //   records.push(document);
    // });
    // setCandidates(records);
  };
  const getCandidateProfile = async () => {
    const signedinUser = auth.currentUser;
    const userId = signedinUser.uid;
    const candidateRef = await db.collection("candidates").doc(userId).get();

    setProfileComplete(!candidateRef.exists);
  };
  const getEmployerProfile = async () => {
    const signedinUser = auth.currentUser;
    const userId = signedinUser.uid;
    const candidateRef = await db.collection("employers").doc(userId).get();

    setEmployerProfileComplete(!candidateRef.exists);
  };
  return (
    // the Provider gives access to the context to its children
    <UserContext.Provider
      value={{
        hotels,
        user,
        setUser,
        pageId,
        addPageId,
        settingData,
        isLoading,
        setLoading,
        isDemo,
        setDemo,
        view,
        setView,
        isEmployer,
        createEmployerProfile,
        searchValue,
        setSearchValue,
        isMobile,
        profileNeeded,
        employerProfileNeeded,
        jobs,
        candidates,
      }}
    >
      {appProps.children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
