import React, { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Table, Tag, Space, Typography } from "antd";
import EmployerLayout from "./layout/layout";
import { db } from "../auth/firebase";
import SliderDrawer from "./components/SliderDrawer";
import JobDetail from "./jobDetailByPoster";
import ApplicantsList from "./applicantsList";
import { MenuUnfoldOutlined } from "@ant-design/icons";

export default function PostedJobs(appProps) {
  const { Title } = Typography;
  const [postedJobs, setPostedjobs] = useState([]);
  const [selectedJobId, setselectedJobId] = useState("");
  const [selectedJobName, setSelectedJobName] = useState("");
  const [sliderOpen, setSliderOpen] = useState(false);
  const [showApplicants, setShowApplicants] = useState(false);
  const [selectedApplicants, setSelectedApplicants] = useState([]);
  const [search, setSearch] = useSearchParams();
  const navigate = useNavigate();
  const columns = [
    {
      title: "Position",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => (
        <a
          onClick={() => {
            console.log(sliderOpen, record);
            setSliderOpen(!sliderOpen);
            setselectedJobId(record.uid);
          }}
        >
          {text}
        </a>

        //   <Link to={`/jobs/${record.uid}`}>{text}</Link>
      ),
    },
    {
      title: "Posted Time",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      render: (text, record) => {
        var date;
        if (record.createdAt) {
          date = record.createdAt.toDate().toDateString();
        }

        return <>{date}</>;
      },
    },
    {
      title: "Saved",
      dataIndex: "saved",
      key: "saved",
      render: (text, record) => {
        if (record.savedUsers) {
          return record.savedUsers.length;
        }

        return <>{"0"}</>;
      },
    },
    {
      title: "Applicants",
      dataIndex: "applicants",
      key: "applicants",
      render: (text, record) => {
        if (record.appliedUsers.length > 0) {
          return (
            <a
              onClick={() => {
                console.log(record.appliedUsers);
                setShowApplicants(!showApplicants);
                setSelectedApplicants(record.appliedUsers);
                setSelectedJobName(record.name);
                navigate(
                  `/dashboard/employer/posted-jobs/${record.uid}/candidates`
                );
              }}
            >
              {record.appliedUsers.length}
            </a>
          );
        }

        return <>{"0"}</>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <a>Delete</a>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    const params = new URLSearchParams(search);
    var obj = {};
    for (const [key, val] of params.entries()) {
      obj[key] = val;
    }
    console.log(obj);
  }, []);
  useEffect(async () => {
    const userId = appProps.user.uid;
    const querySnapshot = await db
      .collection("jobs")
      .where("userId", "==", userId)

      .get();

    var userJobs = querySnapshot.docs.map((doc) => {
      return {
        ...doc.data(),
        ...{ uid: doc.id },
        ...{ key: doc.id },
      };
    });
    userJobs = await Promise.all(
      userJobs.map(async (job) => {
        const savedUserQuery = await db
          .collection("jobs")
          .doc(job.uid)
          .collection("savedUsers")
          .get();
        var savedUsers = [];
        savedUsers = savedUserQuery.docs.map((sudoc) => {
          return {
            ...sudoc.data(),
            ...{ uid: sudoc.id },
            ...{ key: sudoc.id },
          };
        });
        return { ...job, ...{ savedUsers: savedUsers } };
      })
    );
    userJobs = await Promise.all(
      userJobs.map(async (job) => {
        const savedUserQuery = await db
          .collection("jobs")
          .doc(job.uid)
          .collection("appliedUsers")
          .get();
        var appliedUsers = [];
        appliedUsers = savedUserQuery.docs.map((sudoc) => {
          return {
            ...sudoc.data(),
            ...{ uid: sudoc.id },
            ...{ key: sudoc.id },
          };
        });
        return { ...job, ...{ appliedUsers: appliedUsers } };
      })
    );
    setPostedjobs(userJobs);
  }, []);
  let classname = "JobsTable ";
  if (showApplicants) {
    classname = "hideTable ";
  }

  let goBackClassName = "hide";

  if (showApplicants) {
    goBackClassName = "hide";
  }
  return (
    <EmployerLayout>
      <div className="JobsTableContainer">
        <div className={classname}>
          <div className={showApplicants ? "show" : "hide"}>
            <a
              onClick={() => {
                setShowApplicants(!showApplicants);
              }}
            >
              Go back <MenuUnfoldOutlined />
            </a>
          </div>
          <div className={showApplicants ? "hide" : "show"}>
            <Table
              columns={columns}
              dataSource={postedJobs}
              scroll={{
                x: "400px",
              }}
            />
          </div>
        </div>

        <ApplicantsList
          open={showApplicants}
          {...appProps}
          applicants={selectedApplicants}
          selectedJobName={selectedJobName}
        />
      </div>

      <SliderDrawer open={sliderOpen}>
        <JobDetail
          id={selectedJobId}
          close={() => {
            setSliderOpen(false);
          }}
        />
      </SliderDrawer>
    </EmployerLayout>
  );
}
