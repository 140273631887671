import React, { useEffect, useState, useContext } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Pagination, Typography } from "antd";
import JobCard from "./components/jobCard";
import { db } from "../auth/firebase";
import SearchBar from "../../components/searchBar";
import FilterBar from "./components/filter";
import JobDetail from "./jobDetail";
import { UserContext } from "../auth/context";
import LoadingFrame from "../../layout/loadingFrame";
const { Title, Text } = Typography;

export default function Jobs(appProps) {
  const [search, setSearch] = useSearchParams();

  const [allJobs, setJobs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [startIndex, setStartIndex] = useState();
  const [endIndex, setEndIndex] = useState();
  const [selectedJob, setSelectedJob] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const userContext = useContext(UserContext);
  const [searching, setSearching] = useState(false);
  let isMounted = false;
  useEffect(() => {
    userContext.addPageId();
  }, []);
  useEffect(async () => {
    let jobs = userContext.jobs;

    if (!isMounted) {
      if (jobs && jobs.length > 0) {
        setJobs(jobs);
        setSelectedJob(jobs[0]);

        setFilteredJobs(jobs);
        // setSearch(true);
        changePage(1);

        filterJobsBySearchKeyWord(userContext.searchValue);
      }
    }

    return () => {
      isMounted = true;
    };
  }, [userContext]);

  useEffect(() => {
    filterJobsBySearchKeyWord(userContext.searchValue);
  }, [userContext.searchValue, allJobs]);
  useEffect(() => {
    if (filteredJobs.length == 0) {
      setSelectedJob("");
    } else {
      setSelectedJob(filteredJobs[0]);
    }
  }, [filteredJobs]);
  const getJobs = () => {
    var citiesRef = db.collection("jobs");
    var records = [];
    citiesRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        var document = doc.data();
        document.id = doc.id;
        records.push(document);
      });
      setJobs(records);
    });
  };
  const applyFilter = (obj) => {
    console.log(obj);
    console.log(allJobs);

    if (obj && obj.hasOwnProperty("type") && obj.type) {
      if (obj.type == "Any") {
        setFilteredJobs(allJobs);
      } else {
        setFilteredJobs(filteredJobs.filter((item) => item.type == obj.type));
      }
    }

    if (obj && obj.hasOwnProperty("posedTime") && obj.posedTime) {
      var currentDay = new Date().getTime();
      switch (obj.posedTime) {
        case "0":
          setFilteredJobs(allJobs);
          break;
        case "1":
          setFilteredJobs(
            allJobs.filter((item) => {
              if (!item.createdAt) return false;
              var dayTime = item.createdAt.toMillis();
              var diff = (currentDay - dayTime) / 1000 / 24 / 60 / 60;
              console.log(diff);
              return diff < 2;
            })
          );
          break;
        case "2":
          setFilteredJobs(
            allJobs.filter((item) => {
              if (!item.createdAt) return false;
              var dayTime = item.createdAt.toMillis();
              var diff = (currentDay - dayTime) / 1000 / 24 / 60 / 60;
              console.log(diff);
              return diff < 7;
            })
          );
          break;
        case "3":
          setFilteredJobs(
            allJobs.filter((item) => {
              if (!item.createdAt) return false;
              var dayTime = item.createdAt.toMillis();
              var diff = (currentDay - dayTime) / 1000 / 24 / 60 / 60;
              console.log(diff);
              return diff < 14;
            })
          );
          break;
        case "4":
          setFilteredJobs(
            allJobs.filter((item) => {
              if (!item.createdAt) return false;
              var dayTime = item.createdAt.toMillis();
              var diff = (currentDay - dayTime) / 1000 / 24 / 60 / 60;

              return diff < 30;
            })
          );
          break;
        case "5":
          setFilteredJobs(
            allJobs.filter((item) => {
              if (!item.createdAt) return false;
              var dayTime = item.createdAt.toMillis();
              var diff = (currentDay - dayTime) / 1000 / 24 / 60 / 60;

              return diff > 30;
            })
          );
          break;
        default:
      }
    }

    if (obj && obj.hasOwnProperty("hotelName") && obj.hotelName) {
      if (obj.hotelName == "All") {
        setFilteredJobs(allJobs);
      } else {
        setFilteredJobs(
          filteredJobs.filter((item) => obj.hotelName.includes(item.hotel.name))
        );
      }
    }
  };
  const onSelect = (job) => {
    setLoading(true);
    setSelectedJob(job);
    setLoading(false);
  };
  const filterJobsBySearchKeyWord = (keyword) => {
    var value = null;
    if (allJobs.length == 0) {
      return;
    }
    if (keyword) {
      value = keyword.toLowerCase();
    }

    if (!value) {
      setFilteredJobs(allJobs);
      setSelectedJob(allJobs[0]);
    } else {
      let newStudents = allJobs.filter((item) => {
        // item["email"].includes(value);
        let keys = Object.keys(item);
        var bool = false;

        keys.forEach((key) => {
          if (key != "_id" && key != "key") {
            if (typeof item[key] === "string" && item[key]) {
              if (item[key].toLowerCase().includes(value)) {
                bool = true;

                return;
              }
            }
          }
        });
        return bool;
      });

      setFilteredJobs(newStudents);
      setSelectedJob(newStudents[0]);
      if (newStudents.length == 0) {
        setSelectedJob("");
      }
    }
  };
  const showCards = (filteredJobs) => {
    if (filteredJobs) {
      return filteredJobs.map((item) => {
        return <JobCard {...item} key={item.id} onSelect={onSelect} />;
      });
    }
  };
  const changePage = (page) => {
    setCurrentPage(page);

    const indexOfLastTodo = page * pageSize;
    const indexOfFirstTodo = indexOfLastTodo - pageSize;
    setStartIndex(indexOfFirstTodo);
    setEndIndex(indexOfLastTodo);

    setPageQuery(page);
  };
  // const filterDataByPage = (filteredJobs) => {
  //   if (filteredJobs) {
  //     console.log(filteredJobs.slice(indexOfFirstTodo, indexOfLastTodo));
  //     setFilteredJobs(filteredJobs.slice(indexOfFirstTodo, indexOfLastTodo));
  //   }
  // };

  const getCurrentQuery = () => {
    const params = new URLSearchParams(search);
    var obj = {};
    for (const [key, val] of params.entries()) {
      obj[key] = val;
    }
    if (obj.p) {
      setCurrentPage(obj.p);
      changePage(obj.p);
    }

    if (obj.s) {
      filterJobsBySearchKeyWord(obj.s);
    }

    console.log({ obj });
  };

  const setPageQuery = (page) => {
    const params = new URLSearchParams(search);

    params.append("p", page);

    // history.replace({ search: params.toString() });
  };
  return (
    <div style={{ overflow: "auto" }} key={userContext.pageId}>
      {/* <SearchBar onFinish={filterJobsBySearchKeyWord} defaultValue={""} /> */}
      {/* <div
        style={{
          position: "fixed",
          zIndex: "1",
          background: "white",
          height: "60px",
        }}
        key={filteredJobs[0]}
      >
        <FilterBar
          style={{
            position: "fixed",
            zIndex: "1",
          }}
          applyFilter={applyFilter}
        />
      </div> */}

      <div className="jobs-container">
        <div
          style={{
            position: "relative",
            maxWidth: "400px",
            height: "80vh",
          }}
        >
          <div
            style={{
              height: "70vh",
              maxHeight: "100vh",
              overflow: "auto",
            }}
          >
            {showCards(
              filteredJobs && filteredJobs.length > 0
                ? filteredJobs.slice(startIndex, endIndex)
                : []
            )}
          </div>

          <div
            style={{
              margin: "auto",
              width: "300px",
              maxWidth: "350px",

              bottom: "0px",
              position: "absolute",
              textAlign: "center",
            }}
          >
            <Pagination
              simple={true}
              current={currentPage}
              onChange={changePage}
              total={allJobs.length}
              pageSize={pageSize}
            />
          </div>
        </div>
      </div>

      <div
        className="hide-on-mobile"
        style={{
          marginLeft: "375px",
          position: "relative",
          padding: "1.2rem",
        }}
      >
        <LoadingFrame loading={loading}>
          <JobDetail
            style={{
              marginLeft: "300px",
              position: "relative",
              padding: "0 1rem",
            }}
            key={selectedJob.id}
            id={selectedJob.id}
            job={selectedJob}
            {...appProps}
          />
        </LoadingFrame>
      </div>
    </div>
  );
}
