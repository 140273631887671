import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Col,
  Row,
  Select,
  InputNumber,
  DatePicker,
  AutoComplete,
  message,
  Upload,
} from "antd";
import ImgCrop from "antd-img-crop";
import { UploadOutlined } from "@ant-design/icons";
import { db, storage, getAuth } from "../../auth/firebase";
import {
  ref,
  uploadBytes,
  updateMetadata,
  getStorage,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { updateProfile } from "firebase/auth";
export default function ContactDetails() {
  const [fileList, setFileList] = useState([
    {
      uid: "-1",
      name: "image.png",
      status: "done",
      url: "https://firebasestorage.googleapis.com/v0/b/vuefirebase-c2006.appspot.com/o/photoURL%2Favatar_photo.png?alt=media&token=9a19baea-6a77-4301-87ae-f48c66873399",
    },
  ]);
  useEffect(() => {
    setFileList([
      {
        uid: "-1",
        name: "image.png",
        status: "done",
        url:
          getAuth().currentUser.photoURL ||
          "https://firebasestorage.googleapis.com/v0/b/vuefirebase-c2006.appspot.com/o/photoURL%2Favatar_photo.png?alt=media&token=9a19baea-6a77-4301-87ae-f48c66873399",
      },
    ]);
  }, []);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList.length > 0) {
      uploadFirebase(newFileList[0]);
    }
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M && false;
  };

  const uploadFirebase = async (file) => {
    console.log(file);
    const auth = getAuth();
    const authUser = auth.currentUser;
    const uid = authUser.uid;
    const resumeRef = ref(storage, `photoURL/${uid}/photo`);

    const snapshot = await uploadBytes(resumeRef, file.originFileObj);

    const url = await getDownloadURL(resumeRef);

    // if (authUser.photoURL != "") {
    //   const fileRef = ref(
    //     storage,
    //     `photoURL/${uid}/${this.state.originalFileName}`
    //   );
    //   await deleteObject(fileRef);
    // }

    const newMetadata = {
      cacheControl: "public,max-age=300",
      contentType: file.type,
    };

    await updateMetadata(resumeRef, newMetadata);

    await updateProfile(auth.currentUser, {
      photoURL: url,
    });
    db.collection("users").doc(uid).update({ photoUrl: url });
  };
  const handleChange = (info) => {
    // console.log(info);
    // if (info.file.status === "uploading") {
    //   this.setState({ loading: true });
    //   return;
    // }
    // if (info.file.status === "done") {
    //   // Get this url from response in real world.
    //   //   this.uploadFirebase(info);
    //   getBase64(info.file.originFileObj, (imageUrl) =>
    //     this.setState({
    //       imageUrl,
    //       loading: false,
    //     })
    //   );
    // }
  };
  const onPreview = async (file) => {
    let src = file.url;

    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);

        reader.onload = () => resolve(reader.result);
      });
    }

    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  return (
    <div>
      <ImgCrop rotate>
        <Upload
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
          onPreview={onPreview}
          beforeUpload={beforeUpload}
        >
          {fileList.length < 1 && "+ Upload"}
        </Upload>
      </ImgCrop>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" xs={24} md={12} sm={24}>
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              { required: true, message: "Please input your First Name!" },
            ]}
          >
            <Input placeholder="First Name" />
          </Form.Item>
        </Col>
        <Col className="gutter-row" xs={24} md={12} sm={24}>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[
              { required: true, message: "Please input your Last Name!" },
            ]}
          >
            <Input placeholder="Last Name" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="phone"
        label="Phone"
        rules={[{ required: true, message: "Please input your Phone!" }]}
      >
        <Input placeholder="Phone" />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          {
            required: true,
            message: "Please input your E-mail!",
          },
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>
      <Form.Item name="address" label="Address">
        <Input placeholder="Street" />
      </Form.Item>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" xs={24} md={8} sm={24}>
          <Form.Item name="city" label="City">
            <Input placeholder="City" />
          </Form.Item>
        </Col>
        <Col className="gutter-row" xs={24} md={8} sm={24}>
          <Form.Item name="state" label="State">
            <Input placeholder="State" />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={8} sm={24}>
          <Form.Item name="zip" label="Zip">
            <Input placeholder="Zip" />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
