import React, { useEffect, useState } from "react";
import { Input } from "antd";
import {
  useSearchParams,
  useLocation,
  useNavigate,
  createSearchParams,
} from "react-router-dom";

import { UserContext } from "../pages/auth/context";
const { Search } = Input;
export default function SearchBar(props) {
  const userContext = React.useContext(UserContext);
  const [search, setSearch] = useSearchParams();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    getCurrentQuery();
    console.log("getting search value");
  }, []);
  const onSearch = (value) => {
    userContext.setSearchValue(value);
    navigate({
      pathname: "jobs",
      search: createSearchParams({
        s: value,
      }).toString(),
    });
  };
  const getCurrentQuery = () => {
    const params = new URLSearchParams(search);
    var obj = {};
    for (const [key, val] of params.entries()) {
      obj[key] = val;
    }
    console.log("current search value: ", obj.s);
    userContext.setSearchValue(obj.s);
  };
  const setFilterQuery = (value) => {
    const params = new URLSearchParams(search);

    params.append("s", value);
    console.log(params.toString());
    if (location.pathname != "/jobs") {
      navigate("/jobs");
      // history.push({ pathname: "/jobs", search: params.toString() });
    } else {
      // history.push({ search: params.toString() });
    }
  };

  return (
    <Search
      placeholder="input search text"
      onSearch={onSearch}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      enterButton
      defaultValue={userContext.searchValue}
      className={props.classname}
      // style={{ margin: "auto", maxWidth: "500px" }}
    />
  );
}
