import React from "react";
import { Route, Routes } from "react-router-dom";
import candidateDashboard from "../pages/candidate/dashboard";
import profileView from "../pages/candidate/profileView";
import Jobs from "../pages/jobs/jobs";
import JobView from "../pages/jobs/jobView";
import profileEditing from "../pages/candidate/profileEditing";
import Home from "../pages/home";
export default function JobRoutes(appProps) {
  return (
    <Routes>
      <Route exact path="/" element={<Jobs {...appProps} />} />

      <Route exact path="/:id" element={<JobView {...appProps} />} />
    </Routes>
  );
}
