// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signOut,
  signInWithEmailAndPassword,
} from "firebase/auth";
import firebase from "firebase/compat/app";
import { getStorage } from "firebase/storage";
import "firebase/compat/firestore";
import Axios from "axios";
import { baseUrl } from "./apiRoute";
const firebaseConfig = {
  apiKey: "AIzaSyBtY3wzU8PqqU94FsLTxzJo6uR41bSGHAA",
  authDomain: "vuefirebase-c2006.firebaseapp.com",
  databaseURL: "https://vuefirebase-c2006.firebaseio.com",
  projectId: "vuefirebase-c2006",
  storageBucket: "vuefirebase-c2006.appspot.com",
  messagingSenderId: "515689281849",
  appId: "1:515689281849:web:eefcf1cc53f3864990d0e8",
  storageBucket: "gs://vuefirebase-c2006.appspot.com",
};

const app = firebase.initializeApp(firebaseConfig);
const auth = getAuth();
const db = app.firestore();
const storage = getStorage(app);

const registerWithEmailAndPassword = async (
  firstName,
  lastName,
  email,
  password,
  role
) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);

    const user = res.user;
    console.log(user);
    await db
      .collection("users")
      .doc(user.uid)
      .set(
        {
          uid: user.uid,
          firstName,
          lastName,
          email,
          roles: [role],
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      );
    await db
      .collection(role + "s")
      .doc(user.uid)
      .set(
        {
          uid: user.uid,
          firstName,
          lastName,
          email,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      );

    let endpoint = "updaterole";
    await Axios.post(baseUrl + endpoint, { uid: user.uid, role: role });

    return user;
  } catch (err) {
    console.log(err);
    var newErr = new Error(err.message);
    newErr.code = err.code;
    // alert(err.message);
    throw newErr;
  }
};
const updateUserRole = async (uid, role) => {};
const createEmployerProfile = async (role) => {
  var authUser = auth.currentUser;
  console.log(authUser);
  var candidateProfile = await db.collection("users").doc(authUser.uid).get();
  var candidateData = candidateProfile.data();
  await db
    .collection("users")
    .doc(authUser.uid)
    .set(
      { roles: firebase.firestore.FieldValue.arrayUnion(role) },
      { merge: true }
    );

  await db
    .collection(role + "s")
    .doc(authUser.uid)
    .set(candidateData, { merge: true });

  let endpoint = "updaterole";
  await Axios.post(baseUrl + endpoint, { uid: authUser.uid, role: role });
};
const loginUser = async (email, password) => {
  try {
    const credential = await signInWithEmailAndPassword(auth, email, password);
    return credential.user;
  } catch (err) {
    console.log(err);
    var newErr = new Error(err.message);
    newErr.code = err.code;
    throw newErr;
  }
};

const sendPasswordResetEmail = async (email) => {
  try {
    await auth.sendPasswordResetEmail(email);
  } catch (err) {
    console.error(err);
  }
};
const logout = () => {
  signOut(auth)
    .then(() => {
      // Sign-out successful.
      window.location.href = "/login";
    })
    .catch((error) => {
      // An error happened.
      console.log(error);
    });
};
export {
  firebase,
  auth,
  db,
  storage,
  getAuth,
  //   signInWithGoogle,
  signInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordResetEmail,
  loginUser,
  logout,
  createEmployerProfile,
};
