export default [
  {
    name: "Avalon Hotel",
    city: "New York",
    phone: "(212) 320-2591",
    profileId: "118",
    state: "NY",
    street: "16 East 32nd Street",
    suspended: false,
    type: "Hotel - A Div",
    zip: "10016",
  },
  {
    name: "Comfort Inn Manhattan Bridge",
    city: "New York",
    phone: "(212) 925-1212",
    profileId: "132",
    state: "NY",
    street: "61-63 Chrystie Street",
    suspended: true,
    type: "Hotel - Labor",
    zip: "10002",
  },
  {
    name: "Courtyard by Marriott Manhattan / Chelsea",
    city: "New York",
    phone: "(212) 967-6000",
    profileId: "138",
    state: "NY",
    street: "135 West 30th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10001",
  },
  {
    name: "Crowne Plaza Times Square Manhattan",
    city: "New York",
    phone: "(212) 315-6050",
    profileId: "151",
    state: "NY",
    street: "1605 Broadway",
    suspended: true,
    type: "Hotel - Labor",
    zip: "10019",
  },
  {
    name: "Hilton Garden Inn New York / Chelsea",
    city: "New York",
    phone: "(212) 564-2181",
    profileId: "189",
    state: "NY",
    street: "121 West 28th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10001",
  },
  {
    name: "Knickerbocker",
    city: "New York",
    phone: "(212) 204-4980",
    profileId: "313",
    state: "NY",
    street: "6 Times Square",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10036",
  },
  {
    name: "Luxe Life Hotel",
    city: "New York",
    phone: "212-615-9900",
    profileId: "1955",
    state: "NY",
    street: "19 West 31st Street",
    suspended: true,
    type: "Hotel - A Div",
    zip: "10001",
  },
  {
    name: "MAve Hotel",
    city: "New York",
    phone: "(212) 532-7373",
    profileId: "323",
    state: "NY",
    street: "62 Madison Avenue",
    suspended: true,
    type: "Hotel - Labor",
    zip: "10016",
  },
  {
    name: "NYC ESH Lexington",
    city: "New York",
    phone: "(212) 755-4240",
    profileId: "249",
    state: "NY",
    street: "525 Lexington Avenue",
    suspended: true,
    type: "Hotel - Labor",
    zip: "10017",
  },
  {
    name: "Roosevelt Hotel",
    city: "New York",
    phone: "(212) 885-6086",
    profileId: "338",
    state: "NY",
    street: "45 East 45th Street",
    suspended: true,
    type: "Hotel - Suspension",
    zip: "10017",
  },
  {
    name: "Row NYC",
    city: "New York",
    phone: "(212) 536-1157",
    profileId: "277",
    state: "NY",
    street: "700 8th Avenue",
    suspended: true,
    type: "Hotel - Labor",
    zip: "10036",
  },
  {
    name: "Stewart Hotel",
    city: "New York",
    phone: "(646) 733-2000",
    profileId: "342",
    state: "NY",
    street: "371 Seventh Avenue",
    suspended: true,
    type: "Hotel - Labor",
    zip: "10001",
  },
  {
    name: "Surrey",
    city: "New York",
    phone: "(646) 358-3618",
    profileId: "343",
    state: "NY",
    street: "20 East 76th Street",
    suspended: true,
    type: "Hotel - Labor",
    zip: "10021",
  },
  {
    name: "Tuscany - A St Giles Signature Hotel",
    city: "New York",
    phone: "(212) 686-1600",
    profileId: "346",
    state: "NY",
    street: "120 East 39th Street",
    suspended: true,
    type: "Hotel - Labor",
    zip: "10016",
  },
  {
    name: "Wagner Hotel",
    city: "New York",
    phone: "(917) 617-7901",
    profileId: "348",
    state: "NY",
    street: "2 West Street",
    suspended: true,
    type: "Hotel - Labor",
    zip: "10004",
  },
  {
    name: "Washington Square Hotel",
    city: "New York",
    phone: "(212) 777-9515",
    profileId: "366",
    state: "NY",
    street: "103 Waverly Place",
    suspended: true,
    type: "Hotel - Labor",
    zip: "10011",
  },
  {
    name: "Wellington Hotel",
    city: "New York",
    phone: "(646) 277-0852",
    profileId: "367",
    state: "NY",
    street: "871 7th Ave",
    suspended: true,
    type: "Hotel - Labor",
    zip: "10019",
  },
  {
    name: "Westgate New York Grand Central",
    city: "New York",
    phone: "(212) 986-8800",
    profileId: "370",
    state: "NY",
    street: "304 East 42nd Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10017",
  },
  {
    name: "YOTEL New York",
    city: "New York",
    phone: "(646) 449-7742",
    profileId: "373",
    state: "NY",
    street: "570 Tenth Avenue",
    suspended: true,
    type: "Hotel - Labor",
    zip: "10036",
  },
  {
    name: "1 Hotel Brooklyn Bridge",
    city: "Brooklyn",
    phone: "(347) 696-2560",
    profileId: "100",
    state: "NY",
    street: "60 Furman Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "11201",
  },
  {
    name: "1 Hotel Central Park",
    city: "New York",
    phone: "(212) 703-2001",
    profileId: "101",
    state: "NY",
    street: "1414 Avenue of the Americas",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10019",
  },
  {
    name: "11 Howard",
    city: "New York",
    phone: "(212) 235-1111",
    profileId: "102",
    state: "NY",
    street: "11 Howard Street",
    suspended: false,
    type: "Hotel - A Div",
    zip: "10013",
  },
  {
    name: "Ace Hotel Brooklyn",
    city: "Brooklyn",
    phone: "(201) 932-6098",
    profileId: "7143",
    state: "NY",
    street: "252 Schermerhorn Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "11217",
  },
  {
    name: "Ace Hotel New York",
    city: "New York",
    phone: "(212) 679-2222",
    profileId: "105",
    state: "NY",
    street: "20 West 29th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10001",
  },
  {
    name: "AKA Central Park",
    city: "New York",
    phone: "(212) 753-3500",
    profileId: "106",
    state: "NY",
    street: "42 West 58th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10036",
  },
  {
    name: "AKA Nomad",
    city: "New York",
    phone: "(310) 268-8344",
    profileId: "337",
    state: "NY",
    street: "131 Madison Avenue",
    suspended: false,
    type: "Hotel - A Div",
    zip: "10016",
  },
  {
    name: "AKA Sutton Place",
    city: "New York",
    phone: "(212) 752-8888",
    profileId: "107",
    state: "NY",
    street: "330 East 56th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10022",
  },
  {
    name: "AKA Times Square",
    city: "New York",
    phone: "(212) 764-5700",
    profileId: "108",
    state: "NY",
    street: "123 West 44th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10036",
  },
  {
    name: "Algonquin Hotel Times Square, Autograph Collection",
    city: "New York",
    phone: "(212) 419-9330",
    profileId: "293",
    state: "NY",
    street: "59 West 44th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10036",
  },
  {
    name: "Aloft Harlem",
    city: "New York",
    phone: "(212) 749-4000",
    profileId: "111",
    state: "NY",
    street: "2296 Frederick Douglass Blvd.",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10027",
  },
  {
    name: "Aloft New York LaGuardia Airport",
    city: "East Elmhurst",
    phone: "(347) 606-6200",
    profileId: "112",
    state: "NY",
    street: "100-15 Ditmars Blvd.",
    suspended: false,
    type: "Hotel - Union",
    zip: "11369",
  },
  {
    name: "AMAN New York",
    city: "New York",
    phone: "(212) 970-2626",
    profileId: "9222",
    state: "NY",
    street: "730 5th Avenue",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10019",
  },
  {
    name: "Andaz 5th Avenue",
    city: "New York",
    phone: "(646) 213-6604",
    profileId: "113",
    state: "NY",
    street: "485 5th Avenue",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10017",
  },
  {
    name: "Arlo NoMad",
    city: "New York",
    phone: "(212) 806-7000",
    profileId: "115",
    state: "NY",
    street: "11 East 31st Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10016",
  },
  {
    name: "Arlo SoHo",
    city: "New York",
    phone: "(212) 342-7000",
    profileId: "116",
    state: "NY",
    street: "231 Hudson Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10013",
  },
  {
    name: "Arthouse Hotel New York City",
    city: "New York",
    phone: "(212) 651-3302",
    profileId: "117",
    state: "NY",
    street: "2178 Broadway at West 77th Street",
    suspended: false,
    type: "Hotel - A Div",
    zip: "10024",
  },
  {
    name: "Baccarat Hotel and Residences New York",
    city: "New York",
    phone: "(212) 790-8800",
    profileId: "119",
    state: "NY",
    street: "28 West 53rd Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10019",
  },
  {
    name: "Beekman - A Thompson Hotel",
    city: "New York",
    phone: "(212) 233-2300",
    profileId: "294",
    state: "NY",
    street: "123 Nassau Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10038",
  },
  {
    name: "Belleclaire Hotel, a member of Triumph Hotels",
    city: "New York",
    phone: "(212) 453-4021",
    profileId: "120",
    state: "NY",
    street: "2175 Broadway",
    suspended: false,
    type: "Hotel - A Div",
    zip: "10024",
  },
  {
    name: "Benjamin",
    city: "New York",
    phone: "(212) 715-2545",
    profileId: "295",
    state: "NY",
    street: "125 East 50th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10022",
  },
  {
    name: "Bentley Hotel",
    city: "New York",
    phone: "(212) 644-6000",
    profileId: "2406",
    state: "NY",
    street: "500 East 62nd Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10065",
  },
  {
    name: "Cachet Hotel NYC",
    city: "New York",
    phone: "(212) 947-2999",
    profileId: "2432",
    state: "NY",
    street: "510 West 42nd Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10036",
  },
  {
    name: "Cambria Hotel New York - Chelsea",
    city: "New York",
    phone: "(212) 244-4477",
    profileId: "123",
    state: "NY",
    street: "123 West 28th Street",
    suspended: false,
    type: "Hotel - Union",
    zip: "10001",
  },
  {
    name: "Candlewood Suites New York City Times Square",
    city: "New York",
    phone: "(212) 967-2254",
    profileId: "126",
    state: "NY",
    street: "339 West 39th Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10018",
  },
  {
    name: "Carlyle, A Rosewood Hotel",
    city: "New York",
    phone: "(212) 570-7159",
    profileId: "297",
    state: "NY",
    street: "35 East 76th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10021",
  },
  {
    name: "Cassa Hotel New York",
    city: "New York",
    phone: "(212) 302-8700",
    profileId: "129",
    state: "NY",
    street: "70 West 45th Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10036",
  },
  {
    name: "Cassa Times Square",
    city: "New York",
    phone: "(212) 239-9900",
    profileId: "130",
    state: "NY",
    street: "515 9th Avenue",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10018",
  },
  {
    name: "Chatwal, New York, A Luxury Collection Hotel",
    city: "New York",
    phone: "(212) 764-6200",
    profileId: "298",
    state: "NY",
    street: "130 West 44th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10036",
  },
  {
    name: "Club Wyndham Midtown 45",
    city: "New York",
    phone: "(212) 867-5100",
    profileId: "372",
    state: "NY",
    street: "205 East 45th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10017",
  },
  {
    name: "Colony Club",
    city: "New York",
    phone: "(212) 838-4200",
    profileId: "131",
    state: "NY",
    street: "564 Park Avenue",
    suspended: false,
    type: "Hotel - Union",
    zip: "10065",
  },
  {
    name: "Concorde Hotel New York",
    city: "New York",
    phone: "(212) 355-2755",
    profileId: "133",
    state: "NY",
    street: "127 East 55th Street",
    suspended: false,
    type: "Hotel - Union",
    zip: "10022",
  },
  {
    name: "Conrad New York Downtown",
    city: "New York",
    phone: "(212) 945-0100",
    profileId: "134",
    state: "NY",
    street: "102 North End Avenue",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10282",
  },
  {
    name: "Conrad New York Midtown",
    city: "New York",
    phone: "(212) 468-8701",
    profileId: "135",
    state: "NY",
    street: "151 West 54th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10019",
  },
  {
    name: "Courtyard & Residence Inn by Marriott Central Park",
    city: "New York",
    phone: "(646) 273-7800",
    profileId: "136",
    state: "NY",
    street: "1717 Broadway at 54th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10019",
  },
  {
    name: "Courtyard by Marriott Long Island City",
    city: "Long Island City",
    phone: "(718) 786-0003",
    profileId: "137",
    state: "NY",
    street: "29-15 Queens Plaza North",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "11101",
  },
  {
    name: "Courtyard by Marriott Manhattan / Soho",
    city: "New York",
    phone: "(212) 209-6302",
    profileId: "139",
    state: "NY",
    street: "181 Varick Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10014",
  },
  {
    name: "Courtyard by Marriott Manhattan Fifth Avenue",
    city: "New York",
    phone: "(212) 447-1500",
    profileId: "140",
    state: "NY",
    street: "3 East 40th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10016",
  },
  {
    name: "Courtyard by Marriott Manhattan Upper East Side",
    city: "New York",
    phone: "(917) 492-3712",
    profileId: "141",
    state: "NY",
    street: "410 East 92nd Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10128",
  },
  {
    name: "Courtyard by Marriott New York JFK Airport",
    city: "Jamaica",
    phone: "(718) 848-2121",
    profileId: "142",
    state: "NY",
    street: "145-11 North Condit Avenue",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "11436",
  },
  {
    name: "Courtyard by Marriott Tarrytown Westchester County",
    city: "Tarrytown",
    phone: "(914) 631-1122",
    profileId: "11317",
    state: "NY",
    street: "475 White Plains Road",
    suspended: false,
    type: "Hotel - GRIWA",
    zip: "10591",
  },
  {
    name: "Courtyard by Marriott Times Square",
    city: "New York",
    phone: "(212) 391-0088",
    profileId: "144",
    state: "NY",
    street: "114 West 40th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10018",
  },
  {
    name: "Courtyard Marriott Newark Downtown",
    city: "Newark",
    phone: "(973) 848-0070",
    profileId: "145",
    state: "NJ",
    street: "858 Broad Street",
    suspended: false,
    type: "Hotel - GRIWA",
    zip: "07102",
  },
  {
    name: "Courtyard Marriott Newark Elizabeth",
    city: "Elizabeth",
    phone: "(908) 436-9800",
    profileId: "146",
    state: "NJ",
    street: "87 International Blvd",
    suspended: false,
    type: "Hotel - GRIWA",
    zip: "07201",
  },
  {
    name: "Courtyard Marriott Newark Liberty International Airport",
    city: "Newark",
    phone: "(973) 643-8500",
    profileId: "147",
    state: "NJ",
    street: "600 Routes 1&9",
    suspended: false,
    type: "Hotel - GRIWA",
    zip: "07114",
  },
  {
    name: "Courtyard New York Manhattan / Midtown East",
    city: "New York",
    phone: "(212) 317-7906",
    profileId: "148",
    state: "NY",
    street: "866 Third Avenue - 12th Floor",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10022",
  },
  {
    name: "Courtyard New York Manhattan/Midtown West",
    city: "New York",
    phone: "(212) 553-3000",
    profileId: "2487",
    state: "NY",
    street: "461 West 34th Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10001",
  },
  {
    name: "Crowne Plaza HY36",
    city: "New York",
    phone: "(646) 928-3800",
    profileId: "149",
    state: "NY",
    street: "320 W 36th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10018",
  },
  {
    name: "Crowne Plaza JFK Airport",
    city: "Jamaica",
    phone: "(718) 530-1160",
    profileId: "150",
    state: "NY",
    street: "138-10 135th Avenue",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "11436",
  },
  {
    name: "del Lago Resort & Casino",
    city: "Waterloo",
    phone: "(315) 946-1777",
    profileId: "152",
    state: "NY",
    street: "1133 NY-414",
    suspended: false,
    type: "Hotel - GRIWA",
    zip: "13165",
  },
  {
    name: "Dominick Hotel",
    city: "New York",
    phone: "(212) 842-5525",
    profileId: "299",
    state: "NY",
    street: "246 Spring Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10013",
  },
  {
    name: "DoubleTree by Hilton Metropolitan - New York City",
    city: "New York",
    phone: "(212) 752-7000",
    profileId: "153",
    state: "NY",
    street: "569 Lexington Avenue",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10022",
  },
  {
    name: "DoubleTree by Hilton Newark Penn Station",
    city: "Newark",
    phone: "(973) 622-5000",
    profileId: "154",
    state: "NJ",
    street: "1048 Raymond Blvd.",
    suspended: false,
    type: "Hotel - GRIWA",
    zip: "07102",
  },
  {
    name: "Dream Midtown",
    city: "New York",
    phone: "(646) 625-4840",
    profileId: "155",
    state: "NY",
    street: "210 West 55th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10019",
  },
  {
    name: "EC 58th Street LLC",
    city: "New York",
    phone: "(212) 554-6400",
    profileId: "214",
    state: "NY",
    street: "358 West 58th Street",
    suspended: false,
    type: "Hotel - Special",
    zip: "10019",
  },
  {
    name: "Edison Hotel, a member of Triumph Hotels",
    city: "New York",
    phone: "(212) 840-5000",
    profileId: "157",
    state: "NY",
    street: "228 West 47th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10036",
  },
  {
    name: "Element New York Times Square West",
    city: "New York",
    phone: "(212) 643-0770",
    profileId: "158",
    state: "NY",
    street: "311 West 39th Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10018",
  },
  {
    name: "Embassy Suites by Hilton Midtown Manhattan",
    city: "New York",
    phone: "(212) 784-4289",
    profileId: "159",
    state: "NY",
    street: "60 West 37th Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10036",
  },
  {
    name: "Equinox Hotel - Hudson Yards",
    city: "New York",
    phone: "(646) 901-2935",
    profileId: "160",
    state: "NY",
    street: "33 Hudson Yards",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10001",
  },
  {
    name: "Evelyn Hotel, a member of Triumph Hotels",
    city: "New York",
    phone: "(212) 453-4021",
    profileId: "161",
    state: "NY",
    street: "7 E 27th St",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10016",
  },
  {
    name: "EVEN Hotel Midtown East",
    city: "New York",
    phone: "(212) 239-0002",
    profileId: "163",
    state: "NY",
    street: "221 East 44th Street, Between 2nd & 3rd",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10017",
  },
  {
    name: "EVEN Hotel Times Square South",
    city: "New York",
    phone: "(646) 928-3819",
    profileId: "164",
    state: "NY",
    street: "321 West 35th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10001",
  },
  {
    name: "Executive Hotel Le Soleil",
    city: "New York",
    phone: "(212) 695-0003",
    profileId: "165",
    state: "NY",
    street: "38 West 36th Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10018",
  },
  {
    name: "Fairfield Inn & Suites New York Queensboro Bridge",
    city: "Long Island City",
    phone: "(718) 482-0100",
    profileId: "2541",
    state: "NY",
    street: "29-27 40th Road",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "11101",
  },
  {
    name: "Fifty NYC - An Affinia Hotel",
    city: "New York",
    phone: "(212) 751-5710",
    profileId: "166",
    state: "NY",
    street: "155 East 50th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10022",
  },
  {
    name: "Fitzpatrick Grand Central Hotel",
    city: "New York",
    phone: "(212) 351-6800",
    profileId: "167",
    state: "NY",
    street: "141 East 44th Street",
    suspended: false,
    type: "Hotel - A Div",
    zip: "10017",
  },
  {
    name: "Fitzpatrick Manhattan Hotel",
    city: "New York",
    phone: "(212) 355-0100",
    profileId: "168",
    state: "NY",
    street: "687 Lexington Avenue",
    suspended: false,
    type: "Hotel - A Div",
    zip: "10022",
  },
  {
    name: "Four Seasons Hotel New York",
    city: "New York",
    phone: "(212) 758-5700",
    profileId: "169",
    state: "NY",
    street: "57 East 57th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10022",
  },
  {
    name: "Four Seasons Hotel New York Downtown",
    city: "New York",
    phone: "(646) 880-1999",
    profileId: "170",
    state: "NY",
    street: "27 Barclay Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10007",
  },
  {
    name: "Frederick Hotel, a member of Triumph Hotels",
    city: "New York",
    phone: "(212) 566-1900",
    profileId: "171",
    state: "NY",
    street: "95 West Broadway",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10007",
  },
  {
    name: "Gallivant Times Square Hotel",
    city: "New York",
    phone: "(212) 246-8800",
    profileId: "302",
    state: "NY",
    street: "234 West 48th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10036",
  },
  {
    name: "Garden Inn & Suites",
    city: "Jamaica",
    phone: "(718) 581-5380",
    profileId: "173",
    state: "NY",
    street: "151-20 Baisley Blvd.",
    suspended: false,
    type: "Hotel - Labor",
    zip: "11434",
  },
  {
    name: "Gardens NYC - An Affinia Hotel",
    city: "New York",
    phone: "(646) 733-2000",
    profileId: "174",
    state: "NY",
    street: "215 East 64th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10021",
  },
  {
    name: "Gate Hotel JFK Airport",
    city: "Jamaica",
    phone: "(718) 322-7190",
    profileId: "175",
    state: "NY",
    street: "132-26 South Conduit Avenue",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "11430",
  },
  {
    name: "Gatsby Hotel",
    city: "New York",
    phone: "(212) 358-8844",
    profileId: "176",
    state: "NY",
    street: "135 East Houston Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10002",
  },
  {
    name: "Gideon Putnam",
    city: "Saratoga Springs",
    phone: "(518) 226-4720",
    profileId: "177",
    state: "NY",
    street: "24 Gideon Putnam Road",
    suspended: false,
    type: "Hotel - GRIWA",
    zip: "12866",
  },
  {
    name: "Gild Hall - A Thompson Hotel",
    city: "New York",
    phone: "(212) 232-7700",
    profileId: "178",
    state: "NY",
    street: "15 Gold Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10038",
  },
  {
    name: "Gotham Hotel",
    city: "New York",
    phone: "(646) 396-2708",
    profileId: "303",
    state: "NY",
    street: "16 East 46th Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10017",
  },
  {
    name: "Graduate Roosevelt Island",
    city: "New York",
    phone: "(929) 447-4700",
    profileId: "2573",
    state: "NY",
    street: "22 N Loop Road",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10044",
  },
  {
    name: "Gramercy Park Hotel",
    city: "New York",
    phone: "(212) 673-2198",
    profileId: "179",
    state: "NY",
    street: "2 Lexington Avenue",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10010",
  },
  {
    name: "Hampshire House",
    city: "New York",
    phone: "(212) 333-6000",
    profileId: "181",
    state: "NY",
    street: "150 Central Park South",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10019",
  },
  {
    name: "Hampton Inn - Madison Square Garden",
    city: "New York",
    phone: "(212) 947-9700",
    profileId: "182",
    state: "NY",
    street: "116 West 31st Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10001",
  },
  {
    name: "Hampton Inn - Manhattan Downtown Financial District",
    city: "New York",
    phone: "(212) 480-3500",
    profileId: "2587",
    state: "NY",
    street: "32 Pearl Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10004",
  },
  {
    name: "Hampton Inn - Manhattan/Seaport/Financial District",
    city: "New York",
    phone: "(212) 571-4400",
    profileId: "183",
    state: "NY",
    street: "320 Pearl Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10038",
  },
  {
    name: "Hampton Inn & Suites Mahwah",
    city: "Mahwah",
    phone: "(201) 828-2031",
    profileId: "4042",
    state: "NJ",
    street: "290 Route 17 Corporate Dr.",
    suspended: false,
    type: "Hotel - GRIWA",
    zip: "07430",
  },
  {
    name: "Hampton Inn Chelsea",
    city: "New York",
    phone: "(212) 414-1000",
    profileId: "184",
    state: "NY",
    street: "108 West 24th Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10011",
  },
  {
    name: "Hampton Inn Times Square North",
    city: "New York",
    phone: "(212) 581-4100",
    profileId: "185",
    state: "NY",
    street: "851 Eighth Avenue",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10019",
  },
  {
    name: "Hampton Inn Times Square South",
    city: "New York",
    phone: "(212) 581-4100",
    profileId: "186",
    state: "NY",
    street: "337 West 39th Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10018",
  },
  {
    name: "Hard Rock Hotel New York",
    city: "New York",
    phone: "(855) 695-6672",
    profileId: "15561",
    state: "NY",
    street: "159 West 48th Street",
    suspended: false,
    type: "Hotel - Union",
    zip: "10036",
  },
  {
    name: "Harvard Club of New York City",
    city: "New York",
    phone: "(212) 840-6600",
    profileId: "187",
    state: "NY",
    street: "35 West 44th Street",
    suspended: false,
    type: "Hotel - Union",
    zip: "10036",
  },
  {
    name: "Henn na Hotel NY",
    city: "New York",
    phone: "(212) 729-4366",
    profileId: "10290",
    state: "NY",
    street: "235 W 35th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10001",
  },
  {
    name: "High Line Hotel",
    city: "New York",
    phone: "(212) 929-3888",
    profileId: "305",
    state: "NY",
    street: "180 Tenth Avenue",
    suspended: false,
    type: "Hotel - A Div",
    zip: "10011",
  },
  {
    name: "Hilton East Brunswick Hotel and Conference Center",
    city: "East Brunswick",
    phone: "(732) 828-2000",
    profileId: "7095",
    state: "NJ",
    street: "3 Tower Center Blvd.",
    suspended: false,
    type: "Hotel - GRIWA",
    zip: "08816",
  },
  {
    name: "Hilton Garden Inn - Ridgefield Park",
    city: "Ridgefield Park",
    phone: "(201) 641-2024",
    profileId: "192",
    state: "NJ",
    street: "70 Challenger Road",
    suspended: false,
    type: "Hotel - GRIWA",
    zip: "07660",
  },
  {
    name: "Hilton Garden Inn - Tribeca",
    city: "New York",
    phone: "(212) 966-4091",
    profileId: "188",
    state: "NY",
    street: "39 Avenue of the Americas",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10013",
  },
  {
    name: "Hilton Garden Inn New York / Times Square Central",
    city: "New York",
    phone: "(212) 204-7243",
    profileId: "190",
    state: "NY",
    street: "136 West 42nd Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10036",
  },
  {
    name: "Hilton Garden Inn Queens / JFK Airport",
    city: "Jamaica",
    phone: "(718) 322-4448",
    profileId: "191",
    state: "NY",
    street: "148-18 134th Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "11430",
  },
  {
    name: "Hilton Garden Inn Staten Island",
    city: "Staten Island",
    phone: "(718) 477-2400",
    profileId: "193",
    state: "NY",
    street: "1100 South Avenue",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10314",
  },
  {
    name: "Hilton Garden Inn Times Square",
    city: "New York",
    phone: "(646) 710-5724",
    profileId: "194",
    state: "NY",
    street: "790 8th Avenue",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10019",
  },
  {
    name: "Hilton Garden Inn West 35th Street",
    city: "New York",
    phone: "(212) 609-1030",
    profileId: "195",
    state: "NY",
    street: "63 West 35th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10001",
  },
  {
    name: "Hilton New York Fashion District",
    city: "New York",
    phone: "(212) 858-5888",
    profileId: "196",
    state: "NY",
    street: "152 West 26th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10001",
  },
  {
    name: "Holiday Inn Express - Wall Street",
    city: "New York",
    phone: "(212) 747-9222",
    profileId: "198",
    state: "NY",
    street: "126 Water Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10005",
  },
  {
    name: "Holiday Inn Express Brooklyn",
    city: "Brooklyn",
    phone: "(347) 750-2102",
    profileId: "199",
    state: "NY",
    street: "625 Union Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "11215",
  },
  {
    name: "Holiday Inn Express NYC Chelsea",
    city: "New York",
    phone: "(212) 695-7200",
    profileId: "200",
    state: "NY",
    street: "232 West 29th Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10001",
  },
  {
    name: "Holiday Inn Express Times Square",
    city: "New York",
    phone: "(212) 239-2222",
    profileId: "201",
    state: "NY",
    street: "343 West 39th Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10018",
  },
  {
    name: "Holiday Inn Wall Street",
    city: "New York",
    phone: "(212) 227-3007",
    profileId: "203",
    state: "NY",
    street: "51 Nassau Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10038",
  },
  {
    name: "Hostelling International - New York",
    city: "New York",
    phone: "(212) 932-2300",
    profileId: "204",
    state: "NY",
    street: "891 Amsterdam Avenue",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10025",
  },
  {
    name: "Hotel @ Fifth Avenue",
    city: "New York",
    phone: "(212) 736-1600",
    profileId: "306",
    state: "NY",
    street: "17 West 32nd Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10001",
  },
  {
    name: "Hotel @ New York City",
    city: "New York",
    phone: "(212) 545-1800",
    profileId: "307",
    state: "NY",
    street: "161 Lexington Avenue",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10016",
  },
  {
    name: "Hotel @ Times Square",
    city: "New York",
    phone: "(212) 719-2300",
    profileId: "308",
    state: "NY",
    street: "59 West 46th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10036",
  },
  {
    name: "Hotel 46 Times Square",
    city: "New York",
    phone: "(212) 221-2600",
    profileId: "128",
    state: "NY",
    street: "129 West 46th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10036",
  },
  {
    name: "Hotel 50 Bowery NYC",
    city: "New York",
    phone: "(646) 568-2630",
    profileId: "206",
    state: "NY",
    street: "50 Bowery",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10013",
  },
  {
    name: "Hotel 57",
    city: "New York",
    phone: "(212) 651-3236",
    profileId: "269",
    state: "NY",
    street: "130 East 57th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10022",
  },
  {
    name: "Hotel Barriere Le Fouquet's New York",
    city: "New York",
    phone: "",
    profileId: "10285",
    state: "NY",
    street: "456 Greenwich Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10013",
  },
  {
    name: "Hotel Beacon NYC",
    city: "New York",
    phone: "(212) 787-1100",
    profileId: "207",
    state: "NY",
    street: "2130 Broadway at 75th Street",
    suspended: false,
    type: "Hotel - Union",
    zip: "10023",
  },
  {
    name: "Hotel Indigo Lower East Side New York",
    city: "New York",
    phone: "(212) 237-1776",
    profileId: "208",
    state: "NY",
    street: "171 Ludlow Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10002",
  },
  {
    name: "Hotel Mela",
    city: "New York",
    phone: "(212) 710-7000",
    profileId: "209",
    state: "NY",
    street: "120 West 44th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10036",
  },
  {
    name: "Hotel Plaza Athenee, New York",
    city: "New York",
    phone: "(212) 734-9100",
    profileId: "211",
    state: "NY",
    street: "37 East 64th Street at Madison Avenue",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10065",
  },
  {
    name: "Hotel Scherman",
    city: "New York",
    phone: "(212) 359-6652",
    profileId: "301",
    state: "NY",
    street: "346 West 46th Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10036",
  },
  {
    name: "Hyatt Centric Times Square New York",
    city: "New York",
    phone: "(646) 364-1234",
    profileId: "215",
    state: "NY",
    street: "135 West 45th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10036",
  },
  {
    name: "Hyatt Centric Wall Street",
    city: "New York",
    phone: "(212) 590-1234",
    profileId: "114",
    state: "NY",
    street: "75 Wall Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10005",
  },
  {
    name: "Hyatt Grand Central New York",
    city: "New York",
    phone: "(646) 213-6694",
    profileId: "180",
    state: "NY",
    street: "109 East 42nd Street at Grand Central",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10017",
  },
  {
    name: "Hyatt Regency Jersey City",
    city: "Jersey City",
    phone: "(201) 469-1234",
    profileId: "217",
    state: "NJ",
    street: "2 Exchange Place",
    suspended: false,
    type: "Hotel - GRIWA",
    zip: "07302",
  },
  {
    name: "Hyatt Regency Morristown",
    city: "Morristown",
    phone: "(973) 889-2017",
    profileId: "216",
    state: "NJ",
    street: "3 Speedwell Avenue",
    suspended: false,
    type: "Hotel - GRIWA",
    zip: "07960",
  },
  {
    name: "Hyatt Regency New Brunswick",
    city: "New Brunswick",
    phone: "(732) 873-1234",
    profileId: "218",
    state: "NJ",
    street: "2 Albany Street",
    suspended: false,
    type: "Hotel - GRIWA",
    zip: "08901",
  },
  {
    name: "Hyatt Union Square New York",
    city: "New York",
    phone: "(212) 253-1234",
    profileId: "219",
    state: "NY",
    street: "134 4th Avenue",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10003",
  },
  {
    name: "Iberostar 70 Park Avenue",
    city: "New York",
    phone: "(212) 973-2400",
    profileId: "220",
    state: "NY",
    street: "70 Park Avenue",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10016",
  },
  {
    name: "Ink 48 Hotel",
    city: "New York",
    phone: "(212) 757-0088",
    profileId: "228",
    state: "NY",
    street: "653 11th Avenue",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10036",
  },
  {
    name: "InterContinental New York Barclay",
    city: "New York",
    phone: "(212) 906-3133",
    profileId: "221",
    state: "NY",
    street: "111 East 48th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10017",
  },
  {
    name: "InterContinental New York Times Square",
    city: "New York",
    phone: "(212) 906-3133",
    profileId: "222",
    state: "NY",
    street: "300 West 44th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10036",
  },
  {
    name: "Iroquois Hotel, a member of Triumph Hotels",
    city: "New York",
    phone: "(212) 840-3080",
    profileId: "223",
    state: "NY",
    street: "49 West 44th Street",
    suspended: false,
    type: "Hotel - Union",
    zip: "10036",
  },
  {
    name: "James New York - NoMad",
    city: "New York",
    phone: "(646) 472-3601",
    profileId: "309",
    state: "NY",
    street: "22 East 29th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10016",
  },
  {
    name: "JW Marriott Essex House New York",
    city: "New York",
    phone: "(212) 247-0300",
    profileId: "225",
    state: "NY",
    street: "160 Central Park So.",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10019",
  },
  {
    name: "Kew Motor Inn",
    city: "Kew Gardens Hills",
    phone: "(718) 969-3000",
    profileId: "226",
    state: "NY",
    street: "139-01 Grand Central Parkway",
    suspended: false,
    type: "Hotel - Labor",
    zip: "11435",
  },
  {
    name: "Kimpton Hotel Eventi",
    city: "New York",
    phone: "(646) 794-6861",
    profileId: "227",
    state: "NY",
    street: "851 Avenue of Americas",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10001",
  },
  {
    name: "Kimpton Muse Hotel",
    city: "New York",
    phone: "(212) 485-2400",
    profileId: "229",
    state: "NY",
    street: "130 West 46th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10036",
  },
  {
    name: "Kitano New York",
    city: "New York",
    phone: "(212) 885-7000",
    profileId: "312",
    state: "NY",
    street: "66 Park Avenue",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10016",
  },
  {
    name: "Langham, New York, Fifth Avenue",
    city: "New York",
    phone: "(212) 613-8651",
    profileId: "314",
    state: "NY",
    street: "400 Fifth Avenue",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10018",
  },
  {
    name: "Le Meridien New York Central Park",
    city: "New York",
    phone: "(212) 830-8001",
    profileId: "230",
    state: "NY",
    street: "120 West 57th Street",
    suspended: false,
    type: "Hotel - A Div",
    zip: "10019",
  },
  {
    name: "Lexington Hotel",
    city: "New York",
    phone: "(212) 755-4400",
    profileId: "315",
    state: "NY",
    street: "511 Lexington Avenue",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10017",
  },
  {
    name: "Loews Regency New York",
    city: "New York",
    phone: "(212) 339-4002",
    profileId: "231",
    state: "NY",
    street: "540 Park Avenue",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10065",
  },
  {
    name: "Lombardy",
    city: "New York",
    phone: "(212) 753-8600",
    profileId: "316",
    state: "NY",
    street: "111 East 56th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10022",
  },
  {
    name: "Long Island Marriott",
    city: "Uniondale",
    phone: "(516) 229-5020",
    profileId: "232",
    state: "NY",
    street: "101 James Doolittle Blvd.",
    suspended: false,
    type: "Hotel - GRIWA",
    zip: "11553",
  },
  {
    name: "Lotte New York Palace",
    city: "New York",
    phone: "(212) 303-6044",
    profileId: "233",
    state: "NY",
    street: "455 Madison Avenue",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10022",
  },
  {
    name: "Lowell",
    city: "New York",
    phone: "(212) 605-6804",
    profileId: "317",
    state: "NY",
    street: "28 East 63rd Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10065",
  },
  {
    name: "M Social Hotel Times Square",
    city: "New York",
    phone: "(212) 315-0100",
    profileId: "255",
    state: "NY",
    street: "226 West 52nd Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10019",
  },
  {
    name: "Mandarin Oriental, New York",
    city: "New York",
    phone: "(212) 805-8803",
    profileId: "235",
    state: "NY",
    street: "80 Columbus Circle at 60th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10023",
  },
  {
    name: "Manhattan at Times Square Hotel",
    city: "New York",
    phone: "(212) 621-8570",
    profileId: "318",
    state: "NY",
    street: "790 Seventh Avenue",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10019",
  },
  {
    name: "Manhattan Club",
    city: "New York",
    phone: "(212) 453-8285",
    profileId: "319",
    state: "NY",
    street: "200 West 56th Street",
    suspended: false,
    type: "Hotel - Union",
    zip: "10019",
  },
  {
    name: "Marcel at Gramercy Hotel",
    city: "New York",
    phone: "(212) 292-5550",
    profileId: "320",
    state: "NY",
    street: "201 East 24th Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10010",
  },
  {
    name: "Mark",
    city: "New York",
    phone: "(212) 606-4531",
    profileId: "321",
    state: "NY",
    street: "25 East 77th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10075",
  },
  {
    name: "Marmara Manhattan",
    city: "New York",
    phone: "(212) 427-3100",
    profileId: "322",
    state: "NY",
    street: "301 East 94th Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10128",
  },
  {
    name: "Marriott Vacation Club Pulse, New York City",
    city: "New York",
    phone: "(646) 368-6427",
    profileId: "236",
    state: "NY",
    street: "33 West 37th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10018",
  },
  {
    name: "Martinique New York on Broadway, Curio Collection by Hilton",
    city: "New York",
    phone: "(212) 277-2670",
    profileId: "237",
    state: "NY",
    street: "49 West 32nd Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10001",
  },
  {
    name: "Metropolitan Club",
    city: "New York",
    phone: "(212) 838-7400",
    profileId: "238",
    state: "NY",
    street: "1 East 60th Street",
    suspended: false,
    type: "Hotel - Union",
    zip: "10022",
  },
  {
    name: "Michelangelo New York",
    city: "New York",
    phone: "(212) 765-1900",
    profileId: "325",
    state: "NY",
    street: "152 West 51st Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10019",
  },
  {
    name: "Millennium Broadway Times Square",
    city: "New York",
    phone: "(212) 768-4400",
    profileId: "242",
    state: "NY",
    street: "145 West 44th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10036",
  },
  {
    name: "Millennium Downtown New York",
    city: "New York",
    phone: "(212) 693-2001",
    profileId: "240",
    state: "NY",
    street: "55 Church Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10007",
  },
  {
    name: "Millennium Hilton New York One UN Plaza",
    city: "New York",
    phone: "(212) 702-5008",
    profileId: "241",
    state: "NY",
    street: "One United Nations Plaza",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10017",
  },
  {
    name: "Minthouse at 70 Pine",
    city: "New York",
    phone: "(855) 972-9090",
    profileId: "234",
    state: "NY",
    street: "70 Pine Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10005",
  },
  {
    name: "ModernHaus SoHo",
    city: "New York",
    phone: "(212) 465-2000",
    profileId: "310",
    state: "NY",
    street: "27 Grand Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10013",
  },
  {
    name: "Motto by Hilton NYC Chelsea",
    city: "New York",
    phone: "(212) 668-8624",
    profileId: "14433",
    state: "NY",
    street: "113 West 24th Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10001",
  },
  {
    name: "Moxy Chelsea",
    city: "New York",
    phone: "(212) 514-6699",
    profileId: "243",
    state: "NY",
    street: "105 West 28th Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10001",
  },
  {
    name: "Moxy East Village",
    city: "New York",
    phone: "(212) 288-6699",
    profileId: "2309",
    state: "NY",
    street: "112 East 11th Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10003",
  },
  {
    name: "Moxy Times Square",
    city: "New York",
    phone: "(212) 967-6699",
    profileId: "244",
    state: "NY",
    street: "485 7th Avenue",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10018",
  },
  {
    name: "New York EDITION",
    city: "New York",
    phone: "(212) 413-4175",
    profileId: "326",
    state: "NY",
    street: "5 Madison Avenue",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10010",
  },
  {
    name: "New York Hilton Midtown",
    city: "New York",
    phone: "(212) 586-7000",
    profileId: "245",
    state: "NY",
    street: "1335 Avenue of the Americas",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10019",
  },
  {
    name: "New York Marriott at the Brooklyn Bridge",
    city: "Brooklyn",
    phone: "(718) 222-6509",
    profileId: "247",
    state: "NY",
    street: "333 Adam Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "11201",
  },
  {
    name: "New York Marriott Downtown",
    city: "New York",
    phone: "(212) 266-6137",
    profileId: "248",
    state: "NY",
    street: "85 West Street at Albany Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10006",
  },
  {
    name: "New York Marriott Marquis",
    city: "New York",
    phone: "(212) 704-8741",
    profileId: "250",
    state: "NY",
    street: "1535 Broadway",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10036",
  },
  {
    name: "New York Presbyterian Guest Facility",
    city: "New York",
    phone: "(212) 472-8400",
    profileId: "251",
    state: "NY",
    street: "411 East 71st Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10021",
  },
  {
    name: "New Yorker by Wyndham",
    city: "New York",
    phone: "(212) 244-0719",
    profileId: "327",
    state: "NY",
    street: "481 Eighth Avenue",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10001",
  },
  {
    name: "NH Collection New York Madison Avenue",
    city: "New York",
    phone: "(212) 802-0600",
    profileId: "252",
    state: "NY",
    street: "22 East 38th Street",
    suspended: false,
    type: "Hotel - Special",
    zip: "10016",
  },
  {
    name: "Nolitan",
    city: "New York",
    phone: "(212) 925-2555",
    profileId: "328",
    state: "NY",
    street: "30 Kenmare Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10012",
  },
  {
    name: "NoMo SoHo",
    city: "New York",
    phone: "(646) 218-6400",
    profileId: "254",
    state: "NY",
    street: "9 Crosby Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10013",
  },
  {
    name: "NU Hotel Brooklyn",
    city: "Brooklyn",
    phone: "(718) 852-8585",
    profileId: "256",
    state: "NY",
    street: "85 Smith Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "11201",
  },
  {
    name: "NYMA, The New York Manhattan Hotel",
    city: "New York",
    phone: "(212) 643-7100",
    profileId: "257",
    state: "NY",
    street: "6 West 32nd Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10001",
  },
  {
    name: "Omni Berkshire Place",
    city: "New York",
    phone: "(212) 753-5800",
    profileId: "258",
    state: "NY",
    street: "21 East 52nd Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10022",
  },
  {
    name: "Opus Westchester",
    city: "White Plains",
    phone: "(914) 946-5500",
    profileId: "7099",
    state: "NY",
    street: "3 Renaissance Square",
    suspended: false,
    type: "Hotel - GRIWA",
    zip: "10601",
  },
  {
    name: "OYO Times Square",
    city: "New York",
    phone: "(212) 768-3700",
    profileId: "253",
    state: "NY",
    street: "157 West 47th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10036",
  },
  {
    name: "PARAMOUNT",
    city: "New York",
    phone: "(212) 827-4297",
    profileId: "259",
    state: "NY",
    street: "235 West 46th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10036",
  },
  {
    name: "Park Central Hotel New York",
    city: "New York",
    phone: "(212) 707-5028",
    profileId: "260",
    state: "NY",
    street: "870 Seventh Avenue",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10019",
  },
  {
    name: "Park Hyatt New York",
    city: "New York",
    phone: "(646) 774-1325",
    profileId: "261",
    state: "NY",
    street: "153 West 57th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10019",
  },
  {
    name: "Park Lane Hotel New York",
    city: "New York",
    phone: "(212) 521-6674",
    profileId: "262",
    state: "NY",
    street: "36 Central Park South",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10019",
  },
  {
    name: "Park South Hotel",
    city: "New York",
    phone: "(212) 448-0888",
    profileId: "263",
    state: "NY",
    street: "124 East 28th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10016",
  },
  {
    name: "Pendry Manhattan West",
    city: "New York",
    phone: "(212) 933-7000",
    profileId: "12345",
    state: "NY",
    street: "438 West 33rd Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10001",
  },
  {
    name: "Peninsula New York",
    city: "New York",
    phone: "(212) 956-2888",
    profileId: "329",
    state: "NY",
    street: "700 Fifth Avenue",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10019",
  },
  {
    name: "Pierre, A Taj Hotel, New York",
    city: "New York",
    phone: "(212) 940-8189",
    profileId: "330",
    state: "NY",
    street: "2 East 61st Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10065",
  },
  {
    name: "Plaza Hotel",
    city: "New York",
    phone: "(212) 546-5201",
    profileId: "331",
    state: "NY",
    street: "768 Fifth Avenue",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10019",
  },
  {
    name: "POD Hotel 51",
    city: "New York",
    phone: "(212) 355-0300",
    profileId: "265",
    state: "NY",
    street: "230 East 51st Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10022",
  },
  {
    name: "Pod Times Square",
    city: "New York",
    phone: "(212) 273-9222",
    profileId: "266",
    state: "NY",
    street: "400 West 42nd Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10036",
  },
  {
    name: "Princeton Club of New York",
    city: "New York",
    phone: "(212) 596-1200",
    profileId: "267",
    state: "NY",
    street: "15 West 43rd Street",
    suspended: false,
    type: "Hotel - Union",
    zip: "10036",
  },
  {
    name: "Quin Central Park by Hilton Club",
    city: "New York",
    phone: "(646) 589-6550",
    profileId: "332",
    state: "NY",
    street: "101 West 57th Street at 6th Avenue",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10019",
  },
  {
    name: "Radisson Hotel JFK Airport",
    city: "Jamaica",
    phone: "(718) 322-2300",
    profileId: "268",
    state: "NY",
    street: "135-30 140th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "11436",
  },
  {
    name: "Redbury New York",
    city: "New York",
    phone: "(212) 651-3822",
    profileId: "333",
    state: "NY",
    street: "29 East 29th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10016",
  },
  {
    name: "Renaissance New York Times Square Hotel",
    city: "New York",
    phone: "(212) 765-7676",
    profileId: "270",
    state: "NY",
    street: "714 7th Avenue",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10036",
  },
  {
    name: "Renaissance Newark Airport Hotel",
    city: "Elizabeth",
    phone: "(908) 436-4600",
    profileId: "271",
    state: "NJ",
    street: "1000 Spring Street",
    suspended: false,
    type: "Hotel - GRIWA",
    zip: "07201",
  },
  {
    name: "Residence Inn by Marriott Times Square",
    city: "New York",
    phone: "(212) 380-5029",
    profileId: "272",
    state: "NY",
    street: "1033 Avenue of the Americas",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10018",
  },
  {
    name: "Residence Inn by Marriott World Trade Center",
    city: "New York",
    phone: "(212) 600-8941",
    profileId: "273",
    state: "NY",
    street: "170 Broadway",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10007",
  },
  {
    name: "Residence Inn Newark Elizabeth",
    city: "Elizabeth",
    phone: "(908) 352-4300",
    profileId: "274",
    state: "NJ",
    street: "83 International Blvd",
    suspended: false,
    type: "Hotel - GRIWA",
    zip: "07201",
  },
  {
    name: "Ritz-Carlton New York, Central Park",
    city: "New York",
    phone: "(212) 308-9100",
    profileId: "335",
    state: "NY",
    street: "50 Central Park South",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10019",
  },
  {
    name: "Ritz-Carlton New York, NoMad",
    city: "New York",
    phone: "(212) 404-8400",
    profileId: "13388",
    state: "NY",
    street: "25 West 28th Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10001",
  },
  {
    name: "Ritz-Carlton Residences, Westchester",
    city: "White Plains",
    phone: "(914) 467-5775",
    profileId: "4028",
    state: "NY",
    street: "1 & 5 Renaissance Square",
    suspended: false,
    type: "Hotel - GRIWA",
    zip: "10601",
  },
  {
    name: "Rockaway Hotel",
    city: "Rockaway Park",
    phone: "(718) 874-1216",
    profileId: "2324",
    state: "NY",
    street: "108-10 Rockaway Beach Drive",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "11694",
  },
  {
    name: "Roger Smith Hotel",
    city: "New York",
    phone: "(212) 755-1400",
    profileId: "275",
    state: "NY",
    street: "501 Lexington Avenue",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10017",
  },
  {
    name: "Ronald McDonald House New York",
    city: "New York",
    phone: "(212) 639-0400",
    profileId: "276",
    state: "NY",
    street: "405 East 73rd Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10021",
  },
  {
    name: "Royalton Hotel",
    city: "New York",
    phone: "(212) 869-4400",
    profileId: "278",
    state: "NY",
    street: "44 West 44th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10036",
  },
  {
    name: "Royalton Park Avenue",
    city: "New York",
    phone: "(212) 317-2900",
    profileId: "279",
    state: "NY",
    street: "420 Park Ave S",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10016",
  },
  {
    name: "San Carlos Hotel",
    city: "New York",
    phone: "(212) 755-1800",
    profileId: "281",
    state: "NY",
    street: "150 East 50th Street",
    suspended: false,
    type: "Hotel - A Div",
    zip: "10022",
  },
  {
    name: "Seafarers & International House",
    city: "New York",
    phone: "(212) 677-4800",
    profileId: "282",
    state: "NY",
    street: "123 East 15th Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10003",
  },
  {
    name: "Shelburne NYC - An Affinia Hotel",
    city: "New York",
    phone: "(646) 424-2771",
    profileId: "283",
    state: "NY",
    street: "303 Lexington Avenue",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10016",
  },
  {
    name: "Sheraton Edison",
    city: "Edison",
    phone: "(732) 225-8300",
    profileId: "7091",
    state: "NJ",
    street: "125 Raritan Center Parkway",
    suspended: false,
    type: "Hotel - GRIWA",
    zip: "08837",
  },
  {
    name: "Sheraton LaGuardia East Hotel",
    city: "Flushing",
    phone: "(718) 460-6666",
    profileId: "284",
    state: "NY",
    street: "135-20 39th Avenue",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "11354",
  },
  {
    name: "Sheraton Mahwah Hotel",
    city: "Mahwah",
    phone: "(201) 529-1660",
    profileId: "285",
    state: "NJ",
    street: "1 International Blvd.",
    suspended: false,
    type: "Hotel - GRIWA",
    zip: "07495",
  },
  {
    name: "Sheraton New York Times Square Hotel",
    city: "New York",
    phone: "(212) 841-6706",
    profileId: "286",
    state: "NY",
    street: "811 Seventh Avenue",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10019",
  },
  {
    name: "Sheraton Tribeca New York Hotel",
    city: "New York",
    phone: "(212) 966-3400",
    profileId: "287",
    state: "NY",
    street: "370 Canal Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10013",
  },
  {
    name: "Sherry-Netherland",
    city: "New York",
    phone: "(212) 231-6801",
    profileId: "339",
    state: "NY",
    street: "781 Fifth Avenue",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10022",
  },
  {
    name: "Shoreham Hotel",
    city: "New York",
    phone: "(917) 639-1435",
    profileId: "340",
    state: "NY",
    street: "33 West 55th Street",
    suspended: false,
    type: "Hotel - Union",
    zip: "10019",
  },
  {
    name: "Sister City Bowery",
    city: "New York",
    phone: "(646) 343-4000",
    profileId: "288",
    state: "NY",
    street: "225 Bowery",
    suspended: false,
    type: "Hotel - Union",
    zip: "10002",
  },
  {
    name: "SKYLINE HOTEL",
    city: "New York",
    phone: "(212) 586-3400",
    profileId: "289",
    state: "NY",
    street: "725 Tenth Avenue",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10019",
  },
  {
    name: "Smyth Tribeca",
    city: "New York",
    phone: "(212) 997-2700",
    profileId: "109",
    state: "NY",
    street: "85 West Broadway",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10007",
  },
  {
    name: "Sofitel New York",
    city: "New York",
    phone: "(212) 354-8844",
    profileId: "290",
    state: "NY",
    street: "45 West 44th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10036",
  },
  {
    name: "Sonesta Simply Suites Jersey City",
    city: "Jersey City",
    phone: "(201) 659-2500",
    profileId: "124",
    state: "NJ",
    street: "21 2nd Street",
    suspended: false,
    type: "Hotel - GRIWA",
    zip: "07302",
  },
  {
    name: "Sonesta Simply Suites Nanuet",
    city: "Nanuet",
    phone: "(845) 371-4445",
    profileId: "125",
    state: "NY",
    street: "20 Overlook Blvd.",
    suspended: false,
    type: "Hotel - GRIWA",
    zip: "10954",
  },
  {
    name: "Sonesta Simply Suites Parsippany",
    city: "Morris Plains",
    phone: "(973) 984-9960",
    profileId: "127",
    state: "NJ",
    street: "100 Candlewood Suites Drive",
    suspended: false,
    type: "Hotel - GRIWA",
    zip: "07950",
  },
  {
    name: "SpringHill Suites by Marriott New York Midtown Manhattan/Fifth Avenue",
    city: "New York",
    phone: "(212) 391-2255",
    profileId: "291",
    state: "NY",
    street: "25 West 37th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10018",
  },
  {
    name: "St. Regis New York",
    city: "New York",
    phone: "(212) 339-6703",
    profileId: "341",
    state: "NY",
    street: "2 East 55th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10022",
  },
  {
    name: "Staypineapple Midtown New York, An Artful Hotel",
    city: "New York",
    phone: "(212) 500-7123",
    profileId: "292",
    state: "NY",
    street: "337 West 36th Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10018",
  },
  {
    name: "Thompson Central Park New York",
    city: "New York",
    phone: "(212) 245-5000",
    profileId: "264",
    state: "NY",
    street: "119 West 56th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10019",
  },
  {
    name: "Tillary Hotel Brooklyn",
    city: "Brooklyn",
    phone: "(718) 329-9537",
    profileId: "3949",
    state: "NY",
    street: "85 Flatbush Ave Ext",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "11201",
  },
  {
    name: "Time New York",
    city: "New York",
    phone: "(212) 246-5252",
    profileId: "344",
    state: "NY",
    street: "224 West 49th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10019",
  },
  {
    name: "Times Square EDITION",
    city: "New York",
    phone: "(212) 261-5201",
    profileId: "345",
    state: "NY",
    street: "701 7th Avenue",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10036",
  },
  {
    name: "Towne House/Carlyle Construction Corp.",
    city: "New York",
    phone: "(212) 682-7300",
    profileId: "354",
    state: "NY",
    street: "340 East 46th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10017",
  },
  {
    name: "Trump International Hotel & Tower",
    city: "New York",
    phone: "(212) 299-1038",
    profileId: "355",
    state: "NY",
    street: "One Central Park West",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10023",
  },
  {
    name: "TWA Hotel",
    city: "New York",
    phone: "(212) 806-9000",
    profileId: "356",
    state: "NY",
    street: "One Idlewild Drive, JFK International Airport",
    suspended: false,
    type: "Hotel - Labor",
    zip: "11430",
  },
  {
    name: "Union Club of the City of New York",
    city: "New York",
    phone: "(212) 734-5400",
    profileId: "357",
    state: "NY",
    street: "101 East 69th Street",
    suspended: false,
    type: "Hotel - Union",
    zip: "10021",
  },
  {
    name: "University Club of New York",
    city: "New York",
    phone: "(212) 247-2100",
    profileId: "347",
    state: "NY",
    street: "1 West 54th Street",
    suspended: false,
    type: "Hotel - Union",
    zip: "10019",
  },
  {
    name: "Van Cortlandt Motel",
    city: "Bronx",
    phone: "(718) 549-7272",
    profileId: "2312",
    state: "NY",
    street: "6393 Broadway",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10471",
  },
  {
    name: "voco Times Square South",
    city: "New York",
    phone: "(212) 542-8990",
    profileId: "7108",
    state: "NY",
    street: "343 West 36th Street",
    suspended: false,
    type: "Hotel - Non Union",
    zip: "10018",
  },
  {
    name: "W Hoboken",
    city: "Hoboken",
    phone: "(201) 253-2400",
    profileId: "358",
    state: "NJ",
    street: "225 River Street",
    suspended: false,
    type: "Hotel - GRIWA",
    zip: "07030",
  },
  {
    name: "W New York - Times Square",
    city: "New York",
    phone: "(212) 930-7400",
    profileId: "360",
    state: "NY",
    street: "1567 Broadway",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10036",
  },
  {
    name: "W New York - Union Square",
    city: "New York",
    phone: "(917) 534-5813",
    profileId: "361",
    state: "NY",
    street: "201 Park Avenue South",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10003",
  },
  {
    name: "Waldorf Astoria New York",
    city: "New York",
    phone: "(212) 872-4605",
    profileId: "362",
    state: "NY",
    street: "301 Park Avenue",
    suspended: false,
    type: "Hotel - Special",
    zip: "10022",
  },
  {
    name: "Warwick New York Hotel",
    city: "New York",
    phone: "(212) 314-7833",
    profileId: "364",
    state: "NY",
    street: "65 West 54th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10019",
  },
  {
    name: "Washington - New York City",
    city: "New York",
    phone: "(646) 826-8600",
    profileId: "359",
    state: "NY",
    street: "8 Albany Street",
    suspended: false,
    type: "Hotel - Suspension",
    zip: "10006",
  },
  {
    name: "Washington Jefferson Hotel, a member of Triumph Hotels",
    city: "New York",
    phone: "(212) 453-4021",
    profileId: "365",
    state: "NY",
    street: "318 West 51st Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10019",
  },
  {
    name: "Watson Hotel",
    city: "New York",
    phone: "(212) 634-3973",
    profileId: "349",
    state: "NY",
    street: "440 West 57th Street",
    suspended: false,
    type: "Hotel - Union",
    zip: "10019",
  },
  {
    name: "West 57th Street by Hilton Club",
    city: "New York",
    phone: "(212) 379-0103",
    profileId: "368",
    state: "NY",
    street: "102 West 57th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10019",
  },
  {
    name: "Westchester Marriott",
    city: "Tarrytown",
    phone: "(914) 333-1269",
    profileId: "369",
    state: "NY",
    street: "670 White Plains Road",
    suspended: false,
    type: "Hotel - GRIWA",
    zip: "10591",
  },
  {
    name: "WestHouse Hotel New York",
    city: "New York",
    phone: "(212) 707-4888",
    profileId: "371",
    state: "NY",
    street: "201 West 55th Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10019",
  },
  {
    name: "Westin Jersey City Newport",
    city: "Jersey City",
    phone: "(201) 626-2900",
    profileId: "350",
    state: "NJ",
    street: "479 Washington Blvd.",
    suspended: false,
    type: "Hotel - GRIWA",
    zip: "07310",
  },
  {
    name: "Westin New York at Times Square",
    city: "New York",
    phone: "(212) 201-2742",
    profileId: "351",
    state: "NY",
    street: "270 West 43rd Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10036",
  },
  {
    name: "Westin New York Grand Central",
    city: "New York",
    phone: "(212) 405-4242",
    profileId: "352",
    state: "NY",
    street: "212 East 42nd Street",
    suspended: false,
    type: "Hotel - Labor",
    zip: "10017",
  },
  {
    name: "Yale Club of New York City",
    city: "New York",
    phone: "(212) 716-2100",
    profileId: "353",
    state: "NY",
    street: "50 Vanderbilt Avenue",
    suspended: false,
    type: "Hotel - Union",
    zip: "10017",
  },
];
