import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import { db } from "../auth/firebase";
import {
  MdLocationOn,
  MdBusiness,
  MdSource,
  MdAttachMoney,
} from "react-icons/md";

import {
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import {
  Popconfirm,
  Form,
  Input,
  Button,
  Checkbox,
  Col,
  Row,
  Select,
  InputNumber,
  DatePicker,
  AutoComplete,
  Cascader,
  Space,
  Divider,
  Tag,
  Typography,
  Avatar,
  message,
  List,
} from "antd";
import SaveButton from "./components/saveButton";
import ApplyButton from "./components/applyButton";
const { Title, Text, Paragraph } = Typography;
export default function JobView(props) {
  const navigate = useNavigate();
  const [job, setJob] = useState({});
  const [isOwner, setOwner] = useState(false);
  const params = useParams();
  const jobId = params.id;
  useEffect(() => {
    var docRef = db.collection("jobs").doc(String(jobId));
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          var jobData = doc.data();
          jobData.uid = doc.id;

          setJob(jobData);
          if (doc.data().userEmail == props.user.email) {
            setOwner(true);
          }
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }, []);
  const [ellipsis, setEllipsis] = React.useState(true);

  const onDelete = () => {
    db.collection("jobs")
      .doc(props.match.params.id)
      .delete()
      .then(() => {
        message.success("Job successfully deleted!");
        console.log("Document successfully deleted!");
        navigate("/jobs");
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });
  };

  const benefitsTags = (benefits) => {
    const colors = [
      "magenta",
      "red",
      "volcano",
      "orange",
      "gold",
      "lime",
      "green",
      "cyan",
      "blue",
      "geekblue",
      "purple",
    ];
    if (!benefits) {
      return;
    }
    return benefits.map((item) => {
      const indexOfColor = benefits.indexOf(item) % colors.length;

      const colorValue = colors[indexOfColor];
      return (
        <Tag color={colorValue} key={item}>
          {item}
        </Tag>
      );
    });
  };
  return (
    <div className="job-view-container">
      <div
        style={{
          marginTop: "30px",
          zIndex: "50",
          marginBottom: "20px",
          width: "656px",
          borderRadius: "10%",
        }}
      >
        <Button
          style={{ borderRadius: "10%" }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowLeftOutlined />
          Back
        </Button>
      </div>

      <div
      // style={{
      //   display: "flex",
      //   justifyContent: "space-between",
      // }}
      >
        <Row>
          <Col className="flex flex-grow-1">
            <Title level={3}>{job.name}</Title>
          </Col>
          <Col
            style={{
              padding: "10px 0",
              // textAlign: "center",
            }}
            className="flex flex-grow-1"
          >
            <div className="flex flex-start">
              <SaveButton {...job} />
              <ApplyButton {...job} />
            </div>
          </Col>
        </Row>
      </div>
      <Space size={"middle"} align="start">
        <Text className="description-tab">
          <MdBusiness /> {job.hotel && job.hotel.name}{" "}
        </Text>
        {"   "}
        <Text className="description-tab">
          <MdLocationOn /> {job.hotel && job.hotel.city},{" "}
          {job.hotel && job.hotel.state}
        </Text>
        <Text className="description-tab">
          <MdAttachMoney />

          {job.pay && job.pay.minimum}
          {"~"}
          {job.pay && job.pay.maximum}
        </Text>
        <Text className="description-tab">
          <MdSource />

          {job.type}
        </Text>
      </Space>
      <div style={{ marginTop: "5px" }}>{benefitsTags(job.benefits)}</div>

      <Divider />
      <Title level={4}>Description</Title>
      <Paragraph>
        <div dangerouslySetInnerHTML={{ __html: job.description }} />
      </Paragraph>
      <Divider />
      <Title level={4}>Requirements</Title>
      <Paragraph>
        <div dangerouslySetInnerHTML={{ __html: job.requirement }} />
      </Paragraph>
      <Divider />
      <Title level={4}>Skills</Title>
      {job.skills && job.skills.length > 0 && (
        <List
          size="large"
          dataSource={job.skills}
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />
      )}
      {isOwner ? (
        <div style={{ marginBottom: "10px", textAlign: "center" }}>
          {" "}
          <Button type={"primary"}>
            <Link to={`/jobs/${jobId}/edit`}> Edit</Link>
          </Button>
          <Popconfirm
            title="Are you sure to delete this job?"
            onConfirm={onDelete}
            okText="Yes"
            cancelText="No"
          >
            <Button type={"danger"}>Delete</Button>
          </Popconfirm>
        </div>
      ) : (
        <div style={{ marginBottom: "10px", textAlign: "center" }}></div>
      )}
    </div>
  );
}
