import React from "react";
import { AutoComplete, Layout, Typography } from "antd";
import Footer from "../footer";
import { Link } from "react-router-dom";
const { Content, Header } = Layout;

export default function GuestLayout(appProps) {
  const { Title } = Typography;
  return (
    <Layout>
      <Header
        className="header"
        id="header"
        style={{
          position: "fixed",
          zIndex: 1,
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <div className="header-flex">
          <div className="header-flex-item">
            <Link to="/" className="">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/vuefirebase-c2006.appspot.com/o/hotel-logo%2Fhanyc-logo-large.png?alt=media&token=62c36fa2-c372-412f-b965-34dca5b1a4e1"
                className="logo-header-main"
              />
            </Link>
          </div>

          <div className="header-flex-item">
            <Title level={4}>HANYC Job Board</Title>
          </div>
          <div className="header-flex-item"></div>
        </div>
      </Header>
      <Layout className="app site-layout">
        <Content className="site-layout-content">{appProps.children}</Content>
      </Layout>
      <Footer />
    </Layout>
  );
}
