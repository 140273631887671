import React from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Col,
  Row,
  Select,
  InputNumber,
  DatePicker,
  AutoComplete,
  Cascader,
  Divider,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

export default function education() {
  return (
    <div>
      <h3>Education</h3>
      <Form.List name="educations">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <div key={key}>
                <Form.Item
                  label="School Name"
                  {...restField}
                  name={[name, "schoolName"]}
                  fieldKey={[fieldKey, "schoolName"]}
                  rules={[
                    {
                      required: true,
                      message: "Please input your School Name!",
                    },
                  ]}
                >
                  <Input placeholder="School Name" />
                </Form.Item>

                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col className="gutter-row" xs={24} md={12} sm={24}>
                    <Form.Item
                      label="Degree"
                      {...restField}
                      name={[name, "degree"]}
                      fieldKey={[fieldKey, "degree"]}
                      rules={[
                        {
                          required: true,
                          message: "Please input your school degree!",
                        },
                      ]}
                    >
                      <Input placeholder="Degree" />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" xs={24} md={12} sm={24}>
                    <Form.Item
                      label="Major Name"
                      {...restField}
                      name={[name, "major"]}
                      fieldKey={[fieldKey, "major"]}
                      rules={[
                        {
                          required: true,
                          message: "Please input your Major Name!",
                        },
                      ]}
                    >
                      <Input placeholder="Major Name" />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  label="Address"
                  {...restField}
                  name={[name, "address"]}
                  fieldKey={[fieldKey, "address"]}
                >
                  <Input placeholder="Street" />
                </Form.Item>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col className="gutter-row" xs={24} md={12} sm={24}>
                    <Form.Item
                      label="City"
                      {...restField}
                      name={[name, "city"]}
                      fieldKey={[fieldKey, "city"]}
                    >
                      <Input placeholder="City" />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" xs={24} md={12} sm={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "Country"]}
                      fieldKey={[fieldKey, "Country"]}
                      label="Country"
                    >
                      <Input placeholder="country" />
                    </Form.Item>
                  </Col>
                </Row>

                <Button
                  danger
                  type="dashed"
                  onClick={() => remove(name)}
                  block
                  icon={<MinusCircleOutlined />}
                >
                  Remove field
                </Button>
                <Divider />
              </div>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add field
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );
}
