import React, { useEffect, useState, useContext } from "react";
import {
  Form,
  Input,
  Button,
  Col,
  Row,
  Select,
  Typography,
  message,
} from "antd";
// import hotelList from "../../files/hotelList";
import AvatarFileUpload from "./components/uploadImage";
import { db, storage } from "../auth/firebase";
import Layout from "./layout/layout";
import { UserContext } from "../auth/context";
import {
  ref,
  listAll,
  updateMetadata,
  getStorage,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
export default function HotelUpdating(appProps) {
  let formRef = React.createRef();
  const { Option } = Select;
  const { Title } = Typography;
  const [avatarUrl, setAvatarUrl] = useState("");
  const [imageRef, setImageRef] = useState("");
  const [selectedHotel, selectHotel] = useState({});
  let userContext = useContext(UserContext);
  const hotelList = userContext.hotels;
  // let formRef = React.createRef();

  const onFinish = async (values) => {
    console.log("Success:", values);
    console.log({ selectedHotel });
    const newHotelData = selectedHotel;
    const uid = appProps.user.uid;

    var userRef = db.collection("employers").doc(uid);

    newHotelData.avatarUrl = avatarUrl;
    newHotelData.avatarFileName = imageRef;
    await userRef.set({
      hotel: newHotelData,
    });

    message.info("Hotel info updated successfully!");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(async () => {
    const userEmail = appProps.user.uid;
    const hotelData = await getCurrentUserHotel(userEmail);

    if (!hotelData || !hotelData.hotel) {
      return;
    }

    if (hotelData.hotel.avatarUrl) {
      setAvatarUrl(hotelData.hotel.avatarUrl);
      setImageRef(hotelData.hotel.avatarFileName);
    }
    selectHotel(hotelData.hotel);
    if (formRef.current) {
      formRef.current.setFieldsValue(hotelData.hotel);
    }
    getHotelLogo(hotelData.profileId);
  }, []);

  const getHotelLogo = async (hotelId) => {
    const listRef = ref(storage, `logos/${hotelId}`);
    const list = await listAll(listRef);
    if (list.items.length > 0) {
      var url = await getDownloadURL(list.items[0]);
      var name = list.items[0].name;
      console.log(url, name);
      setAvatarUrl(url);
      setImageRef(name);
    }

    // return url;
  };
  const getCurrentUserHotel = async (userEmail) => {
    var docRef = db.collection("employers").doc(userEmail);
    var userData = await docRef.get();

    return userData.data();
  };
  const hotelsOptions = () => {
    return hotelList.map((item) => {
      return (
        <Option key={item.name} value={item.name}>
          {item.name}
        </Option>
      );
    });
  };
  const onChangeSelect = (value) => {
    const theHotel = hotelList.find((item) => item.name == value);

    selectHotel(theHotel);
    getHotelLogo(theHotel.profileId);
    formRef.current.setFieldsValue(theHotel);
  };

  const updateAvatarURL = (url) => {
    setAvatarUrl(url.imageUrl);
    setImageRef(url.imageFileName);
  };
  return (
    <div>
      <Layout>
        <Form
          key={selectedHotel.name}
          ref={formRef}
          style={{ paddingTop: "2px" }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout={"vertical"}
          initialValues={{
            name: selectedHotel.name,
            street: selectedHotel.street,
            city: selectedHotel.city,
            zip: selectedHotel.zip,
            state: selectedHotel.state,
          }}
        >
          <div className="card" style={{ marginTop: "22px" }}>
            <Title level={4}>Hotel Information</Title>
            <Row>
              <Col flex={4}>
                <Form.Item
                  name={"name"}
                  label="Hotel"
                  rules={[
                    { required: true, message: "Please select your Hotel!" },
                  ]}
                >
                  <Select
                    onChange={onChangeSelect}
                    showSearch
                    style={{ maxWidth: 600 }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {hotelsOptions()}
                  </Select>
                </Form.Item>

                {selectedHotel.name != "0" ? (
                  <></>
                ) : (
                  <Form.Item
                    name={"name"}
                    label="Hotel"
                    rules={[
                      { required: true, message: "Please enter your Hotel!" },
                    ]}
                  >
                    <Input placeholder="Hotel Name" />
                  </Form.Item>
                )}
              </Col>
              <Col flex={1} key={avatarUrl}>
                {" "}
                <p>Logo</p>
                <AvatarFileUpload
                  {...appProps}
                  hotel={selectedHotel}
                  updateAvatarURL={updateAvatarURL}
                  avatarUrl={avatarUrl}
                  avatarFileName={imageRef}
                />
              </Col>
            </Row>

            <Form.Item name={"street"} label="Address">
              <Input placeholder="Street" />
            </Form.Item>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" xs={24} md={8} sm={24}>
                <Form.Item name={"city"} label="City">
                  <Input placeholder="City" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} md={8} sm={24}>
                <Form.Item name={"state"} label="State">
                  <Input placeholder="State" />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} md={8} sm={24}>
                <Form.Item name={"zip"} label="Zip">
                  <Input placeholder="Zip" />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <Form.Item style={{ textAlign: "center" }}>
            <Button htmlType="submit">Cancel</Button>
            <Button type="primary" htmlType="submit">
              Confirm
            </Button>
          </Form.Item>
        </Form>
      </Layout>
    </div>
  );
}
