import React from "react";
import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { storage } from "../../auth/firebase";
import {
  ref,
  uploadBytes,
  updateMetadata,
  getStorage,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M && false;
}

export default class AvatarFileUpload extends React.Component {
  state = {
    loading: false,
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/vuefirebase-c2006.appspot.com/o/hotel-logo%2Fhotel_logo.png?alt=media&token=3eecbe7c-59f0-4129-b37c-a6d8ce9b0354",
    originalFileName: "",
    refUrl: "",
  };
  componentDidMount() {
    if (this.props.avatarUrl) {
      this.setState({
        imageUrl: this.props.avatarUrl,
        originalFileName: this.props.avatarFileName,
      });
    }
  }
  uploadFirebase = async (file) => {
    const uid = this.props.hotel.profileId;

    try {
      const resumeRef = ref(storage, `logos/${uid}/${file.name}`);
      const snapshot = await uploadBytes(resumeRef, file.originFileObj);
      console.log(snapshot);
      const url = await getDownloadURL(resumeRef);
      console.log(url);
      this.setState({ imageUrl: url });

      this.setState({ originalFileName: file.name });
      const newMetadata = {
        cacheControl: "public,max-age=300",
        contentType: file.type,
      };

      await updateMetadata(resumeRef, newMetadata);
      this.props.updateAvatarURL({
        imageUrl: this.state.imageUrl,
        imageFileName: this.state.originalFileName,
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleChange = (info) => {
    console.log(info);

    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    this.uploadFirebase(info.fileList[0]);
    if (info.file.status === "done") {
      // Get this url from response in real world.
      //   this.uploadFirebase(info);
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          loading: false,
        })
      );
    }
  };

  render() {
    const { loading, imageUrl } = this.state;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={this.handleChange}
      >
        {imageUrl ? (
          <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
        ) : (
          uploadButton
        )}
      </Upload>
    );
  }
}
