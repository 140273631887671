import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { apiRoutes, baseUrl } from "./apiRoute";
import { Form, Input, Button, Checkbox, Spin, Alert } from "antd";
import Axios from "axios";
Axios.defaults.withCredentials = true;
function PasswordResetRequest(props) {
  const [email, setEmail] = useState("");
  const [user, setUser] = useState({});
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [isAuthed, setAuth] = useState(false);
  const [err, setErr] = useState(false);
  const [errMsg, setMsg] = useState("");
  const navigate = useNavigate();
  const reset = async (email) => {
    return Axios.post(apiRoutes.passwordResetRequest, {
      email: email,
    })
      .then((resp) => {
        console.log(resp.data);

        return resp.data;
      })
      .catch((err) => {
        console.log(err.response.data);
        throw err;
      });
  };
  const onFinish = async (values) => {
    setLoading(true);
    const { email } = values;
    console.log(email);
    var signedInUser;
    try {
      signedInUser = await reset(email);
      setErr(false);
      setAuth(true);
    } catch (error) {
      setErr(true);
      if (error.response && error.response.data) {
        setMsg(error.response.data);
      }
    }
    form.resetFields();
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) {
      // history.replace("/jobs")
    }
  }, [user, loading]);
  return (
    <div>
      <div
        // className="card"
        style={{
          width: "450px",
          maxWidth: "500px",
          margin: "auto",

          padding: "2rem",
        }}
      >
        {isAuthed ? (
          <div style={{ marginBottom: "2rem" }}>
            <Alert
              message="Success!"
              description={`Please check your email to reset your password.`}
              type="success"
              closable
            />
          </div>
        ) : (
          <></>
        )}
        {err ? (
          <>
            <div style={{ marginBottom: "2rem" }}>
              <Alert
                message="Thank you!"
                description={`If your email is located, we will send you a password reset link.`}
                type="info"
                closable
              />
            </div>
          </>
        ) : (
          <></>
        )}
        <Spin spinning={loading}>
          <Form
            form={form}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your Email!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Reset Now
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </div>
  );
}
export default PasswordResetRequest;
