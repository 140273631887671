import React, { Component } from "react";
import PropTypes from "prop-types";
import RichTextEditor from "react-rte";

export default class TextEditor extends Component {
  static propTypes = {
    onChange: PropTypes.func,
  };
  state = {
    value: RichTextEditor.createEmptyValue(),
  };

  componentDidMount() {
    if (this.props.description != undefined) {
      this.setState({
        value: RichTextEditor.createValueFromString(
          this.props.description,
          "html"
        ),
      });
    }
  }

  onChange = (value) => {
    this.setState({ value });
    if (this.props.onChange) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      this.props.onChange(value.toString("html"));
    }
  };

  render() {
    return (
      <RichTextEditor
        className="new-post-editor"
        value={this.state.value}
        onChange={this.onChange}
      />
    );
  }
}
