import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  auth,
  signInWithEmailAndPassword,
  getAuth,
  loginUser,
} from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Form, Input, Button, Checkbox, Spin, Alert } from "antd";
import { UserContext } from "./context";
function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [user, loading, error] = useAuthState(auth);
  const [isAuthed, setAuth] = useState(false);
  const [isError, setError] = useState(false);
  const [errMsg, setMsg] = useState("");
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const location = useLocation();
  const onFinish = async (values) => {
    setLoading(true);

    try {
      const signedUser = await loginUser(values.email, values.password);
      const idToken = await signedUser.getIdTokenResult();
      const roles = idToken.claims.roles;
      console.log({ idToken });
      setAuth(true);
      setTimeout(() => {
        setLoading(false);
        if (roles && roles.includes("employer")) {
          navigate("/dashboard/employer");
          navigate(0);
        } else {
          navigate("/jobs");
          navigate(0);
        }
      }, 500);
    } catch (error) {
      console.log(error.message);
      const errorCode = error.code;
      console.log(error.code);
      var errorMessage = error.message;
      if (error.code == "auth/email-already-in-use") {
        errorMessage = "The email address is already in use";
      } else if (error.code == "auth/invalid-email") {
        errorMessage = "The email address is not valid.";
      } else if (error.code == "auth/operation-not-allowed") {
        errorMessage = "Operation not allowed.";
      } else if (error.code == "auth/user-not-found") {
        errorMessage = "The email address is not found.";
      } else if (error.code == "auth/wrong-password") {
        errorMessage = "The password is wrong.";
      }

      setMsg(errorMessage);
      setError(true);
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {
    console.log("loading login page");
    userContext.addPageId();

    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) {
      console.log(user);

      // navigate("/jobs");
    }
  }, []);

  return (
    <div>
      {isAuthed ? (
        <div
          style={{
            marginBottom: "2rem",

            maxWidth: "500px",
            margin: "auto",
          }}
        >
          <Alert message="Success!" description={``} type="success" closable />
        </div>
      ) : (
        <></>
      )}
      {isError ? (
        <>
          <div
            style={{
              marginBottom: "2rem",

              maxWidth: "500px",
              margin: "auto",
            }}
          >
            <Alert
              message="Error Login:"
              description={`${errMsg}`}
              type="error"
              closable
            />
          </div>
        </>
      ) : (
        <></>
      )}
      <Spin spinning={isLoading}>
        <div className=" ">
          <div className="auth-page">
            <div className="auth-form card">
              <Form
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Email!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item name="remember" valuePropName="checked">
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <Form.Item>
                  <div className="mobile-center">
                    <Button type="primary" htmlType="submit">
                      Login
                    </Button>
                  </div>
                </Form.Item>
              </Form>

              <div className="flex-column flex-center">
                <div>
                  <Link to="/auth/reset-password">Forgot Password</Link>
                </div>
                <div>
                  Don't have an account? <Link to="/register">Register</Link>{" "}
                  now.
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}
export default Login;
