import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Col,
  Row,
  Select,
  InputNumber,
  DatePicker,
  AutoComplete,
  Cascader,
  Divider,
  Space,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

export default function WorkExperience(appProps) {
  const [currentJob, setCurrentJob] = useState([false]);
  useEffect(() => {
    if (appProps.experiences) {
      var iscurrentJob = appProps.experiences.map(
        (experience) => experience.currentJob
      );
      setCurrentJob(iscurrentJob);
    }
  }, [appProps.experiences]);
  return (
    <div>
      <h3>Work Experiences</h3>
      <Form.List name="experiences">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <div key={key}>
                <Form.Item
                  label="Employer Name"
                  {...restField}
                  name={[name, "employerName"]}
                  fieldKey={[fieldKey, "employerName"]}
                  rules={[
                    {
                      required: true,
                      message: "Please input Employer Name!",
                    },
                  ]}
                >
                  <Input placeholder="Employer Name" />
                </Form.Item>
                <Form.Item
                  label="Job Title"
                  {...restField}
                  name={[name, "jobTitle"]}
                  fieldKey={[fieldKey, "jobTitle"]}
                  rules={[
                    {
                      required: true,
                      message: "Please input Job Title",
                    },
                  ]}
                >
                  <Input placeholder="Employer Name" />
                </Form.Item>
                <Form.Item
                  label="Current Job"
                  {...restField}
                  name={[name, "currentJob"]}
                  fieldKey={[fieldKey, "currentJob"]}
                  valuePropName="checked"
                >
                  <Checkbox
                    onChange={(e) => {
                      var ifCurrentJob = currentJob;
                      if (ifCurrentJob[fieldKey] != null) {
                        ifCurrentJob[fieldKey] = e.target.checked;
                      }
                      if (ifCurrentJob[fieldKey] == null) {
                        ifCurrentJob.push({ [fieldKey]: e.target.checked });
                      }

                      setCurrentJob([...ifCurrentJob]);
                    }}
                  >
                    Is this your Current Job?{" "}
                  </Checkbox>
                </Form.Item>

                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col className="gutter-row" xs={24} md={12} sm={24}>
                    <Form.Item
                      label="Start Date"
                      {...restField}
                      name={[name, "startDate"]}
                      fieldKey={[fieldKey, "startDate"]}
                      rules={[
                        {
                          required: true,
                          message: "Please input your Start Date",
                        },
                      ]}
                    >
                      <DatePicker
                        format={"YYYY-MM-DD"}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" xs={24} md={12} sm={24}>
                    {currentJob[fieldKey] ? (
                      <></>
                    ) : (
                      <Form.Item
                        label="End Date"
                        {...restField}
                        name={[name, "endDate"]}
                        fieldKey={[fieldKey, "endDate"]}
                        rules={[
                          {
                            required: true,
                            message: "Please input your End Date",
                          },
                        ]}
                      >
                        <DatePicker
                          format={"YYYY-MM-DD"}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    )}
                  </Col>
                </Row>

                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col className="gutter-row" xs={24} md={8} sm={24}>
                    <Form.Item
                      label="City"
                      {...restField}
                      name={[name, "city"]}
                      fieldKey={[fieldKey, "city"]}
                    >
                      <Input placeholder="City" />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" xs={24} md={8} sm={24}>
                    <Form.Item
                      label="State"
                      {...restField}
                      name={[name, "state"]}
                      fieldKey={[fieldKey, "state"]}
                    >
                      <Input placeholder="state" />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" xs={24} md={8} sm={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "country"]}
                      fieldKey={[fieldKey, "country"]}
                      label="Country"
                    >
                      <Input placeholder="country" />
                    </Form.Item>
                  </Col>
                </Row>
                <Button
                  danger
                  type="dashed"
                  onClick={() => remove(name)}
                  block
                  icon={<MinusCircleOutlined />}
                >
                  Remove field
                </Button>
                <Divider />
              </div>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add field
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );
}
