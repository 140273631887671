import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table, Tag, Space } from "antd";
import CandidateDashboard from "./layout/dashboard";
import { db, getAuth } from "../auth/firebase";
const columns = [
  {
    title: "Position",
    dataIndex: "name",
    key: "name",
    render: (text, record, index) => (
      <Link to={`/jobs/${record.job.uid}`}>{record.job.name}</Link>
    ),
  },
  {
    title: "Hotel",
    dataIndex: "hotel",
    key: "hotel",
    render: (text, record) => {
      return record.job.hotel.name;
    },
  },
  {
    title: "Posted Time",
    dataIndex: "createdAt",
    key: "createdAt",
    sorter: (a, b) => a.createdAt - b.createdAt,
    render: (text, record) => {
      var date;
      if (record.createdAt) {
        date = record.createdAt.toDate().toDateString();
      }

      return <>{date}</>;
    },
  },

  {
    title: "Action",
    key: "action",
    render: (text, record) => {
      console.log(record);
      return (
        <Space size="middle">
          <a>View Job</a>
        </Space>
      );
    },
  },
];

export default function AppliedJobs(appProps) {
  const [savedJobs, setSavedJobs] = useState([]);
  useEffect(async () => {
    if (appProps.user) {
      var userId = appProps.user.uid;
      const querySnapshot = await db
        .collection("users")
        .doc(userId)
        .collection("appliedJobs")

        .get();

      const savedJobs = querySnapshot.docs.map((doc) => {
        return { ...doc.data(), ...{ key: doc.id } };
      });
      console.log(savedJobs);
      setSavedJobs(savedJobs);
    }
  }, []);
  return (
    <CandidateDashboard>
      <Table
        columns={columns}
        dataSource={savedJobs}
        scroll={{
          x: "400px",
        }}
      />
    </CandidateDashboard>
  );
}
