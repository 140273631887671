import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import {
  auth,
  registerWithEmailAndPassword,
  storage,
  db,
} from "../../auth/firebase";
import { getAuth } from "firebase/auth";
import {
  Form,
  Input,
  Button,
  Radio,
  Upload,
  Spin,
  Result,
  message,
  Typography,
} from "antd";
import { UploadOutlined, InboxOutlined } from "@ant-design/icons";

const { Text } = Typography;
function UploadCoverLetter(props) {
  const [isLoading, setLoading] = useState(false);

  const [fileList, setFileList] = useState([]);

  const onRemove = (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "application/pdf" ||
      file.type ===
        "register.js:116 application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    if (!isJpgOrPng) {
      message.error("You can only upload PDF/docx file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("File must smaller than 2MB!");
    }
    props.setFile(file);
    // return isJpgOrPng && isLt2M;
    return false;
  };

  return (
    <div>
      <Spin spinning={isLoading}>
        <Form.Item
          valuePropName="fileList"
          // getValueFromEvent={normFile}
          noStyle
        >
          <Text> </Text>
          <Upload.Dragger
            accept=".doc,.docx,application/pdf, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            maxCount="1"
            onRemove={onRemove}
            beforeUpload={beforeUpload}
            label="Upload Cover Letter"
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">Support format: doc, docx, pdf</p>
          </Upload.Dragger>
        </Form.Item>
      </Spin>
    </div>
  );
}
export default UploadCoverLetter;
