import React from "react";
import { Layout } from "antd";
import Footer from "../footer";
import AppHeader from "./header";
const { Content } = Layout;

export default function MemberLayout(appProps) {
  return (
    <Layout>
      <AppHeader className="header" {...appProps} />
      <Layout className="app site-layout">
        <Content className="site-layout-content">{appProps.children}</Content>
      </Layout>
      <Footer />
    </Layout>
  );
}
