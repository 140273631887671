import React, { useState, useEffect } from "react";
import TextEditor from "./richText";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Col,
  Row,
  Select,
  InputNumber,
  DatePicker,
  AutoComplete,
  Cascader,
  Typography,
  Tag,
} from "antd";
import jobRequirement from "./jobRequirements";
const { Option } = Select;
const { Text } = Typography;
const { TextArea } = Input;

export default function JobDescription(props) {
  useEffect(() => {
    // console.log(props);
  }, []);
  const onRichValueChange = (value) => {
    // console.log(value);
  };
  const options = [
    { value: "Paid Time Off" },
    { value: "Health Insurance" },
    { value: "501k" },
    { value: "Bonus" },
    { value: "Other" },
  ];
  const colors = [
    { value: "gold" },
    { value: "lime" },
    { value: "green" },
    { value: "cyan" },
  ];
  function tagRender(props) {
    const { label, value, closable, onClose } = props;

    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    var colorValue;
    if (value) {
      const indexOfColor =
        options.findIndex((item) => item.value == value) % colors.length;

      colorValue = colors[indexOfColor].value;
    }

    return (
      <Tag
        color={colorValue}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  }
  return (
    <div>
      <h3>Job Description</h3>
      <Form.Item
        name="name"
        label="Position Name"
        rules={[{ required: true, message: "Please enter the job title." }]}
      >
        <Input placeholder="Position " />
      </Form.Item>
      <Form.Item
        name="type"
        label="Job Type "
        rules={[{ required: true, message: "Please select the job type!" }]}
      >
        <Select
          showSearch
          style={{ maxWidth: 600 }}
          placeholder="Search to Select"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          filterSort={(optionA, optionB) =>
            optionA.children
              .toLowerCase()
              .localeCompare(optionB.children.toLowerCase())
          }
        >
          <Option value="Full Time">Full Time</Option>
          <Option value="Part Time">Part Time</Option>
          <Option value="Contract">Contract</Option>
          <Option value="Temporary">Temporary</Option>
          <Option value="Intern">Intern</Option>
          <Option value="Other">Other</Option>
        </Select>
      </Form.Item>
      <Text>Pay Range</Text>
      <Input.Group compact label="Pay range from">
        <Form.Item name={["pay", "minimum"]}>
          <Input
            style={{ maxWidth: 300, textAlign: "center" }}
            placeholder="Minimum"
            addonAfter="$"
          />
        </Form.Item>
        <Input
          className="site-input-split"
          style={{
            width: 30,
            borderLeft: 0,
            borderRight: 0,
            pointerEvents: "none",
          }}
          placeholder="~"
          disabled
        />
        <Form.Item name={["pay", "maximum"]}>
          <Input
            className="site-input-right"
            addonAfter="$"
            style={{
              maxWidth: 300,
              textAlign: "center",
            }}
            placeholder="Maximum"
          />
        </Form.Item>
      </Input.Group>

      <Form.Item name="benefits" label="Benefits">
        <Select
          mode="multiple"
          showArrow
          tagRender={tagRender}
          style={{ width: "100%" }}
          options={options}
        />
      </Form.Item>

      <Form.Item name="description" label="Description">
        {/* <TextArea
          rows={15}
          allowClear
          placeholder="Details about this job..."
        /> */}
        <TextEditor
          key={props.description}
          onChange={onRichValueChange}
          description={props.description}
        />
      </Form.Item>
    </div>
  );
}
