import React, { useState, useEffect } from "react";
import { Avatar, Typography, Space, Empty, Button } from "antd";
import {
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { db, firebase, getAuth, storage } from "../auth/firebase";
import {
  Link,
  useNavigate,
  useSearchParams,
  useParams,
} from "react-router-dom";
import moment from "moment";
import EmployerLayout from "./layout/layout";
const { Text, Paragraph, Title } = Typography;
export default function ProfileView(appProps) {
  const [currentProfile, setCurrentProfile] = useState({});
  const [resumeUrl, setResumeUrl] = useState("");
  const [resumeFileName, setResumeFileName] = useState("");
  const [coverletterUrl, setCoverletterUrl] = useState("");
  const [coverletterFileName, setCoverletterFileName] = useState("");
  const [loading, setLoading] = useState(true);
  const [photoUrl, setPhotoUrl] = useState();
  const [job, setJob] = useState();
  let { jobId, candidateId } = useParams();
  const navigate = useNavigate();

  useEffect(async () => {
    setLoading(true);

    getCurrentJob();

    setLoading(false);
  }, [appProps]);
  const getCurrentJob = async () => {
    console.log(jobId, candidateId);

    const userId = appProps.user.uid;
    try {
      const jobQuery = await db.collection("jobs").doc(jobId).get();
      const job = jobQuery.data();
      console.log(job);
      if (job.userId != userId) {
        return navigate("/jobs");
      }
      setJob(job);
      getCandidate();
      return job;
    } catch (error) {
      console.log(error);
    }
  };
  const getCandidate = async () => {
    const userRef = await db.collection("users").doc(candidateId).get();

    var userRefProfile = userRef.data();

    setPhotoUrl(userRefProfile.photoUrl);

    const candidateProfileRef = await db
      .collection("candidates")
      .doc(candidateId)
      .get();

    var candidateProfile = candidateProfileRef.data();
    console.log(candidateProfile);
    if (candidateProfile) {
      if (candidateProfile.resume && candidateProfile.resume.experiences) {
        candidateProfile.resume.experiences =
          candidateProfile.resume.experiences.map((item) => {
            return {
              ...item,
              startDate: moment(item.startDate),
              endDate: moment(item.endDate),
            };
          });
      }
      setCurrentProfile(candidateProfile.resume);
      setResumeFileName(candidateProfile.resumeFileName);
      setResumeUrl(candidateProfile.resumeUrl);
      setCoverletterFileName(candidateProfile.coverletterFileName);
      setCoverletterUrl(candidateProfile.coverletterUrl);
    }
  };
  const contactInfo = () => {
    return (
      <div
        className="card"
        style={{ padding: "10px", maxWidth: "800px", margin: "auto" }}
      >
        {photoUrl ? (
          <img src={photoUrl} className="avatar" />
        ) : (
          <Avatar
            size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
            icon={<UserOutlined />}
          />
        )}
        <Title level={4}>
          {currentProfile.firstName + " " + currentProfile.lastName}
        </Title>
        <Space direction="vertical">
          <Text>
            <PhoneOutlined /> {currentProfile.phone}
          </Text>
          <Text>
            <MailOutlined /> {currentProfile.email}
          </Text>
          <Text type="secondary">
            {currentProfile.city}, {currentProfile.state}, {currentProfile.zip}{" "}
          </Text>
          {resumeUrl ? (
            <a href={resumeUrl} download={resumeFileName} target="_blank">
              Download Resume
            </a>
          ) : (
            ""
          )}
        </Space>
      </div>
    );
  };

  const coverLetter = () => {
    return (
      <div
        className="card"
        style={{
          padding: "10px",
          maxWidth: "800px",
          margin: "auto",
          marginTop: "10px",
        }}
      >
        <Title level={4}>Cover Letter</Title>
        {coverletterUrl ? (
          <a
            href={coverletterUrl}
            download={coverletterFileName}
            target="_blank"
          >
            Download
          </a>
        ) : (
          ""
        )}

        <Paragraph>
          <Paragraph>
            {currentProfile.coverLetter ? currentProfile.coverLetter : ""}
          </Paragraph>
        </Paragraph>
      </div>
    );
  };

  const experiences = () => {
    return (
      <div
        className="card"
        style={{
          padding: "10px",
          maxWidth: "800px",
          margin: "auto",
          marginTop: "10px",
        }}
      >
        <Title level={4}>Experiences</Title>

        {currentProfile.experiences &&
          currentProfile.experiences.map((item) => {
            var startDateTime = moment(item.startDate).format("MMMM Do YYYY");
            var endDateTime = moment(item.endDate).format("MMMM Do YYYY");
            var duration = moment(item.startDate).diff(
              moment(item.endDate),
              "years"
            );
            return (
              <div
                style={{ marginTop: "10px" }}
                key={item.employerName + item.jobTitle}
              >
                <Space direction="vertical" size={"2"}>
                  <Text strong>{item.employerName}</Text>
                  <Text>{item.jobTitle}</Text>
                  <Text type="secondary">
                    {startDateTime} – {endDateTime}, Duration {duration} years
                  </Text>
                  <Text type="secondary">
                    {item.city}, {item.state}, {item.country}{" "}
                  </Text>
                </Space>
              </div>
            );
          })}
      </div>
    );
  };

  const educations = () => {
    return (
      <div
        className="card"
        style={{
          padding: "10px",
          maxWidth: "800px",
          margin: "auto",
          marginTop: "10px",
        }}
      >
        <Title level={4}>Education</Title>
        {currentProfile.educations &&
          currentProfile.educations.map((item) => {
            var startDateTime = moment(item.startDate).format("MMMM Do YYYY");
            var endDateTime = moment(item.endDate).format("MMMM Do YYYY");
            var duration = moment(item.startDate).diff(
              moment(item.endDate),
              "years"
            );
            return (
              <div
                style={{ marginTop: "10px" }}
                key={item.employerName + item.jobTitle}
              >
                <Space direction="vertical" size={"2"}>
                  <Text strong>{item.schoolName}</Text>
                  <Text>
                    {item.degree + " of "} {item.major + " degree"}
                  </Text>
                  {/* <Text type="secondary">
                    {startDateTime} – {endDateTime}, Duration {duration} years
                  </Text> */}
                  <Text type="secondary">
                    {item.city}, {item.state}, {item.country}{" "}
                  </Text>
                </Space>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <EmployerLayout>
      {" "}
      <div style={{ margin: "10px" }}>
        <Button onClick={() => navigate(-1)}>
          <ArrowLeftOutlined />
          go back
        </Button>
        {contactInfo()}
        {coverLetter()}
        {experiences()}

        {educations()}
      </div>
    </EmployerLayout>
  );
}
