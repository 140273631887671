import React from "react";
import { Avatar, Typography, Space, Button } from "antd";
import {
  UserOutlined,
  MenuUnfoldOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
const { Text, Link, Title, Paragraph } = Typography;

export default function CandidateDetail(appProps) {
  const coverLetter = (candidateCL) => {
    const clContent = candidateCL.split("\n").map((item) => {
      return <div>{item}</div>;
    });

    return (
      <div
        className="card"
        style={{
          padding: "10px",
          maxWidth: "800px",
          margin: "auto",
          marginTop: "10px",
        }}
      >
        <Title level={4}>Cover Letter</Title>
        <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: "more" }}>
          {clContent}
        </Paragraph>
      </div>
    );
  };
  const contactInfo = (candidate) => {
    return (
      <div
        className="card"
        style={{ padding: "10px", maxWidth: "800px", margin: "auto" }}
      >
        <Avatar
          size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
          icon={<UserOutlined />}
        />

        <Title level={4}>
          {candidate.firstName + "    " + candidate.lastName}
        </Title>

        <Space direction="vertical">
          <Text>
            <MailOutlined style={{ color: "lightsalmon" }} />{" "}
            {candidate.resume && candidate.resume.email}{" "}
            <PhoneOutlined style={{ color: "lightsalmon" }} />
            {candidate.resume && candidate.resume.phone}
          </Text>
          <Text>
            {candidate.resume &&
              candidate.resume.experiences &&
              candidate.resume.experiences[0].employerName}
          </Text>

          <Text type="secondary">
            {candidate.resume &&
              candidate.resume.experiences &&
              candidate.resume.experiences[0].jobTitle}{" "}
          </Text>
        </Space>
      </div>
    );
  };
  const experiences = (candidateExperiences) => {
    const experienceList = candidateExperiences.map((experience) => {
      return (
        <Space
          direction="vertical"
          size={"2"}
          style={{ marginBottom: "15px" }}
          key={experience.employerName + experience.jobTitle}
        >
          <Text strong>{experience.employerName}</Text>
          <Text>{experience.jobTitle}</Text>
          <Text type="secondary">
            {experience.startDate} to {experience.endDate}
          </Text>
          <Text type="secondary">
            {experience.city +
              ", " +
              experience.state +
              ", " +
              experience.country}{" "}
          </Text>
        </Space>
      );
    });
    return (
      <div
        className="card"
        style={{
          padding: "10px",
          maxWidth: "800px",
          margin: "auto",
          marginTop: "10px",
        }}
      >
        <Title level={4}>Experiences</Title>
        {experienceList}
      </div>
    );
  };
  const educations = (candidateEducations) => {
    const educationList = candidateEducations.map((education) => {
      return (
        <div>
          <Space
            direction="vertical"
            size={"2"}
            key={education.schoolName + education.degree}
          >
            <Text strong> {education.schoolName}</Text>
            <Text>
              {education.degree} in {education.major}
            </Text>
            <Text type="secondary">2006 – 2010</Text>

            <Text type="secondary">
              {" "}
              {education.address +
                ", " +
                education.city +
                ", " +
                education.country}{" "}
            </Text>
          </Space>
        </div>
      );
    });
    return (
      <div
        className="card"
        style={{
          padding: "10px",
          maxWidth: "800px",
          margin: "auto",
          marginTop: "10px",
        }}
      >
        <Title level={4}>Experiences</Title>
        {educationList}
      </div>
    );
  };
  const downloads = (resumeUrl, coverLetterUrl) => {
    return (
      <div
        className="card"
        style={{
          padding: "10px",
          maxWidth: "800px",
          margin: "auto",
          marginTop: "10px",
        }}
      >
        {resumeUrl && (
          <Button
            type="link"
            onClick={() => {
              var xhr = new XMLHttpRequest();
              xhr.responseType = "blob";
              xhr.onload = (event) => {
                var blob = xhr.response;
              };
              xhr.open("GET", resumeUrl);
              xhr.send();
            }}
          >
            Download Resume
          </Button>
        )}
        {coverLetterUrl && (
          <Button type="link">
            <a href={coverLetterUrl} download>
              Download CoverLetter
            </a>
          </Button>
        )}
      </div>
    );
  };
  return (
    <div style={{ margin: "10px" }}>
      <a
        onClick={() => {
          appProps.close();
        }}
      >
        Go back <MenuUnfoldOutlined />
      </a>
      {contactInfo(appProps)}
      {downloads(appProps.resumeUrl, appProps.coverLetterUrl)}
      {appProps.resume &&
        appProps.resume.coverLetter &&
        coverLetter(appProps.resume.coverLetter)}

      {appProps.resume &&
        appProps.resume.experiences.length > 0 &&
        experiences(appProps.resume.experiences)}

      {appProps.resume &&
        appProps.resume.educations.length > 0 &&
        educations(appProps.resume.educations)}
    </div>
  );
}
