import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  Col,
  Row,
  Select,
  Switch,
} from "antd";

export default function MiscInfo() {
  const { Text } = Typography;
  return (
    <div>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" xs={24} md={12} sm={24}>
          <Text>
            Each talent's resume will be shared with hotels hiring managers in
            New York City. If you do not want to share your resume, please keep
            it confidential by toggling the switch below. If you decide to keep
            your profile confidential, your name and company name in your
            experiences will be hashed.
          </Text>
          <Form.Item
            label="Would you like to keep your profile confidential?"
            name="confidential"
            rules={[{ required: true, message: "Please advise!" }]}
          >
            <Switch />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
