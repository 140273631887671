import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../auth/firebase";
import {
  MdLocationOn,
  MdBusiness,
  MdSource,
  MdAttachMoney,
} from "react-icons/md";
import {
  Col,
  Row,
  Space,
  Divider,
  Tag,
  Typography,
  message,
  List,
  Empty,
} from "antd";
import SaveButton from "./components/saveButton";
import ApplyButton from "./components/applyButton";
import { UserContext } from "../auth/context";
import LoadingFrame from "../../layout/loadingFrame";
const { Title, Text, Paragraph } = Typography;
export default function JobDetail(props) {
  const [job, setJob] = useState({});
  const [loading, setLoading] = useState(false);
  const [isOwner, setOwner] = useState(false);
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  let mounted = false;
  useEffect(() => {
    setLoading(true);
    if (props.job) {
      setJob(props.job);
    }
    setLoading(false);
    return () => {
      mounted = true; // <- change to true
    };
  }, []);
  const getJob = async () => {
    console.log(props);
    const jobId = props.id;
    if (jobId) {
      var docRef = await db.collection("jobs").doc(String(jobId)).get();

      if (docRef.exists) {
        var jobData = docRef.data();
        jobData.uid = docRef.id;
        if (!mounted) {
          setJob(jobData);
        }
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    }
  };
  const onDelete = () => {
    db.collection("jobs")
      .doc(props.match.params.id)
      .delete()
      .then(() => {
        message.success("Job successfully deleted!");
        console.log("Document successfully deleted!");
        navigate("/jobs");
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });
  };
  const benefitsTags = (benefits) => {
    const colors = [
      "magenta",
      "red",
      "volcano",
      "orange",
      "gold",
      "lime",
      "green",
      "cyan",
      "blue",
      "geekblue",
      "purple",
    ];
    if (!benefits) {
      return;
    }
    return benefits.map((item) => {
      const indexOfColor = benefits.indexOf(item) % colors.length;

      const colorValue = colors[indexOfColor];
      return (
        <Tag color={colorValue} key={item}>
          {item}
        </Tag>
      );
    });
  };

  const result = () => {
    if (!props.id) {
      return (
        <div style={{ margin: "160px" }}>
          <Empty />
        </div>
      );
    } else {
      return (
        <div className="card" style={{ position: "relative", zIndex: "0" }}>
          <div
          // style={{
          //   display: "flex",
          //   justifyContent: "space-between",
          // }}
          >
            <Row style={{ position: "sticky", top: 40 }}>
              <Col flex="1 1 200px">
                <Title level={3}>{job.name}</Title>
              </Col>
              <Col
                style={{
                  padding: "10px",
                  // textAlign: "center",
                }}
                flex="0 1 300px"
              >
                <div
                  style={{
                    display: "flex",
                    alignContent: "flex-start",
                    margin: "3px",
                    position: "sticky",
                    top: 20,
                    padding: "5px",
                  }}
                >
                  <SaveButton {...job} />
                  <ApplyButton {...job} key={job.uid} />
                </div>
              </Col>
            </Row>
          </div>
          <Space size={"middle"} align="start">
            <Text className="description-tab">
              <MdBusiness /> {job.hotel && job.hotel.name}{" "}
            </Text>
            {"   "}
            <Text className="description-tab">
              <MdLocationOn /> {job.hotel && job.hotel.city},{" "}
              {job.hotel && job.hotel.state}
            </Text>
            <Text className="description-tab">
              <MdAttachMoney />

              {job.pay && job.pay.minimum}
              {"~"}
              {job.pay && job.pay.maximum}
            </Text>
            <Text className="description-tab">
              <MdSource />

              {job.type}
            </Text>
          </Space>
          <div style={{ marginTop: "5px" }}>{benefitsTags(job.benefits)}</div>

          <Divider />
          <Title level={4}>Description</Title>
          <Paragraph>
            <div dangerouslySetInnerHTML={{ __html: job.description }} />
          </Paragraph>
          <Divider />
          <Title level={4}>Requirements</Title>
          <Paragraph>
            <div dangerouslySetInnerHTML={{ __html: job.requirement }} />
          </Paragraph>
          <Divider />
          <Title level={4}>Skills</Title>
          {job.skills && job.skills.length > 0 && (
            <List
              size="large"
              dataSource={job.skills}
              renderItem={(item) => <List.Item>{item}</List.Item>}
            />
          )}
        </div>
      );
    }
  };
  return (
    <LoadingFrame loading={loading}>{job ? result() : <></>}</LoadingFrame>
  );
}
