import React, { useState, useEffect } from "react";
import { Form, Button, Spin, Typography, Popconfirm, message } from "antd";
import {
  ref,
  uploadBytes,
  updateMetadata,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

import moment from "moment";
import { db, firebase, getAuth, storage } from "../auth/firebase";
import ContactDetails from "./components/contactDetails";
import Education from "./components/education";
import WorkExperience from "./components/workExperience";
import CoverLetter from "./components/coverLetter";
import CandidateDashboard from "./layout/dashboard";
import UploadResume from "./components/uploadResume";
import UploadCoverLetter from "./components/uploadCoverLetter";
import MiscInfo from "./components/miscInfo";
export default function ProfileEditing(appProps) {
  const { Title, Text } = Typography;
  let formRef = React.createRef();
  const [currentProfile, setCurrentProfile] = useState({});
  const [resumeUrl, setResumeUrl] = useState("");
  const [resumeFileName, setResumeFileName] = useState("");
  const [coverletterUrl, setCoverletterUrl] = useState("");
  const [coverletterFileName, setCoverletterFileName] = useState("");
  const [file, setFile] = useState("");
  const [clfile, setCLFile] = useState("");
  const [loading, setLoading] = useState(true);
  let mounted = false;

  useEffect(async () => {
    setLoading(true);
    var userId = appProps.user.uid;
    const candidateProfileRef = await db
      .collection("candidates")
      .doc(userId)
      .get();
    var candidateProfile = candidateProfileRef.data();
    if (candidateProfile) {
      if (candidateProfile.resume && candidateProfile.resume.experiences) {
        candidateProfile.resume.experiences =
          candidateProfile.resume.experiences.map((item) => {
            return {
              ...item,
              startDate: moment(item.startDate),
              endDate: moment(item.endDate),
            };
          });
      }
      if (!mounted) {
        setCurrentProfile(candidateProfile.resume);
        setResumeFileName(candidateProfile.resumeFileName);
        setResumeUrl(candidateProfile.resumeUrl);
        setCoverletterFileName(candidateProfile.coverletterFileName);
        setCoverletterUrl(candidateProfile.coverletterUrl);
      }
    }

    setLoading(false);
    return () => {
      mounted = true;
    };
  }, []);

  useEffect(() => {
    formRef.current.setFieldsValue(currentProfile);
  }, [currentProfile]);

  const onFinish = async (values) => {
    createUserProfile(values);
    saveResume(values);

    message.success("Profile Updated!");
  };
  const uploadResumeFile = async (file, fileType) => {
    const resumeRef = ref(
      storage,
      `${fileType}s/${appProps.user.uid}/${fileType}`
    );
    const snapshot = await uploadBytes(resumeRef, file);

    const newMetadata = {
      cacheControl: "public,max-age=300",
      contentType: file.type,
    };

    // Update metadata properties
    updateMetadata(resumeRef, newMetadata);

    const url = await getDownloadURL(resumeRef);
    return { resumeUrl: url, resumeFileName: file.name };
  };

  const createUserProfile = async (values) => {
    const uid = appProps.user.uid;

    const resumeRefl = db.collection("users").doc(uid);

    await resumeRefl.set(
      {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        uid: uid,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true }
    );
  };
  const saveResume = async (values) => {
    const userEmail = appProps.user.uid;

    console.log(userEmail);
    const resumeRefl = db.collection("candidates").doc(String(userEmail));
    console.log(resumeRefl);
    var obj = JSON.parse(
      JSON.stringify(values, function (k, v) {
        if (v === undefined) {
          return "";
        }
        return v;
      })
    );

    await resumeRefl.set(
      {
        resume: obj,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true }
    );

    if (file) {
      const fileResult = await uploadResumeFile(file, "resume");
      db.collection("candidates").doc(userEmail).set(
        {
          resumeFileName: fileResult.resumeFileName,
          resumeUrl: fileResult.resumeUrl,
        },
        { merge: true }
      );

      setResumeFileName(fileResult.resumeFileName);
      setResumeUrl(fileResult.resumeUrl);
    }
    if (clfile) {
      const fileResult = await uploadResumeFile(clfile, "coverletter");
      db.collection("candidates").doc(userEmail).set(
        {
          coverletterFileName: fileResult.resumeFileName,
          coverletterUrl: fileResult.resumeUrl,
        },
        { merge: true }
      );
      setCoverletterUrl(fileResult.resumeUrl);
      setCoverletterFileName(fileResult.resumeFileName);
    }
  };
  const listCoverLetterFile = (link, name) => {
    const confirm = async () => {
      if (coverletterFileName != "") {
        const fileRef = ref(
          storage,
          `resumes/${appProps.user.email}/${coverletterFileName}`
        );
        await deleteObject(fileRef);
        setResumeFileName("");
        db.collection("candidates").doc(appProps.user.email).update({
          coverletterUrl: "",
          coverletterFileName: "",
        });
      }
    };
    const cancel = (e) => {
      console.log(e);
      message.error("Click on No");
    };
    return (
      <div>
        <div style={{ display: "flex", alignContent: "space-between" }}>
          <div style={{ flex: "1" }}>
            <a href={link}>{name}</a>
          </div>
          <div>
            <Popconfirm
              title="Are you sure to delete this resume?"
              onConfirm={confirm}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <a href="#">Delete</a>
            </Popconfirm>
          </div>
        </div>
      </div>
    );
  };
  const listResumeFile = (link, name) => {
    const confirm = async () => {
      if (resumeFileName != "") {
        const fileRef = ref(
          storage,
          `resumes/${appProps.user.email}/${resumeFileName}`
        );
        await deleteObject(fileRef);
        setResumeFileName("");
        db.collection("candidates").doc(appProps.user.email).update({
          resumeUrl: "",
          resumeFileName: "",
        });
      }
    };
    const cancel = (e) => {
      console.log(e);
      message.error("Click on No");
    };
    return (
      <div>
        <div style={{ display: "flex", alignContent: "space-between" }}>
          <div style={{ flex: "1" }}>
            <a href={link}>{name}</a>
          </div>
          <div>
            <Popconfirm
              title="Are you sure to delete this resume?"
              onConfirm={confirm}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <a href="#">Delete</a>
            </Popconfirm>
          </div>
        </div>
      </div>
    );
  };
  const onFinishFailed = (errorInfo) => {
    message.error(errorInfo);
    console.log("Failed:", errorInfo);
  };
  return (
    <CandidateDashboard>
      <Spin spinning={loading}>
        <Form
          key={appProps.user.uid}
          ref={formRef}
          style={{ paddingTop: "2px" }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout={"vertical"}
        >
          <Form.Item style={{ margin: "5px 1vw", padding: "1px 0.5vw" }}>
            <h2>Update Resume </h2>
          </Form.Item>

          <div className="card">
            <MiscInfo />
          </div>
          <div className="card" style={{ marginTop: "22px" }}>
            <ContactDetails />
          </div>
          <div className="card" style={{ marginTop: "22px" }}>
            <Education />
          </div>
          <div className="card" style={{ marginTop: "22px" }}>
            <WorkExperience {...currentProfile} />
          </div>
          <div className="card" style={{ marginTop: "22px" }}>
            <CoverLetter />
            <Text>Upload Cover Letter</Text>

            {coverletterFileName && coverletterFileName != "" ? (
              <>{listCoverLetterFile(coverletterUrl, coverletterFileName)}</>
            ) : (
              <UploadCoverLetter
                setFile={(file) => {
                  setCLFile(file);
                }}
              />
            )}
          </div>
          <div
            className="card"
            style={{
              marginTop: "22px",
              marginBottom: "22px",
              textAlign: "left",
            }}
          >
            <Title level={5}>Upload Your Current Resume</Title>
            {resumeFileName && resumeFileName != "" ? (
              <>{listResumeFile(resumeUrl, resumeFileName)}</>
            ) : (
              <UploadResume
                setFile={(file) => {
                  setFile(file);
                }}
              />
            )}
          </div>

          <Form.Item style={{ textAlign: "center" }}>
            <Button htmlType="submit">Cancel</Button>
            <Button type="primary" htmlType="submit">
              Confirm
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </CandidateDashboard>
  );
}
