import React from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import CandidateDashboard from "../pages/candidate/dashboard";
import ProfileView from "../pages/candidate/profileView";
import ProfileEditing from "../pages/candidate/profileEditing";
import SavedJobs from "../pages/candidate/savedJobs";
import AppliedJobs from "../pages/candidate/appliedJobs";
import { Skeleton } from "antd";
export default function CandidateRoutes(appProps) {
  const MemberOnly = ({
    isLoading,
    isAuthenticated,
    user,
    redirectPath = "/login",
  }) => {
    if (isLoading) {
      return <Skeleton active />;
    }
    // return <Outlet />;
    if (appProps.user && appProps.user.uid) {
      return <Outlet />;
    }

    return <Navigate to={redirectPath} replace />;
  };
  return (
    <Routes>
      <Route element={<MemberOnly {...appProps} />}>
        <Route
          exact
          path="/"
          element={<CandidateDashboard {...appProps} />}
        ></Route>
        <Route
          exact
          path="/saved-jobs"
          element={<SavedJobs {...appProps} />}
        ></Route>
        <Route
          exact
          path="/applied-jobs"
          element={<AppliedJobs {...appProps} />}
        ></Route>
        <Route
          exact
          path="/profile/edit"
          element={<ProfileEditing {...appProps} />}
        ></Route>
        <Route
          exact
          path="/profile"
          element={<ProfileView {...appProps} />}
        ></Route>
      </Route>
    </Routes>
  );
}
