import React from "react";
import { Link } from "react-router-dom";
import EmployerLayout from "./layout/layout";
export default function EmployerDashboard(appProps) {
  return (
    <div>
      <EmployerLayout>
        <h1>Main</h1>
      </EmployerLayout>
    </div>
  );
}
