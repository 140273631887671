import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Col,
  Row,
  Select,
  InputNumber,
  DatePicker,
  AutoComplete,
  Cascader,
  Space,
  Divider,
  Typography,
  Avatar,
} from "antd";
import { AntDesignOutlined } from "@ant-design/icons";

const { Title, Text, Paragraph } = Typography;
export default function CandidateCard(candidate) {
  const { id, firstName, lastName, photoUrl, resume, updatedAt } = candidate;
  const { city, state, experiences } = resume || "";

  const [width, setWidth] = useState(window.innerWidth);
  var currentJob;
  if (resume && resume.experiences) {
    currentJob = experiences.find((item) => item.currentJob);
  }

  var lastJobTitle;
  var lastJobEmployer;
  lastJobTitle = experiences ? experiences[0].jobTitle : "";
  lastJobEmployer = experiences ? experiences[0].employerName : "";
  if (currentJob) {
    lastJobTitle = currentJob.jobTitle;
    lastJobEmployer = currentJob.employerName;
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  return (
    <div className="card" style={{ maxWidth: "800px" }}>
      <div style={{ margin: "auto", padding: "5px" }}>
        <Row>
          <Col xs={6}>
            <img
              style={{ objectFit: "contain", width: "50px", marginTop: "10px" }}
              src={
                photoUrl
                  ? photoUrl
                  : "https://firebasestorage.googleapis.com/v0/b/vuefirebase-c2006.appspot.com/o/hotel-logo%2Fhotel_placeholder.png?alt=media&token=9c128ea2-9582-406e-9e63-de7a00fd3fff"
              }
            ></img>
          </Col>
          <Col xs={18}>
            <Title level={4}>
              <Link to={`/candidates/${id}`}>{firstName + " " + lastName}</Link>
            </Title>
            <Paragraph>
              <Text>
                {lastJobTitle} -- {lastJobEmployer}
              </Text>
            </Paragraph>
            <div>
              <Text type="secondary">
                {city ? city + "," : ""} {state}
              </Text>
            </div>
            <div>
              <Text type="secondary">
                {updatedAt
                  ? new Date(updatedAt._seconds * 1000).toLocaleDateString(
                      "en-us",
                      {
                        weekday: "long",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      }
                    )
                  : ""}
                {/* {updatedAt && updatedAt.toDate().toDateString()} */}
              </Text>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
