import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table, Tag, Space, message, Spin } from "antd";
import CandidateDashboard from "./layout/dashboard";
import { db, getAuth } from "../auth/firebase";

export default function SavedJobs(appProps) {
  const [savedJobs, setSavedJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(async () => {
    const userId = appProps.user.uid;
    const querySnapshot = await db
      .collection("users")
      .doc(userId)
      .collection("savedJobs")

      .get();

    const savedJobs = querySnapshot.docs.map((doc) => {
      return { ...doc.data(), ...{ key: doc.id } };
    });
    console.log(savedJobs);
    setSavedJobs(savedJobs);
  }, [loading]);

  const remove = async (record) => {
    const jobId = record.job.uid;
    await removeUserSavedJobs(jobId);
    await removeJobSavedUser(jobId);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 300);

    message.success("Job removed");
  };

  const removeUserSavedJobs = async (jobId) => {
    const userId = appProps.user.uid;

    await db
      .collection("users")
      .doc(userId)
      .collection("savedJobs")
      .doc(jobId)
      .delete();
  };

  const removeJobSavedUser = async (jobId) => {
    const userEmail = appProps.user.email;
    await db
      .collection("jobs")
      .doc(jobId)
      .collection("savedUsers")
      .doc(userEmail)
      .delete();
  };
  const columns = [
    {
      title: "Position",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => {
        return <Link to={`/jobs/${record.job.uid}`}>{record.job.name}</Link>;
      },
    },
    {
      title: "Hotel",
      dataIndex: "hotel",
      key: "hotel",
      render: (text, record) => {
        return record.job.hotel.name;
      },
    },
    {
      title: "Posted Time",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      render: (text, record) => {
        var date;
        if (record.createdAt) {
          date = record.createdAt.toDate().toDateString();
        }

        return <>{date}</>;
      },
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <Space size="middle">
            <Spin spinning={loading}>
              <a
                onClick={() => {
                  remove(record);
                }}
              >
                Remove
              </a>
            </Spin>
          </Space>
        );
      },
    },
  ];
  return (
    <CandidateDashboard>
      <Table
        columns={columns}
        dataSource={savedJobs}
        scroll={{
          x: "400px",
        }}
      />
    </CandidateDashboard>
  );
}
