import React, { useEffect, useState, useContext } from "react";
import { Button, message, Popconfirm } from "antd";
import { BookOutlined, CheckSquareOutlined } from "@ant-design/icons";
import { db, getAuth, firebase } from "../../auth/firebase";
import { Navigate, useNavigate } from "react-router-dom";
import Axios from "axios";
import { baseUrl, apiRoutes } from "../../auth/apiRoute";
import { UserContext } from "../../auth/context";
export default function ApplyButton(props) {
  const [isApplied, setApplied] = useState(false);

  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  let mounted = false;
  useEffect(async () => {
    if (userContext.user) {
      const userId = userContext.user.uid;

      const jobRef = await db
        .collection("users")
        .doc(userId)
        .collection("appliedJobs")
        .doc(props.uid)
        .get();

      if (!mounted) {
        setApplied(jobRef.exists);
      }
    }

    return () => {
      mounted = true;
    };
  }, [userContext]);

  const onSave = async () => {
    console.log(props);
    if (userContext.user && userContext.user.uid) {
      await updateUserAppliedJobs();
      await updateJobAppliedUser();
      setApplied(true);
    } else {
      navigate("/register");
    }
  };

  const onRemove = () => {
    removeUserAppliedJobs();
    removeJobAppliedUser();
    setApplied(false);
  };
  const updateUserAppliedJobs = async () => {
    const userId = userContext.user.uid;
    try {
      const querySnapshot = await db
        .collection("users")
        .doc(userId)
        .collection("appliedJobs")
        .doc()
        .get();

      var newJob = {
        ...{ job: props },
        ...{ createdAt: firebase.firestore.FieldValue.serverTimestamp() },
      };
      await db
        .collection("users")
        .doc(userId)
        .collection("appliedJobs")
        .doc(props.uid)
        .set(newJob);
    } catch (error) {
      console.log(error);
    }
  };

  const removeUserAppliedJobs = async () => {
    const userId = userContext.user.uid;
    const querySnapshot = await db.collection("users").doc(userId).get();
    var currentAppliedJobs = querySnapshot.data().appliedJobs;

    var newAppliedJobs = currentAppliedJobs.filter(
      (item) => item.uid != props.uid
    );
    await db.collection("users").doc(userId).update({
      appliedJobs: newAppliedJobs,
    });
  };

  const updateJobAppliedUser = async () => {
    console.log("update applied users");

    const userId = userContext.user.uid;
    const candidateData = await db
      .collection("candidates")

      .doc(userId)
      .get();

    sendNotification({ ...props, ...{ candidate: candidateData.data() } });
    if (candidateData.exists) {
      const newUser = candidateData.data();
      newUser.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      newUser.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
      await db
        .collection("jobs")
        .doc(props.uid)
        .collection("appliedUsers")
        .doc(userId)
        .set(newUser);
    }
  };

  const removeJobAppliedUser = async () => {
    const userId = userContext.user.uid;
    const jobRef = await db.collection("jobs").doc(props.uid);
    const jobQuerySnapshot = await jobRef.get();
    var currentAppliedUsers = jobQuerySnapshot.data().appliedUsers;

    var newAppliedUsers = currentAppliedUsers.filter(
      (item) => item.uid != userId
    );
    await db.collection("jobs").doc(props.uid).update({
      appliedUsers: newAppliedUsers,
    });
  };

  const sendNotification = async (data) => {
    try {
      await Axios.post(apiRoutes.emailNewApplicant, { data: data });
    } catch (error) {
      console.log(error);
    }
  };

  const handleVisibleChange = async (newVisible) => {
    console.log(newVisible);
    if (!newVisible) {
      setVisible(newVisible);
      return;
    } // Determining condition before show the popconfirm.

    if (!userContext.profileNeeded) {
      onSave(); // next step
    } else {
      setVisible(newVisible);
    }
  };
  return (
    <div
      style={{
        margin: "0 10px",
        display: userContext.view == "employer" ? "none" : "block",
      }}
    >
      {!isApplied ? (
        <Popconfirm
          title="Your profile is not complete."
          visible={visible}
          onConfirm={() => {
            setVisible(false);
            navigate("/dashboard/candidate/profile/edit");
          }}
          onCancel={() => {
            setVisible(false);
          }}
          onVisibleChange={handleVisibleChange}
          okText="Update Profile"
          cancelText="Never mind"
        >
          <Button type="primary">Apply</Button>
        </Popconfirm>
      ) : (
        <Button disabled>
          <CheckSquareOutlined style={{ color: "green" }} /> Applied{" "}
        </Button>
      )}
    </div>
  );
}
