import React, { useState } from "react";
import "./components.css";
export default function SliderDrawer(props) {
  let classname = "drawer";
  if (props.open) {
    classname = "drawer drawer-open";
  }

  return (
    <div className={classname}>
      <h1>{props.children}</h1>
    </div>
  );
}
