import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Col,
  Row,
  Select,
  InputNumber,
  DatePicker,
  AutoComplete,
  Cascader,
  Space,
  Divider,
  Typography,
  Avatar,
} from "antd";
import { Link, useSearchParams } from "react-router-dom";
import hotelList from "../../../files/hotelList";
const { Option } = Select;
const { Text } = Typography;
export default function Filter(props) {
  const formRef = React.createRef();
  const [filterInfo, setFilterInfo] = useState({});
  const [search, setSearch] = useSearchParams();
  const [selectedHotel, selectHotel] = useState({});
  useEffect(() => {
    getCurrentQuery();
  }, []);
  const hotelsOptions = () => {
    return hotelList.map((item) => {
      return (
        <Option key={item.name} value={item.name}>
          {item.name}
        </Option>
      );
    });
  };
  const onFinish = (values) => {
    console.log({ values });
    props.applyFilter(values);
    setFilterQuery(values);
  };
  const getCurrentQuery = () => {
    const params = new URLSearchParams(search);
    var obj = {};
    for (const [key, val] of params.entries()) {
      if (val.includes(",")) {
        obj[key] = val.split(",");
      } else {
        obj[key] = val;
      }
    }

    formRef.current.setFieldsValue(obj);
    setFilterInfo(obj);
    props.applyFilter(obj);
  };

  const setFilterQuery = (filters) => {
    const params = new URLSearchParams(search);

    if (filters) {
      for (const [key, val] of Object.entries(filters)) {
        if (val) {
          if (val.length > 0) {
            params.append(key, val);
          }
        }
      }
    }

    // history.replace({ search: params.toString() });
    setFilterInfo(filters);
  };

  const onChangeSelect = (value) => {
    const theHotel = hotelList.find((item) => item.name == value);

    selectHotel(theHotel);

    // props.onSelectHotel(theHotel);
    console.log(selectedHotel);
  };
  return (
    <div
      style={{
        margin: "10px 1px",
      }}
    >
      <Form
        layout={"vertical"}
        style={{ backgroundColor: "white", padding: "5px" }}
        onFinish={onFinish}
        initialValues={{}}
        ref={formRef}
      >
        <Form.Item name={"type"} label={"Job Type"}>
          <Select style={{ width: 150 }}>
            <Option value="Any">Any</Option>
            <Option value="Full Time">Full Time</Option>
            <Option value="Part Time">Part Time</Option>
            <Option value="Contract">Contract</Option>
            <Option value="Temporary">Temporary</Option>
            <Option value="Intern">Intern</Option>
            <Option value="Other">Other</Option>
          </Select>
        </Form.Item>
        <Form.Item name={"posedTime"} label={"Posted Time"}>
          <Select style={{ width: 150 }}>
            <Option value="0">All Time</Option>
            <Option value="1">Today</Option>
            <Option value="2">Within a week</Option>
            <Option value="3">Within two weeks</Option>
            <Option value="4">Within 1 month</Option>
            <Option value="5">More than 1 month</Option>
          </Select>
        </Form.Item>
        <Form.Item name={"minimum"} label={"Pay Range"}>
          <Input
            style={{ maxWidth: 200 }}
            placeholder="Minimum"
            addonAfter="$"
          />
        </Form.Item>

        <Form.Item name={"maximum"}>
          <Input
            className="site-input-right"
            addonAfter="$"
            style={{
              maxWidth: 200,
            }}
            placeholder="Maximum"
          />
        </Form.Item>
        <Form.Item
          name={"hotelName"}
          label="Hotel"
          style={{ maxWidth: 500, minWidth: 300 }}
        >
          <Select
            onChange={onChangeSelect}
            showSearch
            // style={{ maxWidth: 500 }}
            mode="tags"
            maxTagCount="responsive"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            {hotelsOptions()}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type={"primary"} htmlType="submit">
            Filter
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
