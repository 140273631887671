import React, { useEffect, useState, useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  auth,
  registerWithEmailAndPassword,
  storage,
  logout,
} from "../../pages/auth/firebase";
import { getAuth } from "firebase/auth";
import { Layout, Menu, Dropdown, Button, Spin, Space, Typography } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { UserContext } from "../../pages/auth/context";
import SearchBar from "../../components/searchBar";
export default function AppHeader(appProps) {
  const [user, loading, error] = useAuthState(auth);
  const [isLoading, setLoading] = useState(false);
  const { Header, Content, Footer } = Layout;
  const userContxt = useContext(UserContext);
  const [photoUrl, setPhotoUrl] = useState();
  const { Text } = Typography;
  const navigate = useNavigate();
  let mounted = false;
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    if (!mounted) {
      if (appProps.user) {
        // console.log(authUser.email);

        setPhotoUrl(appProps.user.photoURL);
      }
    }
    return () => {
      mounted = true;
    };
    // if (user) history.replace("/dashboard");
  }, []);
  const menu = (
    <Menu mode="vertical">
      {isMobile ? (
        <Menu.Item key="1">
          <Link to="/candidates">Candidates</Link>
        </Menu.Item>
      ) : (
        ""
      )}

      {appProps.isAuthenticated ? (
        <Menu.Item key="2">
          <Link to="/dashboard/employer">Dashboard</Link>
        </Menu.Item>
      ) : (
        ""
      )}
      <Menu.Item key="3">
        <Text
          italic
          onClick={() => {
            setLoading(true);
            userContxt.setView("candidate");
            userContxt.createEmployerProfile("candidate");
            setTimeout(() => {
              navigate("/dashboard/candidate");
              setLoading(false);
            }, 500);
          }}
        >
          I'm looking for jobs.
        </Text>
      </Menu.Item>
      <Menu.Item key="10">
        <Button
          onClick={() => {
            setLoading(true);
            logout();
            setTimeout(() => {
              setLoading(false);
            }, 500);
          }}
          type={"dashed"}
        >
          Sign Out
        </Button>
      </Menu.Item>
    </Menu>
  );
  return (
    <div>
      <Spin spinning={isLoading}>
        <Header
          style={{
            position: "fixed",
            zIndex: 1,
            width: "100%",
            backgroundColor: "white",
          }}
        >
          <div style={{ display: "flex", height: "fit-content" }}>
            <div className="flex  mobile-header-flex flex-start flex-basis-60 flex-grow-1">
              <Link to="/">
                {" "}
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vuefirebase-c2006.appspot.com/o/hotel-logo%2Fhanyc-logo-large.png?alt=media&token=62c36fa2-c372-412f-b965-34dca5b1a4e1"
                  className="logo-header-main"
                />
              </Link>
              {isMobile ? (
                ""
              ) : (
                <Menu
                  className="flex flex-start flex-basis-60 hide-on-mobile"
                  mode="horizontal"
                  defaultSelectedKeys={["1"]}
                >
                  <Menu.Item key="1">
                    <Link to="/candidates">Candidates</Link>
                  </Menu.Item>
                  {appProps.isAuthenticated ? (
                    <Menu.Item key="2">
                      <Link to="/dashboard/employer">Dashboard</Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}

                  {UserContext.view == "employer" ? (
                    <Menu.Item key="3">
                      <Link to="/dashboard/candidates">Candidates</Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                </Menu>
              )}
            </div>
            <div style={{ flex: 1, margin: "1rem" }}></div>
            {user ? (
              <div style={{ flex: 1, textAlign: "right" }}>
                <Dropdown overlay={menu} trigger={["click"]}>
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      {photoUrl ? (
                        <img src={photoUrl} className="avatar header-image" />
                      ) : (
                        "Profile"
                      )}
                      <DownOutlined />
                    </Space>
                  </a>
                </Dropdown>
              </div>
            ) : (
              <>
                <Link to="/login">Login/Register</Link>
              </>
            )}
          </div>
        </Header>
      </Spin>
    </div>
  );
}
