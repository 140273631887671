import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter, Route } from "react-router-dom";

import withAuthProvider from "./pages/auth/AuthProvider";
import { UserContextProvider } from "./pages/auth/context";
import ContentLayout from "./ContentLayout";
function App(appProps) {
  return (
    <div className="App">
      <UserContextProvider>
        <BrowserRouter>
          {/* <Routers {...appProps} /> */}

          <ContentLayout {...appProps} />
          {/* <Layout style={{}}>
         
            <Content className="site-layout">
              <div className="site-layout-content">
       
              </div>
            </Content>
            <Footer className="layout-footer">SG DESIGN</Footer>
          </Layout> */}
        </BrowserRouter>
      </UserContextProvider>
    </div>
  );
}

export default withAuthProvider(App);
