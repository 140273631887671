import React, { useEffect, useState, useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import { auth, registerWithEmailAndPassword, storage, db } from "./firebase";
import { getAuth } from "firebase/auth";
import {
  Form,
  Input,
  Button,
  Radio,
  Upload,
  Spin,
  Result,
  message,
  Typography,
  Alert,
} from "antd";
import { UploadOutlined, InboxOutlined } from "@ant-design/icons";
import {
  ref,
  uploadBytes,
  updateMetadata,
  getDownloadURL,
} from "firebase/storage";
import { UserContext } from "./context";
const { Text } = Typography;
function Register() {
  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [role, setRole] = useState("candidate");
  const [fileList, setFileList] = useState([]);
  const [storageRef, setStorageRef] = useState([]);
  const [file, setFile] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const [isAuthed, setAuth] = useState(false);
  const [err, setErr] = useState(false);
  const [errMsg, setMsg] = useState("");
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const register = async (values) => {
    try {
      await registerWithEmailAndPassword(
        values.firstName,
        values.lastName,
        values.email,
        values.password,
        values.role
      );
    } catch (error) {
      throw new Error(error.code);
    }
  };
  useEffect(() => {
    if (loading) return;

    if (user) {
      navigate("/jobs");
    }
    userContext.addPageId();
  }, [user, loading]);

  const uploadResumeFile = async (file) => {
    const resumeRef = ref(storage, `resumes/${email}/${file.name}`);
    const snapshot = await uploadBytes(resumeRef, file);
    console.log("Uploaded a blob or file!");
    console.log(resumeRef.fullPath);
    console.log(snapshot);
    const newMetadata = {
      cacheControl: "public,max-age=300",
      contentType: file.type,
    };

    // Update metadata properties
    updateMetadata(resumeRef, newMetadata)
      .then((metadata) => {
        // Updated metadata for 'images/forest.jpg' is returned in the Promise
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
      });
    const url = await getDownloadURL(resumeRef);
    return { resumeUrl: url, resumeFileName: file.name };
  };
  const onFinish = async (values) => {
    console.log("Success:", values);

    setLoading(true);
    try {
      const registeredUser = await register(values);

      if (file) {
        const fileResult = await uploadResumeFile(file);
        db.collection("candidates").doc(registeredUser.uid).update({
          resumeFileName: fileResult.resumeFileName,
          resumeUrl: fileResult.resumeUrl,
        });
      }

      // if (registeredUser.uid) {
      //   setAuth(true);
      //   if (values.role == "employer") {
      //     navigate("/dashboard/employer");
      //     navigate(0);
      //   } else if (values.role == "candidate") {
      //     navigate("/dashboard/candidate");
      //     navigate(0);
      //   } else {
      //     navigate("/login");
      //     navigate(0);
      //   }
      // }
    } catch (error) {
      console.log(error.message);
      const errorCode = error.code;
      console.log(error.code);
      var errorMessage = error.message;
      if (error.message == "auth/email-already-in-use") {
        errorMessage = "The email address is already in use";
      } else if (error.message == "auth/invalid-email") {
        errorMessage = "The email address is not valid.";
      } else if (error.message == "auth/operation-not-allowed") {
        errorMessage = "Operation not allowed.";
      } else if (error.message == "auth/weak-password") {
        errorMessage = "The password is too weak.";
      } else if (error.message == "auth/invalid-email") {
        errorMessage = "E-mail is invalid.";
      }
      setErr(true);
      setMsg(errorMessage);
    }

    setTimeout(() => {
      setLoading(false);
    }, 500);
  };
  const normFile = (e) => {
    console.log("Upload event:", e);

    if (Array.isArray(e)) {
      return e;
    }
    if (e.fileList.length > 0) {
      const resumeRef = ref(storage, `resumes/${e.fileList[0].name}`);
      uploadBytes(resumeRef, file).then((snapshot) => {
        console.log("Uploaded a blob or file!");
        console.log(resumeRef.fullPath);
        console.log(snapshot);
        const newMetadata = {
          cacheControl: "public,max-age=300",
          contentType: "image/jpeg",
        };

        // Update metadata properties
        updateMetadata(resumeRef, newMetadata)
          .then((metadata) => {
            // Updated metadata for 'images/forest.jpg' is returned in the Promise
          })
          .catch((error) => {
            // Uh-oh, an error occurred!
          });
      });

      setStorageRef([resumeRef]);
      setFile(e.file);
    }

    return e && e.fileList;
  };

  const onRemove = (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const beforeUpload = (file) => {
    setFile(file);
    const isJpgOrPng =
      file.type === "application/pdf" ||
      file.type ===
        "register.js:116 application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    if (!isJpgOrPng) {
      message.error("You can only upload PDF/docx file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("File must smaller than 2MB!");
    }
    // return isJpgOrPng && isLt2M;
    return false;
  };

  return (
    <div>
      {isAuthed ? (
        <div
          style={{
            marginBottom: "2rem",

            maxWidth: "500px",
            margin: "auto",
          }}
        >
          <Alert
            message="Registered Successfully!"
            description={``}
            type="success"
            closable
          />
        </div>
      ) : (
        <></>
      )}
      {err ? (
        <>
          <div style={{ marginBottom: "2rem" }}>
            <Alert
              message="Registration went wrong:"
              description={`${errMsg}`}
              type="error"
              closable
            />
          </div>
        </>
      ) : (
        <></>
      )}
      <Spin spinning={isLoading}>
        <div className="auth-page">
          <div className=" card auth-form">
            <Form
              className=""
              labelCol={{
                span: 4,
              }}
              wrapperCol={{
                span: 20,
              }}
              // labelCol={{
              //   xs: { span: 24 },
              //   sm: { span: 4 },
              //   md: { span: 4 },
              //   lg: { span: 4 },
              // }}
              // wrapperCol={{
              //   xs: { span: 24 },
              //   sm: { span: 20 },
              //   md: { span: 20 },
              //   lg: { span: 20 },
              // }}
              onFinish={onFinish}
              initialValues={{ role: role }}
            >
              <div style={{ margin: "auto", textAlign: "center" }}>
                <Form.Item
                  label=""
                  name="role"
                  style={{ justifyContent: "space-evenly" }}
                >
                  <Radio.Group
                    value={"candidate"}
                    onChange={(e) => {
                      setRole(e.target.value);
                    }}
                  >
                    <Radio.Button value="employer">I'm hiring</Radio.Button>
                    <Radio.Button value="candidate">Job Seeker</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </div>

              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please input your First Name!",
                  },
                ]}
              >
                <Input style={{ maxWidth: "400px" }} />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please input your Last Name!",
                  },
                ]}
              >
                <Input style={{ maxWidth: "400px" }} />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input
                  style={{ maxWidth: "400px" }}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password style={{ maxWidth: "400px" }} />
              </Form.Item>

              {role == "candidate" ? (
                <div style={{ textAlign: "center" }}>
                  <Form.Item
                    name="resume"
                    valuePropName="fileList"
                    // getValueFromEvent={normFile}
                    noStyle
                  >
                    <Text>Upload Resume</Text>
                    <Upload.Dragger
                      accept=".doc,.docx,application/pdf, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      maxCount="1"
                      onRemove={onRemove}
                      beforeUpload={beforeUpload}
                      label="Upload Resume"
                      style={{ maxWidth: "400px", margin: "auto" }}
                      wrapperCol={{
                        offset: 4,
                        span: 20,
                      }}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                      <p className="ant-upload-hint">
                        Support format: doc, docx, pdf
                      </p>
                    </Upload.Dragger>
                  </Form.Item>
                </div>
              ) : (
                <></>
              )}
              <div className="flex-column flex-center ">
                <Form.Item
                  // wrapperCol={{
                  //   offset: 4,
                  //   span: 20,
                  // }}
                  style={{
                    marginTop: "25px",
                  }}
                >
                  <Button type="primary" htmlType="submit">
                    Register
                  </Button>
                </Form.Item>

                <Form.Item
                  style={{
                    maxWidth: "450px",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    {" "}
                    Already have an account? <Link to="/login">Login</Link> now.
                  </div>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </Spin>
    </div>
  );
}
export default Register;
