import React, { useState } from "react";
import { Select, Divider, Input, Form } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

let index = 0;

export default function JobSkills() {
  const [items, setItems] = useState([
    "Administrative assistant/receptionist",
    "Customer service",
  ]);
  const [name, setName] = useState("");
  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const addItem = () => {
    console.log("addItem");

    setItems([...items, name || `New item ${index++}`]);
    setName("");
  };

  return (
    <Form.Item name="skills" label="Skills">
      <Select
        mode="multiple"
        style={{ maxWidth: 600 }}
        placeholder="Select or add skills requirments"
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: "4px 0" }} />
            <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
              <Input
                style={{ flex: "auto" }}
                value={name}
                onChange={onNameChange}
              />
              <a
                style={{
                  flex: "none",
                  padding: "8px",
                  display: "block",
                  cursor: "pointer",
                }}
                onClick={addItem}
              >
                <PlusOutlined /> Add item
              </a>
            </div>
          </div>
        )}
      >
        {items.map((item) => (
          <Option key={item}>{item}</Option>
        ))}
      </Select>
    </Form.Item>
  );
}
