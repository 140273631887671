import React, { Children } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Button, Spin, Typography } from "antd";
const { Title } = Typography;
const { Header, Content, Sider } = Layout;
export default function CandidateDashboard(props) {
  return (
    <div>
      {/* <Sider
        width={200}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 20,
        }}
        className="site-layout-background"
        // breakpoint="lg"
        // collapsedWidth="0"
        onBreakpoint={(broken) => {
          // console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          // console.log(collapsed, type);
        }}
      >
         breakpoint="lg"
        collapsedWidth="0"
      </Sider> */}
      <Sider
        width={200}
        className="site-layout-background"
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          // console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          // console.log(collapsed, type);
        }}
      >
        <div id="dashboardSidebar">
          {" "}
          <Menu mode="vertical">
            <Menu.Item key="1">
              <Link to="/dashboard/candidate/profile/edit">
                Update Profile{" "}
              </Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to="/dashboard/candidate/saved-jobs">Saved Jobs</Link>
            </Menu.Item>
            <Menu.Item key="3">
              <Link to="/dashboard/candidate/applied-jobs">Applied Jobs</Link>
            </Menu.Item>
          </Menu>
        </div>
      </Sider>

      <div id="dashboardContent">
        {" "}
        <Title level={3}>Dashboard</Title>
        {props.children}
      </div>
    </div>
  );
}
