import React, { useState, useEffect, Children } from "react";
import { Avatar, Typography, Space, Empty, Alert, Button, Result } from "antd";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
  ArrowLeftOutlined,
  LockOutlined,
} from "@ant-design/icons";

import LoadingFrame from "../../layout/loadingFrame";
import moment from "moment";
import InterestedButton from "../employer/components/interestedButton";
import Axios from "axios";
import { apiRoutes } from "../auth/apiRoute";
const { Text, Paragraph, Title } = Typography;
export default function ProfileView(appProps) {
  const [currentProfile, setCurrentProfile] = useState({});
  const [resumeUrl, setResumeUrl] = useState("");
  const [resumeFileName, setResumeFileName] = useState("");
  const [coverletterUrl, setCoverletterUrl] = useState("");
  const [coverletterFileName, setCoverletterFileName] = useState("");
  const [loading, setLoading] = useState(true);
  const [photoUrl, setPhotoUrl] = useState();
  const [profileComplete, setProfileComplete] = useState(false);
  const [confidential, setConfidential] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  useEffect(async () => {
    getCandidate();
  }, []);

  const getCandidate = async () => {
    const userId = params.candidateId;
    console.log(params);
    // const candidateProfileRef = await db
    //   .collection("candidates")
    //   .doc(userId)
    //   .get();
    // var candidateProfile = candidateProfileRef.data();
    setLoading(true);
    try {
      const result = await Axios.post(apiRoutes.candidate, {
        candidateId: userId,
      });
      console.log(result.data);
      if (result.data) {
        const candidateProfile = result.data;
        if (candidateProfile.resume && candidateProfile.resume.experiences) {
          setConfidential(candidateProfile.resume.confidential);
          candidateProfile.resume.experiences =
            candidateProfile.resume.experiences.map((item) => {
              return {
                ...item,
                startDate: moment(item.startDate),
                endDate: moment(item.endDate),
              };
            });
        }
        setCurrentProfile(candidateProfile.resume);
        setProfileComplete(true);
        setResumeFileName(candidateProfile.resumeFileName);
        setResumeUrl(candidateProfile.resumeUrl);
        setCoverletterFileName(candidateProfile.coverletterFileName);
        setCoverletterUrl(candidateProfile.coverletterUrl);
        setPhotoUrl(candidateProfile.photoUrl);
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };
  const contactInfo = () => {
    if (currentProfile) {
      return (
        <div
          className="card"
          style={{ padding: "10px", maxWidth: "800px", margin: "auto" }}
        >
          {photoUrl ? (
            <img src={photoUrl} className="avatar" />
          ) : (
            <Avatar
              size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
              icon={<UserOutlined />}
            />
          )}
          <Title level={4}>
            {currentProfile.firstName + " " + currentProfile.lastName}
          </Title>
          <Space direction="vertical">
            <Text>
              <PhoneOutlined /> {currentProfile.phone}
            </Text>
            <Text>
              <MailOutlined /> {currentProfile.email}
            </Text>
            <Text type="secondary">
              {currentProfile.city}, {currentProfile.state},{" "}
              {currentProfile.zip}{" "}
            </Text>
            {resumeUrl ? (
              <a href={resumeUrl} download={resumeFileName} target="_blank">
                Download Resume
              </a>
            ) : (
              ""
            )}
          </Space>
        </div>
      );
    }
  };

  const coverLetter = () => {
    return (
      <div
        className="card"
        style={{
          padding: "10px",
          maxWidth: "800px",
          margin: "auto",
          marginTop: "10px",
        }}
      >
        <Title level={4}>Cover Letter</Title>
        {coverletterUrl ? (
          <a
            href={coverletterUrl}
            download={coverletterFileName}
            target="_blank"
          >
            Download
          </a>
        ) : (
          ""
        )}

        <Paragraph>
          <Paragraph>
            {currentProfile.coverLetter ? (
              currentProfile.coverLetter
            ) : coverletterUrl ? (
              ""
            ) : (
              <Empty />
            )}
          </Paragraph>
        </Paragraph>
      </div>
    );
  };

  const experiences = () => {
    return (
      <div
        className="card"
        style={{
          padding: "10px",
          maxWidth: "800px",
          margin: "auto",
          marginTop: "10px",
        }}
      >
        <Title level={4}>Experiences</Title>

        {currentProfile.experiences &&
          currentProfile.experiences.map((item) => {
            var startDateTime = moment(item.startDate).format("MMMM Do YYYY");
            var endDateTime = moment(item.endDate).format("MMMM Do YYYY");
            if (item.currentJob) {
              endDateTime = "Current Job";
            }
            var duration = moment(item.startDate).diff(
              moment(item.endDate),
              "years"
            );
            return (
              <div
                style={{ marginTop: "10px" }}
                key={item.employerName + item.jobTitle}
              >
                <Space direction="vertical" size={"2"}>
                  <Text strong>{item.employerName}</Text>
                  <Text>{item.jobTitle}</Text>
                  <Text type="secondary">
                    {startDateTime} – {endDateTime}, Duration {duration} years
                  </Text>
                  <Text type="secondary">
                    {item.city}, {item.state}, {item.country}{" "}
                  </Text>
                </Space>
              </div>
            );
          })}
      </div>
    );
  };

  const educations = () => {
    return (
      <div
        className="card"
        style={{
          padding: "10px",
          maxWidth: "800px",
          margin: "auto",
          marginTop: "10px",
        }}
      >
        <Title level={4}>Education</Title>
        {currentProfile.educations &&
          currentProfile.educations.map((item) => {
            var startDateTime = moment(item.startDate).format("MMMM Do YYYY");
            var endDateTime = moment(item.endDate).format("MMMM Do YYYY");
            var duration = moment(item.startDate).diff(
              moment(item.endDate),
              "years"
            );
            return (
              <div
                style={{ marginTop: "10px" }}
                key={item.employerName + item.jobTitle}
              >
                <Space direction="vertical" size={"2"}>
                  <Text strong>{item.schoolName}</Text>
                  <Text>
                    {item.degree + " of "} {item.major + " degree"}
                  </Text>
                  {/* <Text type="secondary">
                    {startDateTime} – {endDateTime}, Duration {duration} years
                  </Text> */}
                  <Text type="secondary">
                    {item.city}, {item.state}, {item.country}{" "}
                  </Text>
                </Space>
              </div>
            );
          })}
      </div>
    );
  };
  const noProfile = () => {
    return (
      <Alert
        message="No Profile"
        type="warning"
        action={
          <Space>
            <Button size="small" type="ghost">
              <Link to="/candidates">View Others</Link>
            </Button>
          </Space>
        }
      />
    );
  };

  const ConfidentialContact = ({ confidential, children }) => {
    if (confidential) {
      return (
        <>
          <Result
            icon={<LockOutlined />}
            title="Information is locked. Please contact the contact for more information."
            extra={<InterestedButton props={params} />}
          />
          {experiences()}
          {educations()}
        </>
      );
    } else {
      return children;
    }
  };
  return (
    <LoadingFrame
      loading={loading}
      style={{ margin: "10px", padding: "0 40px" }}
    >
      <Button onClick={() => navigate(-1)}>
        <ArrowLeftOutlined />
        go back
      </Button>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="flex-basis-40">
          {!profileComplete ? (
            noProfile()
          ) : currentProfile ? (
            <>
              <ConfidentialContact confidential={confidential}>
                <div>
                  {" "}
                  {contactInfo()}
                  {coverLetter()}
                  {experiences()}
                  {educations()}
                </div>
              </ConfidentialContact>
            </>
          ) : (
            noProfile()
          )}
        </div>
      </div>
    </LoadingFrame>
  );
}
