import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Col,
  Row,
  Select,
  InputNumber,
  DatePicker,
  AutoComplete,
  Cascader,
  Space,
  Divider,
  Typography,
  Avatar,
} from "antd";
import { AntDesignOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;
export default function JobCard(job) {
  const { name, hotel, url, city, state, createdAt, id } = job;
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <div className="card" style={{ maxWidth: "800px" }}>
      <div style={{ margin: "auto", padding: "5px" }}>
        <Row>
          <Col xs={6}>
            <img
              style={{ objectFit: "contain", width: "50px", marginTop: "10px" }}
              src={
                hotel.avatarUrl
                  ? hotel.avatarUrl
                  : "https://firebasestorage.googleapis.com/v0/b/vuefirebase-c2006.appspot.com/o/hotel-logo%2Fhotel_placeholder.png?alt=media&token=9c128ea2-9582-406e-9e63-de7a00fd3fff"
              }
            ></img>
          </Col>
          <Col xs={18}>
            <Title level={4}>
              {isMobile ? (
                <Link to={`/jobs/${id}`}>{name}</Link>
              ) : (
                <a onClick={() => job.onSelect(job)}>{name}</a>
              )}
            </Title>
            <Text>{hotel.name}</Text>
            <div>
              <Text type="secondary">
                {hotel.city}, {hotel.state}
              </Text>
            </div>
            <div>
              <Text type="secondary">
                {createdAt && createdAt.toDate().toDateString()}
              </Text>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
