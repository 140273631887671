import React, { useState, useEffect } from "react";

import Routers from "./routes/routes";
import { Layout, Menu, Breadcrumb, Button } from "antd";
// import MemberLayout from "./layout/memberLayout";
import CandidateLayout from "./layout/candidate/candidateLayout";
import EmployerLayout from "./layout/employer/employerLayout";
import GuestLayout from "./layout/guest/guestLayout";
import LoadingFrame from "./layout/loadingFrame";
import { UserContext } from "./pages/auth/context";
export default function ContentLayout(appProps) {
  const userContext = React.useContext(UserContext);

  useEffect(() => {
    console.log(userContext.pageId);
  }, [userContext.pageId]);

  const contentLayout = (key) => {
    var address = window.location.pathname;
    if (appProps.isLoading) {
      return <LoadingFrame loading={true}></LoadingFrame>;
    }

    if (address == "/") {
      return <Routers {...appProps} />;
    }

    if (address == "/login" || address == "/register") {
      return (
        <GuestLayout>
          <Routers {...appProps} />
        </GuestLayout>
      );
    }

    // if (address.includes("jobs")) {
    //   return (
    //     <CandidateLayout {...appProps}>
    //       <Routers {...appProps} />
    //     </CandidateLayout>
    //   );
    // }
    if (userContext.view == "candidate") {
      return (
        <CandidateLayout {...appProps}>
          <Routers {...appProps} />
        </CandidateLayout>
      );
    }
    if (userContext.view == "employer") {
      return (
        <EmployerLayout {...appProps}>
          <Routers {...appProps} />
        </EmployerLayout>
      );
    }
    if (appProps.isAuthenticated && address == "/404") {
      return (
        <GuestLayout {...appProps}>
          <Routers {...appProps} />
        </GuestLayout>
      );
    }

    // if (address.includes("admin")) {
    //   return (
    //     <AdminLayout>
    //       <Routers {...appProps} />
    //     </AdminLayout>
    //   );
    // }
    return <Routers {...appProps} />;
  };
  return <div>{contentLayout(userContext.pageId)}</div>;
}
