import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../auth/firebase";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import {
  MdLocationOn,
  MdBusiness,
  MdSource,
  MdAttachMoney,
} from "react-icons/md";
import {
  Popconfirm,
  Form,
  Input,
  Button,
  Checkbox,
  Col,
  Row,
  Select,
  InputNumber,
  DatePicker,
  AutoComplete,
  Cascader,
  Space,
  Divider,
  Tag,
  Typography,
  Avatar,
  message,
  List,
} from "antd";

const { Title, Text, Paragraph } = Typography;
export default function JobDetail(props) {
  const navigate = useNavigate();
  const [job, setJob] = useState({});
  const [isOwner, setOwner] = useState(false);

  useEffect(() => {
    const jobId = props.id;
    if (jobId) {
      var docRef = db.collection("jobs").doc(String(jobId));
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            var jobData = doc.data();
            jobData.uid = doc.id;

            setJob(jobData);
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    }
  }, [props.id]);
  const [ellipsis, setEllipsis] = React.useState(true);

  const onDelete = () => {
    db.collection("jobs")
      .doc(props.match.params.id)
      .delete()
      .then(() => {
        message.success("Job successfully deleted!");
        console.log("Document successfully deleted!");

        navigate("/jobs");
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });
  };
  const benefitsTags = (benefits) => {
    const colors = [
      "magenta",
      "red",
      "volcano",
      "orange",
      "gold",
      "lime",
      "green",
      "cyan",
      "blue",
      "geekblue",
      "purple",
    ];
    if (!benefits) {
      return;
    }
    return benefits.map((item) => {
      const indexOfColor = benefits.indexOf(item) % colors.length;

      const colorValue = colors[indexOfColor];
      return (
        <Tag color={colorValue} key={item}>
          {item}
        </Tag>
      );
    });
  };

  const result = () => {
    return (
      <div
        className="card"
        style={{ marginTop: "30px", position: "relative", zIndex: "0" }}
      >
        <a
          onClick={() => {
            props.close();
          }}
        >
          <MenuUnfoldOutlined style={{ fontSize: "1rem" }} />
        </a>
        <div
        // style={{
        //   display: "flex",
        //   justifyContent: "space-between",
        // }}
        >
          <Row>
            <Col
              flex="1 1 200px"
              style={{
                margin: "3px",
              }}
            >
              <Title level={3}>{job.name}</Title>
            </Col>
            <Col
              style={{
                padding: "10px",
                // textAlign: "center",
              }}
              flex="0 0"
            >
              <div
                style={{
                  margin: "3px",
                }}
              >
                <Link to={`/dashboard/employer/job/${job.uid}/edit`}>Edit</Link>
              </div>
            </Col>
          </Row>
        </div>
        <Space size={"middle"} align="start">
          <Text className="description-tab">
            <MdBusiness /> {job.hotel && job.hotel.name}{" "}
          </Text>
          {"   "}
          <Text className="description-tab">
            <MdLocationOn /> {job.hotel && job.hotel.city},{" "}
            {job.hotel && job.hotel.state}
          </Text>
          <Text className="description-tab">
            <MdAttachMoney />

            {job.pay && job.pay.minimum}
            {"~"}
            {job.pay && job.pay.maximum}
          </Text>
          <Text className="description-tab">
            <MdSource />

            {job.type}
          </Text>
        </Space>
        <div style={{ marginTop: "5px" }}>{benefitsTags(job.benefits)}</div>

        <Divider />
        <Title level={4}>Description</Title>
        <Paragraph>
          <div dangerouslySetInnerHTML={{ __html: job.description }} />
        </Paragraph>
        <Divider />
        <Title level={4}>Requirements</Title>
        <Paragraph>
          <div dangerouslySetInnerHTML={{ __html: job.requirement }} />
        </Paragraph>
        <Divider />
        <Title level={4}>Skills</Title>
        {job.skills && job.skills.length > 0 && (
          <List
            size="large"
            dataSource={job.skills}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        )}
      </div>
    );
  };
  return <div>{job ? result() : <></>}</div>;
}
