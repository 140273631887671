import React from "react";
import { Link } from "react-router-dom";
import {
  Alert,
  Result,
  Form,
  Input,
  InputNumber,
  Button,
  Select,
  Typography,
  message,
  Spin,
} from "antd";

export default function showResult(props) {
  const { status, errorMsg, id, successMsg } = props;

  var msg = successMsg || "Successfully posted the job!";
  return (
    <div className="card">
      <Result
        status={status}
        title={status == "success" ? msg : errorMsg}
        extra={[
          <Button type="primary" key={status} onClick={props.viewJob}>
            <Link to={`/jobs/${id}`}> View the job</Link>
          </Button>,
        ]}
      />
    </div>
  );
}
