import React from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { Skeleton } from "antd";

import JobPosting from "../pages/employer/jobPosting";
import JobEditing from "../pages/employer/jobEditing";
import HotelUpdating from "../pages/employer/hotelUpdating";
import PostedJobs from "../pages/employer/postedJobs";
import EmployerDashboard from "../pages/employer/dashboard";
import JobsCandidates from "../pages/employer/jobsCandidates";
import JobCandidateView from "../pages/employer/jobCandidateView";
import Candidates from "../pages/employer/candidates";
export default function EmployerRoutes(appProps) {
  const EmployerOnly = ({
    isLoading,
    isAuthenticated,
    user,
    redirectPath = "/",
  }) => {
    if (isLoading) {
      return <Skeleton active />;
    }
    if (!user) {
      return <Navigate to={"/login"} replace />;
    }

    if (user && user.roles && user.roles.includes("employer")) {
      return <Outlet />;
    }

    return <Navigate to={redirectPath} replace />;
  };
  return (
    <Routes>
      <Route element={<EmployerOnly {...appProps} />}>
        <Route
          exact
          path="/"
          element={<EmployerDashboard {...appProps} />}
        ></Route>
        <Route
          exact
          path="/hotel/update"
          element={<HotelUpdating {...appProps} />}
        ></Route>
        <Route
          exact
          path="/posted-jobs"
          element={<PostedJobs {...appProps} />}
        ></Route>
        <Route
          exact
          path="/posted-jobs/:jobId/candidates"
          element={<JobsCandidates {...appProps} />}
        ></Route>
        <Route
          exact
          path="/posted-jobs/:jobId/candidates/:candidateId"
          element={<JobCandidateView {...appProps} />}
        ></Route>
        <Route
          exact
          path="/job/:jobId/edit"
          element={<JobEditing {...appProps} />}
        ></Route>
        <Route
          exact
          path="/job/add"
          element={<JobPosting {...appProps} />}
        ></Route>
      </Route>
    </Routes>
  );
}
