import React, { useEffect, useState, useContext } from "react";
import { Input, Button } from "antd";
import { BookOutlined, CheckSquareOutlined } from "@ant-design/icons";
import { db, getAuth, firebase } from "../../auth/firebase";
import { UserContext } from "../../auth/context";
const { Search } = Input;
export default function SaveButton(props) {
  const userContext = useContext(UserContext);
  const [isSaved, setSaved] = useState(false);
  const currentUser = userContext.user;
  useEffect(async () => {
    if (currentUser) {
      const userId = currentUser.uid;

      const jobRef = await db
        .collection("users")
        .doc(userId)
        .collection("savedJobs")
        .doc(props.uid)
        .get();

      setSaved(jobRef.exists);
    }
  }, [props.uid]);

  const onSave = (e) => {
    updateUserSavedJobs();
    updateJobSavedUser();
    setSaved(true);
  };
  const onRemove = () => {
    removeUserSavedJobs();
    removeJobSavedUser();
    setSaved(false);
  };

  const updateUserSavedJobs = async () => {
    const userId = currentUser.uid;
    const querySnapshot = await db
      .collection("users")
      .doc(userId)
      .collection("savedJobs")
      .doc()
      .get();

    var newJob = {
      ...{ job: props },
      ...{ createdAt: firebase.firestore.FieldValue.serverTimestamp() },
    };
    await db
      .collection("users")
      .doc(userId)
      .collection("savedJobs")
      .doc(props.uid)
      .set(newJob);
  };

  const updateJobSavedUser = async () => {
    const userRef = db.collection("candidates").doc(currentUser.uid);
    const newUser = {
      ...{ email: currentUser.email, uid: currentUser.uid },
      ...{ createdAt: firebase.firestore.FieldValue.serverTimestamp() },
    };
    await db
      .collection("jobs")
      .doc(props.uid)

      .collection("savedUsers")
      .doc(currentUser.email)
      .set(newUser);
  };

  const removeUserSavedJobs = async () => {
    const userId = currentUser.email;

    await db
      .collection("users")
      .doc(userId)
      .collection("savedJobs")
      .doc(props.uid)
      .delete();
  };

  const removeJobSavedUser = async () => {
    await db
      .collection("jobs")
      .doc(props.uid)
      .collection("savedUsers")
      .doc(currentUser.uid)
      .delete();
  };

  return (
    <div
      style={{
        margin: "0 10px",
        display: userContext.view == "employer" ? "none" : "block",
      }}
    >
      {!isSaved ? (
        <Button onClick={onSave} key={props.uid}>
          <BookOutlined /> Save
        </Button>
      ) : (
        <Button onClick={onRemove} key={props.uid}>
          {" "}
          <CheckSquareOutlined style={{ color: "green" }} /> Saved
        </Button>
      )}
    </div>
  );
}
