export const environment = "live";

export const baseUrl = environment == "live" ? "/" : "http://localhost:8189/";

export const apiRoutes = {
  emailNewJob: baseUrl + "api/v1/email/job",
  emailNewApplicant: baseUrl + "api/v1/email/applicant",
  emailInterestedApplicant: baseUrl + "api/v1/email/interestedapplicant",
  passwordResetRequest: baseUrl + "auth/api/password-reset-request",
  passwordReset: baseUrl + "auth/api/password-reset",

  candidate: baseUrl + "api/v1/candidate",
  candidates: baseUrl + "api/v1/candidates",
};
