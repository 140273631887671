import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Col,
  Row,
  Select,
  InputNumber,
  DatePicker,
  AutoComplete,
  Cascader,
  Space,
  Typography,
} from "antd";
import hotelList from "../../../files/hotelList";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
const { Option } = Select;
const { Title, Text } = Typography;
export default function HotelInfo(props) {
  const [selectedHotel, selectHotel] = useState({});
  const hotelsOptions = () => {
    return hotelList.map((item) => {
      return (
        <Option key={item.name} value={item.name}>
          {item.name}
        </Option>
      );
    });
  };
  const onChangeSelect = (value) => {
    const theHotel = hotelList.find((item) => item.name == value);

    selectHotel(theHotel);

    props.onSelectHotel(theHotel);
    console.log(selectedHotel);
  };
  return (
    <div>
      <h3>Hotel Information</h3>

      <Form.Item
        name={["hotel", "name"]}
        label="Hotel"
        rules={[{ required: true, message: "Please select your Hotel!" }]}
      >
        {selectedHotel.name != "Not Found?" ? (
          <Select
            onChange={onChangeSelect}
            showSearch
            style={{ maxWidth: 600 }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            {hotelsOptions()}
          </Select>
        ) : (
          <div>
            <Text>Please enter a hotel name below.</Text>
            <Input placeholder="Hotel Name" />{" "}
          </div>
        )}
      </Form.Item>

      <Form.Item name={["hotel", "street"]} label="Address">
        <Input placeholder="Street" />
      </Form.Item>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" xs={24} md={8} sm={24}>
          <Form.Item name={["hotel", "city"]} label="City">
            <Input placeholder="City" />
          </Form.Item>
        </Col>
        <Col className="gutter-row" xs={24} md={8} sm={24}>
          <Form.Item name={["hotel", "state"]} label="State">
            <Input placeholder="State" />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={8} sm={24}>
          <Form.Item name={["hotel", "zip"]} label="Zip">
            <Input placeholder="Zip" />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
