import React, { useState, useEffect } from "react";
import SearchBar from "../components/searchBar";
import { Link } from "react-router-dom";
import { UserContext } from "./auth/context";
import "./pages.css";

export default function Home() {
  const [classname, setClassname] = useState("regular-search");
  const userContext = React.useContext(UserContext);
  const onClick = () => {
    setClassname("regular-search onClick");
  };
  const onBlur = () => {
    setClassname("regular-search");
  };
  useEffect(() => {
    userContext.addPageId();
  }, []);

  return (
    <div>
      <div className="homepage">
        <div className="navbar">
          <div className="logo"></div>

          <div className="nav-login">
            <Link to="/login">Login/Register</Link>
          </div>
        </div>

        <div className="search-container">
          <h1 className="search-container-heading ">
            Hospitality Jobs in New York City
          </h1>
          <SearchBar onFocus={onClick} onBlur={onBlur} classname={classname} />
        </div>
      </div>
    </div>
  );
}
