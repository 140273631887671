import React, { useEffect, useState, useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import {
  auth,
  registerWithEmailAndPassword,
  storage,
  logout,
} from "../../pages/auth/firebase";
import { getAuth } from "firebase/auth";
import { Layout, Menu, Dropdown, Button, Spin, Space, Typography } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { UserContext } from "../../pages/auth/context";
import SearchBar from "../../components/searchBar";
export default function AppHeader(appProps) {
  const [user, loading, error] = useAuthState(auth);
  const [isLoading, setLoading] = useState(false);
  const { Header, Content, Footer } = Layout;
  const [photoUrl, setPhotoUrl] = useState();
  const userContxt = useContext(UserContext);
  const { Text } = Typography;
  let mounted = false;
  const navigate = useNavigate();
  useEffect(() => {
    console.log({ appProps });
    if (!mounted) {
      if (appProps.user) {
        // console.log(authUser.email);

        setPhotoUrl(appProps.user.photoURL);
      }
    }
    return () => {
      mounted = true;
    };
    // if (user) history.replace("/dashboard");
  }, []);
  const menu = (
    <Menu mode="vertical">
      <Menu.Item key="1">
        <Link to="/dashboard/candidate">Dashboard</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="/dashboard/candidate/profile">My Profile</Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link to="/dashboard/candidate/profile/edit">Update Resume</Link>
      </Menu.Item>
      <Menu.Item key="4">
        <Text
          italic
          onClick={() => {
            setLoading(true);
            userContxt.setView("employer");
            userContxt.createEmployerProfile("employer");
            setTimeout(() => {
              setLoading(false);
              navigate("/dashboard/employer");
            }, 500);
          }}
        >
          I'm hiring
        </Text>
      </Menu.Item>
      <Menu.Item key="10">
        <Button
          onClick={() => {
            setLoading(true);
            logout();
            setTimeout(() => {
              setLoading(false);
            }, 500);
          }}
          type={"dashed"}
        >
          Sign Out
        </Button>
      </Menu.Item>
    </Menu>
  );
  return (
    <div>
      <Spin spinning={isLoading}>
        <Header
          style={{
            position: "fixed",
            zIndex: 1,
            width: "100%",
            backgroundColor: "white",
          }}
        >
          <div className="flex flex-space-between flex-basis-60 ">
            <div className="flex  mobile-header-flex flex-start flex-basis-60 flex-grow-1">
              <div className="flex flex-start flex-basis-60">
                <Link to="/">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/vuefirebase-c2006.appspot.com/o/hotel-logo%2Fhanyc-logo-large.png?alt=media&token=62c36fa2-c372-412f-b965-34dca5b1a4e1"
                    className="logo-header-main"
                  />
                </Link>
                {userContxt.isMobile ? (
                  ""
                ) : (
                  <Menu
                    className="flex flex-start flex-basis-60 hide-on-mobile"
                    mode="horizontal"
                    // defaultSelectedKeys={["1"]}
                    // className="hide-on-mobile"
                  >
                    <Menu.Item key="1">
                      <Link to="/jobs">Jobs</Link>
                    </Menu.Item>
                    {appProps.isAuthenticated ? (
                      <Menu.Item key="2">
                        <Link to="/dashboard/candidate">Dashboard</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                  </Menu>
                )}
              </div>

              <div
                className="flex-basis-60 flex-grow-1 search-bar-margin"
                style={{ height: "30px" }}
              >
                <SearchBar />
              </div>
            </div>

            {user ? (
              <div style={{ textAlign: "right" }}>
                <Dropdown overlay={menu} trigger={["click"]}>
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      {photoUrl ? (
                        <img src={photoUrl} className="avatar header-image" />
                      ) : (
                        "Profile"
                      )}
                      <DownOutlined />
                    </Space>
                  </a>
                </Dropdown>
              </div>
            ) : (
              <>
                <Link to="/login">Login/Register</Link>
              </>
            )}
          </div>
        </Header>
      </Spin>
    </div>
  );
}
