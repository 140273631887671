import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useNavigate,
  Navigate,
} from "react-router-dom";
import { Form, Input, Button, Typography } from "antd";
import EmployerLayout from "./layout/layout";
import HotelInfo from "./components/hotelInfo";
import JobDescription from "./components/jobDescription";
import JobRequirement from "./components/jobRequirements";
import ShowResult from "./components/showResult";
import { db } from "../auth/firebase";
import { ArrowLeftOutlined } from "@ant-design/icons";
const { Title } = Typography;

export default function JobPosting(props) {
  let { jobId } = useParams();

  const [editing, setEditing] = useState(false);
  const [selectedHotel, selectHotel] = useState({});
  const [postedJob, setPostedJob] = useState({});
  const [status, setStatus] = useState("");
  let formRef = React.createRef();

  const navigate = useNavigate();
  useEffect(() => {
    setEditing(true);
    if (jobId) {
      var docRef = db.collection("jobs").doc(String(jobId));
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            setPostedJob(doc.data());
          } else {
            // doc.data() will be undefined in this case

            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    }
  }, [props]);

  const onFinish = async (values) => {
    console.log("Success:", values);

    var ref = db.collection("jobs").doc(String(jobId));

    return ref
      .update(values)
      .then(() => {
        console.log("Document successfully updated!");

        setStatus("success");
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (status != "success") {
      formRef.current.setFieldsValue(postedJob);
    }
  }, [postedJob]);

  const onChangeSelect = (value) => {
    selectHotel(value);
  };

  return (
    <EmployerLayout>
      <Button onClick={() => navigate(-1)}>
        <ArrowLeftOutlined />
        go back
      </Button>
      {status == "success" ? (
        <ShowResult
          errorMsg={"Successfully updated the job!"}
          status={status}
          id={jobId}
        />
      ) : (
        <Form
          ref={formRef}
          style={{ paddingTop: "2px" }}
          initialValues={{}}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout={"vertical"}
        >
          <div className="card" style={{ marginTop: "22px" }}>
            <HotelInfo
              onSelectHotel={(values) => {
                onChangeSelect(values);
              }}
            />
          </div>
          <div className="card" style={{ marginTop: "22px" }}>
            <JobDescription {...postedJob} />
          </div>
          <div className="card" style={{ marginTop: "22px" }}>
            <JobRequirement {...postedJob} />
          </div>
          <Form.Item style={{ textAlign: "center" }}>
            <Button
              htmlType="submit"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Confirm
            </Button>
          </Form.Item>
        </Form>
      )}
    </EmployerLayout>
  );
}
