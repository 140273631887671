import React, { useEffect, useState, useContext } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Pagination, Typography } from "antd";
import CandidateCard from "./components/candidateCard";
import { db } from "../auth/firebase";
import SearchBar from "../../components/searchBar";
// import FilterBar from "./components/filter";

import { UserContext } from "../auth/context";
import LoadingFrame from "../../layout/loadingFrame";
const { Title, Text } = Typography;

export default function Candidates(appProps) {
  const [search, setSearch] = useSearchParams();

  const [allCandidates, setCandidates] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredCandidates, setFilteredCandidates] = useState([]);
  const [startIndex, setStartIndex] = useState();
  const [endIndex, setEndIndex] = useState();
  const [selectedJob, setSelectedJob] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const userContext = useContext(UserContext);
  const [searching, setSearching] = useState(false);

  let isMounted = false;
  useEffect(() => {
    // userContext.addPageId();
  }, []);
  useEffect(async () => {
    let candidates = userContext.candidates;

    if (!isMounted) {
      if (candidates && candidates.length > 0) {
        setCandidates(candidates);

        setFilteredCandidates(candidates);
        // setSearch(true);
        changePage(1);

        filterCandidatesBySearchKeyWord(userContext.searchValue);
      }
    }

    return () => {
      isMounted = true;
    };
  }, [userContext]);

  // useEffect(() => {
  //   filterCandidatesBySearchKeyWord(userContext.searchValue);
  // }, [userContext.searchValue, allCandidates]);
  // useEffect(() => {
  //   if (filteredCandidates.length == 0) {
  //     setSelectedJob("");
  //   } else {
  //     setSelectedJob(filteredCandidates[0]);
  //   }
  // }, [filteredCandidates]);
  const getCandidates = () => {
    var citiesRef = db.collection("candidates");
    var records = [];
    citiesRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        var document = doc.data();
        document.id = doc.id;
        records.push(document);
      });
      setCandidates(records);
    });
  };
  const applyFilter = (obj) => {
    console.log(obj);
    console.log(allCandidates);

    if (obj && obj.hasOwnProperty("type") && obj.type) {
      if (obj.type == "Any") {
        setFilteredCandidates(allCandidates);
      } else {
        setFilteredCandidates(
          filteredCandidates.filter((item) => item.type == obj.type)
        );
      }
    }

    if (obj && obj.hasOwnProperty("posedTime") && obj.posedTime) {
      var currentDay = new Date().getTime();
      switch (obj.posedTime) {
        case "0":
          setFilteredCandidates(allCandidates);
          break;
        case "1":
          setFilteredCandidates(
            allCandidates.filter((item) => {
              if (!item.createdAt) return false;
              var dayTime = item.createdAt.toMillis();
              var diff = (currentDay - dayTime) / 1000 / 24 / 60 / 60;
              console.log(diff);
              return diff < 2;
            })
          );
          break;
        case "2":
          setFilteredCandidates(
            allCandidates.filter((item) => {
              if (!item.createdAt) return false;
              var dayTime = item.createdAt.toMillis();
              var diff = (currentDay - dayTime) / 1000 / 24 / 60 / 60;
              console.log(diff);
              return diff < 7;
            })
          );
          break;
        case "3":
          setFilteredCandidates(
            allCandidates.filter((item) => {
              if (!item.createdAt) return false;
              var dayTime = item.createdAt.toMillis();
              var diff = (currentDay - dayTime) / 1000 / 24 / 60 / 60;
              console.log(diff);
              return diff < 14;
            })
          );
          break;
        case "4":
          setFilteredCandidates(
            allCandidates.filter((item) => {
              if (!item.createdAt) return false;
              var dayTime = item.createdAt.toMillis();
              var diff = (currentDay - dayTime) / 1000 / 24 / 60 / 60;

              return diff < 30;
            })
          );
          break;
        case "5":
          setFilteredCandidates(
            allCandidates.filter((item) => {
              if (!item.createdAt) return false;
              var dayTime = item.createdAt.toMillis();
              var diff = (currentDay - dayTime) / 1000 / 24 / 60 / 60;

              return diff > 30;
            })
          );
          break;
        default:
      }
    }

    if (obj && obj.hasOwnProperty("hotelName") && obj.hotelName) {
      if (obj.hotelName == "All") {
        setFilteredCandidates(allCandidates);
      } else {
        setFilteredCandidates(
          filteredCandidates.filter((item) =>
            obj.hotelName.includes(item.hotel.name)
          )
        );
      }
    }
  };
  const onSelect = (job) => {
    setLoading(true);
    setSelectedJob(job);
    setLoading(false);
  };
  const filterCandidatesBySearchKeyWord = (keyword) => {
    var value = null;
    if (allCandidates.length == 0) {
      return;
    }
    if (keyword) {
      value = keyword.toLowerCase();
    }

    if (!value) {
      setFilteredCandidates(allCandidates);
      setSelectedJob(allCandidates[0]);
    } else {
      let newStudents = allCandidates.filter((item) => {
        // item["email"].includes(value);
        let keys = Object.keys(item);
        var bool = false;

        keys.forEach((key) => {
          if (key != "_id" && key != "key") {
            if (typeof item[key] === "string" && item[key]) {
              if (item[key].toLowerCase().includes(value)) {
                bool = true;

                return;
              }
            }
          }
        });
        return bool;
      });

      setFilteredCandidates(newStudents);
      setSelectedJob(newStudents[0]);
      if (newStudents.length == 0) {
        setSelectedJob("");
      }
    }
  };

  const showCards = (filteredCandidates) => {
    if (filteredCandidates) {
      return filteredCandidates.map((item) => {
        return <CandidateCard {...item} key={item.id} onSelect={onSelect} />;
      });
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);

    const indexOfLastTodo = page * pageSize;
    const indexOfFirstTodo = indexOfLastTodo - pageSize;
    setStartIndex(indexOfFirstTodo);
    setEndIndex(indexOfLastTodo);

    setPageQuery(page);
  };
  // const filterDataByPage = (filteredCandidates) => {
  //   if (filteredCandidates) {
  //     console.log(filteredCandidates.slice(indexOfFirstTodo, indexOfLastTodo));
  //     setFilteredCandidates(filteredCandidates.slice(indexOfFirstTodo, indexOfLastTodo));
  //   }
  // };

  const getCurrentQuery = () => {
    const params = new URLSearchParams(search);
    var obj = {};
    for (const [key, val] of params.entries()) {
      obj[key] = val;
    }
    if (obj.p) {
      setCurrentPage(obj.p);
      changePage(obj.p);
    }

    if (obj.s) {
      filterCandidatesBySearchKeyWord(obj.s);
    }

    console.log({ obj });
  };

  const setPageQuery = (page) => {
    const params = new URLSearchParams(search);

    params.append("p", page);

    // history.replace({ search: params.toString() });
  };
  return (
    <div className="candidates-container">
      {/* <SearchBar onFinish={filterCandidatesBySearchKeyWord} defaultValue={""} /> */}
      {/* <div
        style={{
          position: "fixed",
          zIndex: "1",
          background: "white",
          height: "60px",
        }}
        key={filteredCandidates[0]}
      >
        <FilterBar
          style={{
            position: "fixed",
            zIndex: "1",
          }}
          applyFilter={applyFilter}
        />
      </div> */}

      <div className="flex-basis-40">
        <div className="center">
          <Title level={3}>Candidates</Title>
        </div>

        <div
          style={{
            height: "80vh",
            maxHeight: "100vh",
            overflow: "auto",
          }}
        >
          {showCards(
            filteredCandidates && filteredCandidates.length > 0
              ? filteredCandidates.slice(startIndex, endIndex)
              : []
          )}
        </div>
        <div className="pagination">
          <Pagination
            simple={true}
            current={currentPage}
            onChange={changePage}
            total={allCandidates.length}
            pageSize={pageSize}
          />
        </div>
      </div>
    </div>
  );
}
